<template>
    <div class="main">
    
    <section class="section-login">
        <div class="md:w-6/12 sm:w-10/12 mx-auto">
            <h3 v-if="!errorMessage" class="text-center">Access to Tallypoint has been requested</h3>
            <div  v-if="errorMessage">
                <h3 v-if="errorMessage" class="text-center">Access to Tallypoint has been denied</h3>
                <a target="_blank" :href='process.env.VUE_APP_EIA_URL + "/" + process.env.VUE_APP_KEYCLOAK_CLIENT_ID' class="flex justify-center">Please return to EIA and try logging in again</a>
            </div>

        </div>
    </section>

</div>
</template>
    
<script>

import Keycloak from 'keycloak-js';
import axios from 'axios';

const keycloakConfig = {
      url: process.env.VUE_APP_KEYCLOAK_URL,
      realm: process.env.VUE_APP_KEYCLOAK_REALM,
      clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID
};

const keycloak = new Keycloak(keycloakConfig);

export default {
  data : function () {
      return {
          errorMessage: false,
      };
  },
  created(){
    // Keycloak init will check whether this browser has an active SSO session with Keycloak
    keycloak.init({ onLoad: 'login-required' })
      .then(authenticated => {
        if (authenticated) {
          const jwt = keycloak.token;

          // Using axios rather than this.$api() as I need the Authorization header to be set rather than nested in the url.
          // We then send jwt to the backend to validate it and extract the user details. 
          axios({
            method: 'get',
            url: "/api/external/login",
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
          }).then(resp => {
            // If details are valid then we set the user details in the state and redirect to the user home page.
            this.$state.user.username = resp.data.username;
            this.$state.user.admin = !!resp.data.admin;
            this.$state.user.precinctId = resp.data.precinct;
            this.$state.user.system = !!resp.data.system;
            this.redirectToUserHome();
          }).catch(error => {
              console.log(error)
          });
          return;
        } else {
          // Handle the scenario if the user is not authenticated
          console.error('User is not authenticated with Keycloak.');
          this.errorMessage = true;
          const redirectToLogin = window.confirm("User is not authenticated with Keycloak. Redirect to login page ?");
        
          if (redirectToLogin) {
            this.$router.push('/');
          }
        }
      })
      .catch(err => {
        // Handle any initialization errors
        console.error('Failed to initialize Keycloak', err);
        this.errorMessage = true;
        const redirectToLogin = window.confirm("Unknown error with Keycloak. Redirect to login page ?");
      
        if (redirectToLogin) {
          this.$router.push('/'); 
        }
      });
    },
  methods: {
    redirectToUserHome() {
      if (this.$state.isSystem()) {
          this.$router.push(this.routeurl('system.home'));
      } else if (this.$state.user.admin && !this.$state.isWorker()) {
          this.$router.push(this.routeurl('manager.precinct_status'));
      } else {
          this.$router.push(this.routeurl('record.home'));
      }
    }
  },
};
</script>
    