<template>
    <table class="w-full">
        <tr class="bg-gray-200">
            <th class="text-left">Name</th>
            <th class="text-left">Address</th>
            <th class="text-left">Phone</th>
            <th class="text-left">Birth Year</th>
            <th class="text-left" v-if="provisionalParties.parties_enabled">Party</th>
            <th class="text-right"></th>
        </tr>
        <tr v-if="localProvisionals.length === 0">
            <td colspan=7 class="text-center">No provisional voters found</td>
        </tr>


        <template v-else v-for="(row, rowIdx) in localProvisionals ">

            <!-- Display the rows which are not being edited -->
            <tr
            :key="`row-preview-${row.id}`" 
            :class="[
                isRowOpen(row.id) ? 'dark-background' : 'hover-background',
                rowIdx !== 0 && isRowOpen(row.id) ? 'border-t-2 border-gray-600' : ''
            ]">
                <td class="text-left">{{row.last_name}}, {{row.first_name}}</td>
                <td class="text-left" style="word-break:break-word;">{{row.address}}</td>
                <td class="text-left">{{row.phone}}</td>
                <td class="text-left">{{getYearOfBirth(row.dob)}}</td>
                <td class="text-left" v-if="provisionalParties.parties_enabled">{{row.party}}</td>
                

                <!-- More info button -->
                <td class="text-right cursor-pointer" @click="if(editingRows[row.id]){cancelChange(row); $set(openRows, row.id, !openRows[row.id])} else {$set(openRows, row.id, !openRows[row.id])}"
>
                    <SvgKeyboardUp v-if="isRowOpen(row.id)" class="sticky" style="width:24px; height:24px;"/>
                    <SvgKeyboardDown v-else class="sticky" style="width:24px; height:24px;"/>

                </td>
            </tr>

            <!-- Display the 'More Info' dropdown for this row which is not being edited-->
            <tr v-if="openRows[row.id] && editingRows[row.id] === undefined" :key="`row-notes-${row.id}`" class="bg-neutral-500 w-full">
                <td colspan="6" class="w-full pt-4">
                    <div class="flex flex-col">

                        <div class="flex mt-4">

                            <div class="flex w-1/2 flex-col">
                                <div class="flex">
                                    <label class="w-7/12 font-semibold mr-3">Did Voter Sign Provisional Envelope ?</label>
                                    <p class="italic w-5/12">{{row.voter_signed ? 'Yes' : 'No'}}</p>
                                </div>

                                <div class="flex mt-2">
                                    <label class="w-7/12 font-semibold mr-3">Did EO Sign Provisional Envelope ?</label>
                                    <p class="italic w-5/12">{{row.eo_signed ? 'Yes' : 'No'}}</p>
                                </div>

                                <div class="flex mt-2">
                                    <label class="w-7/12 font-semibold mr-3">Reason</label>
                                    <p class="italic w-5/12">{{provisionalReasonLabel(row.reason)}}</p>
                                </div>
                            </div>

                            <div class="flex w-1/2">
                                <label class="w-2/6 font-semibold text-center">Notes</label>
                                <textarea class="italic w-4/6" readonly v-text="row.notes.length > 0 ? row.notes : 'None'"></textarea>
                            </div>
                        </div>

                        <div class="flex justify-center">
                            <button class="btn-hollow mt-7 w-36" @click="openEditForRow(row)">Edit Info</button>
                        </div>

                    </div>
                </td>
            </tr>

            <!-- If the row is being edited -->
            <tr v-if="editingRows[row.id] !== undefined" :key="`row-notes-${row.id}`" class="bg-neutral-500 w-full">
                <td colspan="5" class="w-full px-12">`
                    <form @submit.prevent="(submitChange(row))" class="px-8">
                        <h4 class="text-center">Edit Voter Information</h4>

                        <label class="pt-4">
                            <span>First Name</span>
                            <input v-model="editingRows[row.id].newRow.first_name" @input="updateRowValue($event, row, `first_name`)" v-focus />
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.first_name" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.first_name.join(' ')}}</div>

                    
                        <label>
                            <span>Middle Name</span>
                            <input v-model="editingRows[row.id].newRow.middle_name" @input="updateRowValue($event, row, `middle_name`)" v-focus />
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.middle_name" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.middle_name.join(' ')}}</div>
                    
                        <label>
                            <span>Last Name</span>
                            <input v-model="editingRows[row.id].newRow.last_name" @input="updateRowValue($event, row, `last_name`)" v-focus />
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.last_name" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.last_name.join(' ')}}</div>
                    
                        <label>
                            <span>Address</span>
                            <input v-model="editingRows[row.id].newRow.address" @input="updateRowValue($event, row, `address`)" v-focus />
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.address" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.address.join(' ')}}</div>
                    
                        <label>
                            <span>Phone</span>
                            <input v-model="editingRows[row.id].newRow.phone" @input="updateRowValue($event, row, `phone`)" v-focus />
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.phone" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.phone.join(' ')}}</div>
                    
                        <label>
                            <span>Year of Birth</span>
                            <input v-model="editingRows[row.id].newRow.dob" @input="updateRowValue($event, row, `dob`)" type="number" v-focus />
                        </label>


                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.dob" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.dob.join(' ')}}</div>
                    
                        
                        <label>
                            <span>Did Voter Sign Provisional Envelope?</span>
                            <select v-model="editingRows[row.id].newRow.voter_signed" @change="updateRowValue($event, row, 'voter_signed')">
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                            </select>
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.voter_signed" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.voter_signed.join(' ')}}</div>
                    
                        <label>
                            <span>Did EO Sign Provisional Envelope?</span>
                            <select v-model="editingRows[row.id].newRow.eo_signed" @change="updateRowValue($event, row, 'eo_signed')">
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                            </select>
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.eo_signed" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.eo_signed.join(' ')}}</div>

                        
                        <label>
                            <span>Reason for provisional</span>
                            <select v-model="editingRows[row.id].newRow.reason" @change="updateRowValue($event, row, 'reason')">
                                <option v-for="reason in $results.election.election_settings.provisional_types" :value="reason.code" :key="reason.code">
                                    {{reason.label}}
                                    {{ reason.requiresNote ? '(Please explain below)' : '' }}
                                </option>
                            </select>
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.reason" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.reason.join(' ')}}</div>
                        
                        <label v-if="provisionalParties.parties_enabled">
                            <span>Party</span>
                            <select v-model="editingRows[row.id].newRow.party" @change="updateRowValue($event, row, 'party')">
                                <option v-for="party in provisionalParties.parties" :value="party.name" :key="party.name">
                                    {{party.name}}
                                </option>
                            </select>
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.party" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.party.join(' ')}}</div>

                        <label>
                            <span>Notes to office</span>
                            <textarea v-model="editingRows[row.id].newRow.notes" @input="updateRowValue($event, row, `notes`)"></textarea>
                        </label>
                        <div v-if="editingRows[row.id] && editingRows[row.id].formErrors.notes" class="text-danger-500 text-right mb-5 text-sm">{{editingRows[row.id].formErrors.notes.join(' ')}}</div>

                        <div class="flex flex-row">
                            <button
                                @click="cancelChange(row)"
                                class="btn-cancel mt-7 w-20"
                            >Cancel</button>
                            
                            <button 
                                :class="{
                                    'btn-success': editingRows[row.id].changes || loading,
                                    'btn-unavailable': !editingRows[row.id].changes && !loading
                                }"
                                class="mt-7 w-20"
                                :disabled="!editingRows[row.id].changes || loading"
                            >
                                {{
                                    loading 
                                        ? 'Sending...'
                                        : editingRows[row.id].changes 
                                            ? 'Submit Voter Information' : editingRows[row.id].saved 
                                                ? 'Saved'
                                            : 'Make Changes to Submit'
                                }}
                            </button>
                        </div>

                    </form>
                </td>
            </tr>
        </template>
    </table>
</template>

<script>

import * as Validator from 'validatorjs';
import SvgKeyboardUp from '../assets/svgs/keyboardUp.svg?inline';
import SvgKeyboardDown from '../assets/svgs/keyboardDown.svg?inline';



export default {
    props: ['provisionals'],
    data: function() {
        return {
            provisionalParties: {
                parties_enabled: false,
                parties: {}
            },
            openRows: {},
            editingRows: {},
            loading: false,
            localProvisionals: this.provisionals.slice(), // create a copy of provisionals
            routeToCall : "pollworker"
        };
    },
    watch: {
        provisionals(newVal) {
            this.localProvisionals = newVal.slice();
        }
    },
    mounted(){
        this.updateRouteToCall();
    },
    components: {
        SvgKeyboardDown,
        SvgKeyboardUp,
    },
    computed: {
        provisionalReasons() {
            return this.$results.election.election_settings.provisional_types;
        }
    },
    created() {
        if (this.$results.election.election_settings.provisional_parties.parties_enabled) {
            this.provisionalParties = this.$results.election.election_settings.provisional_parties;
        }
    },
    methods: {
        updateRouteToCall() {
            if (this.$state.user.admin) {
                this.routeToCall = "mgr";
            } else {
                this.routeToCall = "pollworker";
            }
        },
        cancelChange(row){
            this.editingRows[row.id] = undefined;
            this.$set(this.openRows, row.id, !this.openRows[row.id]);
            this.reloadProvisionals();
        },

        async submitChange(row) {
            

            let updatedRow = this.editingRows[row.id].newRow;

            let form = {
                first_name: updatedRow.first_name,
                middle_name: updatedRow.middle_name,
                last_name: updatedRow.last_name,
                address: updatedRow.address,
                phone: updatedRow.phone,
                dob: updatedRow.dob,
                reason: updatedRow.reason,
                party: updatedRow.party,
                notes: updatedRow.notes,
                precinct_id: updatedRow.precinct_id,
                id : updatedRow.id,
                voter_signed: updatedRow.voter_signed,
                eo_signed: updatedRow.eo_signed,
            };

            let formRules = {
                first_name: 'required',
                middle_name: 'present',
                last_name: 'required',
                address: 'required',
                phone: 'present',
                dob: 'required|date',
                reason: 'required|alpha_num|between:0,5',
                party: this.provisionalParties.parties_enabled ? 'required' : 'present',
                notes: 'present',
                precinct_id: 'required',
                id : 'present',
                voter_signed: 'required|boolean',
                eo_signed: 'required|boolean',
            };

            let validate = new Validator(form, formRules);
            this.editingRows[row.id].formErrors = validate.errors.all();
            if (validate.fails()) {
                console.error(validate.errors.all());
                return;
            }
            try {
                this.loading = true;
                let resp = await this.$api.post(`/${this.routeToCall}/report_voter_edit`, form);

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                // Reset the form
                for (let prop in this.form) {
                    this.form[prop] = '';
                }
                this.complete = true;

            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.log(err.message);
            }

            this.loading = false;


            this.localProvisionals = this.localProvisionals.map(p => {
                if(p.id === row.id) {
                    return {...p, ...this.editingRows[row.id].newRow};
                }
                return p;
            });
            this.editingRows[row.id] = undefined;

            this.reloadProvisionals();
            
        },
        updateRowValue(event, row, changedField){
            this.editingRows[row.id].changes = true;
            this.editingRows[row.id].newRow[changedField] = event.target.value;

        },
        async reloadProvisionals() {
            try {
                let resp; 
                if (this.routeToCall === "mgr") {
                    resp = await this.$api.get(`/mgr/provisionals?json=1`);
                } else {
                    resp = await this.$api.get(`/pollworker/provisionals`);
                }
                if (resp && resp.provisionals) {
                    this.localProvisionals = resp.provisionals;
                    this.$emit('update:provisionals', this.localProvisionals);
                }
            } catch (err) {
                console.error(err);
            }
        },
        provisionalReasonLabel(code) {
            let reason = this.$results.election.election_settings.provisional_types.find(r => r.code === code);
            return reason ?
                reason.label :
                'Unknown reason';
        },
        isRowOpen(rowId) {
            if (Object.prototype.hasOwnProperty.call(this.openRows, rowId) && this.openRows[rowId] === true) {
                return true;
            } else {
                return false;
            }
        },
        openEditForRow(newRow) {
            // 1: Check if 'id' field exists in 'newRow'
            if (newRow.id === undefined) {
              console.error('No id field in newRow');
              return;
            }

            // 2: Check for existing entry with the same 'id'
            if (this.editingRows[newRow.id] !== undefined) {
                console.warn('An entry with this id already exists in editingRows. Updating the existing entry.');
            }

            // 3: Change the date format into one that can be used as a year number
            newRow.dob = this.getYearOfBirth(newRow.dob);

            // 4: Add the new entry to editingRows (we use Vue.set to ensure reactivity)
            this.$set(this.editingRows, newRow.id, {
                "id": newRow.id,
                "changes": false,
                "saved" : false,
                "formErrors": {                
                    first_name: [],
                    middle_name: [],
                    last_name: [],
                    address: [],
                    phone: [],
                    dob: [],
                    reason: [],
                    party: [],
                    notes: [],
                    id : [],
                    voter_signed: [],
                    eo_signed: [],
                },
                "oldRow": { ...newRow },
                "newRow": { ...newRow }
            });

        },

        getYearOfBirth(dob) {
            // Check if the dob is in the format YYYY using a regular expression.
            if (/^\d{4}$/.test(dob)) {
                return parseInt(dob, 10);  // Return the year directly after converting it to an integer.
            }
            // Otherwise, assume the dob is in the format 1998-01-01T00:00:00.000Z and parse it.
            return new Date(dob).getUTCFullYear();
        },
    },
};

</script>

<style scoped>
.dark-background{
    background-color: theme('colors.neutral.500');
}

.hover-background:hover{
    background-color: theme('colors.neutral.100');
}

.btn-hollow {
    @apply bg-gray-200 border border-neutral-600 text-black px-5 py-2 cursor-pointer rounded;
}
.btn-hollow:hover {
    @apply bg-neutral-600 text-black border border-gray-700 px-5 cursor-pointer rounded;
} 

.btn-cancel {
    @apply bg-gray-200 border border-neutral-600 text-black px-5 py-2 cursor-pointer rounded;
}
.btn-cancel:hover {
    @apply bg-neutral-600 text-black border border-red-600 px-5 cursor-pointer rounded;
} 

.btn-unavailable {
    @apply bg-gray-400 border border-neutral-600 text-black px-5 py-2 cursor-not-allowed rounded;
}



.btn-neutral {
    border: 2px solid grey;
    color: black;
    background-color: white;
    color: black;
    pointer-events: none;
}

.btn-neutral:hover {
    border: 2px solid grey;
    color: black;
    background-color: grey;
    color: white;
    pointer-events: none;
}

</style>

