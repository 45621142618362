<template>
    <div :class="{'pb-5': hasDevices}">
        <table class="w-full text-sm">
            <tr v-for="dev in devices" :key="dev.deviceId" class="device">
                <td class="w-2/12">
                    <svg-device />
                </td>
                <td>
                    {{ dev.username }} {{ dev.precinctKey }} - {{ dev.precinctName }}<br />
                    <span class="text-neutral-600">{{ dev.remoteAddr }}</span>
                </td>
                <td class="w-0 whitespace-nowrap">
                    <form>
                        <button @click="trust(dev)" type="button" class="btn-success mr-2">Trust</button>
                        <button @click="reject(dev)" type="button" class="btn-danger">Deny</button>
                    </form>
                </td>
            </tr>

            <tr v-if="devices.length > 0">
                <td colspan=3 class="text-center">
                    <router-link
                        :to="route('manager.users', null, '#election-import')"
                        class="no-underline"
                    >Manage devices</router-link>
                </td>
            </tr>
        </table>
    </div>
</template>

<style scoped>
.device svg {
    width: 100%;
    height: 45px;
}
</style>

<script>

import SvgDevice from '../../assets/svgs/device.svg?inline';

export default {
    components: {
        SvgDevice,
    },
    data: function() {
        return {
        };
    },
    computed: {
        devices() {
            return this.$services.devices.devices;
        },
        hasDevices() {
            return this.devices.length > 0;
        },
    },
    methods: {
        trust(device) {
            this.$services.devices.authorizeDevice(device.deviceId, device.remoteAddr);
        },
        reject(device) {
            this.$services.devices.unauthorizeDevice(device.deviceId, device.remoteAddr);
        },
    },
}
</script>
