import EventEmitter from 'events';
import Vue from 'vue';

export default class ActiveSessions extends EventEmitter {
    constructor(api, bus) {
        super();

        this.precinctIds = [];

        // Only make previous properties reactive
        new Vue.observable(this);

        this.api = api;
        this.bus = bus;
    }

    listen() {
        this.bus.$on('serverevent.active.sessions', event => {
            this.precinctIds = event.precinctIds;
        });
    }
}
