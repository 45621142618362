<template>
    <header class="bg-neutral-700 text-neutral-100">
        <div class="md:flex">
            <div class="flex-grow my-2">
                <div><router-link :to="{name:'home'}" class="font-bold no-underline text-neutral-300" data-testid="home-button">TallyPoint</router-link></div>
                <div class="text-sm" v-if="this.$state.site.locationName">
                    {{ this.$state.site.locationName }}, {{ this.$state.site.electionName }}
                </div>
            </div>
            <div class="flex items-center my-2">
                <nav>
                    <div v-if="$state.isAdmin()" class="menu-dropdown inline-block pr-8">
                        <div><a href="#">Admin</a></div>
                        <div>
                            <router-link :to="{name:'manager.ballots'}">Ballot styles</router-link>
                            <router-link :to="{name:'manager.enter_results'}">Registered voters</router-link>
                            <router-link :to="{name:'manager.provisional_voters'}">Provisional voters</router-link>
                            <hr />
                            <router-link :to="{name:'manager.users'}">Users and devices</router-link>
                            <router-link :to="{name:'manager.reorder_contests'}">Reorder Contests</router-link>
                            <router-link :to="{name:'manager.import_election'}">Election settings and data</router-link>
                        </div>
                    </div>
                    <div v-if="$state.isAdmin()" class="menu-dropdown inline-block pr-8">
                        <div><a href="#">ENR</a></div>
                        <div>
                            <router-link :to="{name:'manager.precinct_status'}">Precinct status</router-link>
                            <router-link :to="{name:'manager.precinct_overview'}">Precinct results overview</router-link>
                            <router-link :to="{name:'manager.election_results'}">Election results overview</router-link>
                        </div>
                    </div>
                    <div v-if="$state.isAdmin()" class="inline-block pr-8">
                        <a @click="$state.$emit('message.open')" class="cursor-pointer">Messages {{ unreadMessageText }}</a>
                    </div>
                    <div v-if="$state.isWorker() && $state.isAdmin()" class="inline-block pr-8">
                        <router-link :to="{name:'manager.precinct_status'}">Admin</router-link>
                    </div>
                    <div class="inline-block pr-8">
                        <a @click="logout" class="cursor-pointer">Logout</a>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>

export default {
  computed: {
      unreadMessageText() {
          let out = '';
          let count = this.$services.messages.state.unreadCount;
          if (count > 0) {
              out = `(${count})`;
          }

          return out;
      }
  },
  methods: {
      async logout() {
          window.location = this.$api.url('/logout');
      },
  },
}
</script>

<style>
</style>