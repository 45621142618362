import _ from 'lodash';
import Vue from 'vue';

let intlNumber = new Intl.NumberFormat();
export function displayNumber(num) {
    return intlNumber.format(num);
}

export function propSum(arr, prop) {
    return arr.reduce(function(ret, item) {
        return ret += typeof item[prop] === 'number' ? item[prop] : 0
    }, 0);
}

// Group items of an array on a number of properties
// eg. groupThings([{a:1}, {a:2}, {a:1}], ['a']) => {1: [{}, {}], 2: [{}]}
export function groupThings(collection, keys=[]) {
    return _.groupBy(collection, (item) => {
        return keys.map(k => _.get(item, k));
    });
}

// Truncate a number to 2 decimal places without rounding. 2 places = kinda good for display purposes
export function truncForDisplay(num) {
    return Math.floor(num * 100) / 100;
}

export function makeKey(name) {
    return name.replace(/[^a-z0-9_-]/ig, '').toLowerCase();
}

export function queryStringVal(_name, _url) {
    let url = _url || window.location.href;
    let name = _.escapeRegExp(_name);
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    let results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// Get a % or cast any NaNs to 0
export function ptc(total, subset) {
    let p = subset / total * 100;
    if (Number.isNaN(p) || !Number.isFinite(p)) {
        p = 0;
    }
    return p;
}

export function sleep(len) {
    return new Promise(r => setTimeout(r, len));
}

export function ElectionData(api, appState) {
    return new Vue({
        name: 'ElectionData',
        data: function() {
            return {
                raw: {},
                // loaded - true after the first data load
                loaded: false,
            };
        },
        created() {
            this.listen(appState, 'serverevent.electiondata', event => {
                this.loadFromServerPayload(event);
            });
        },
        computed: {
            election: function() {
                return this.raw.election || {};
            },
            results: function() {
                return this.raw.results;
            },
            resultsStats() {
                return ElectionResultsStats(this.results);
            },
            locations: function() {
                return this.raw.locations ?
                    this.raw.locations.districts :
                    {};
            },
            ballots: function() {
                return this.raw.ballots;
            },
            precincts: function() {
                let precincts = [];
                for (let prop in this.locations) {
                    let district = this.locations[prop];
                    for (let precinctId in district.precincts) {
                        precincts.push(district.precincts[precinctId]);
                    }
                }
                return precincts;
            },
            districts: function() {
                return Object.values(this.locations);
            },
        },
        methods: {
            refresh: async function() {
                try {
                    let data = await api.longpoll().get('/data/election', {cb: Date.now()});
                    this.loadFromServerPayload(data);
                    this.loaded = true;
                } catch (err) {
                    console.error('Error getting election data', err);
                }
            },
            loadFromServerPayload(payload) {
                // Make sure it looks like a correct payload
                if (payload.election && payload.ballots && payload.locations) {
                    this.raw = payload;
                    appState.site.electionName = this.raw.election.name;
                    appState.site.locationName = this.raw.election.location_name;
                    appState.site.electionDate = this.raw.election.election_date || null;
                }
            },
        },
    });
}

export function ElectionResultsStats(results) {
    return {
        groupAndSumResults: function(groupBy, sumProps) {
            return this.groupAndSum(results, groupBy, sumProps);
        },
        groupAndSum: function(inp, groupBy, sumProps) {
            var helper = {};
            return inp.reduce(function(res, o) {
                var key = groupBy.reduce(function(resultingKey, prop) {
                    return resultingKey + '-' + o[prop];
                }, '');
                
                if(!helper[key]) {
                helper[key] = Object.assign({}, o);
                res.push(helper[key]);
                } else {
                sumProps.forEach(function(p) {
                    if (typeof helper[key][p] === 'number' && typeof o[p] === 'number') {
                        helper[key][p] += o[p];
                    }
                })
                }
            
                return res;
            }, []);
        },
        addPtc: function(inp, props) {
            let totals = {};
            props.forEach(p => totals[p] = propSum(inp, p));
            inp.forEach(row => {
                props.forEach(p => {
                    row[p + 'Ptc'] = this.ptc(totals[p], row[p])
                });
            });
        },
        // ptc - helper since its used in several places
        ptc: function(total, subset) {
            let p = subset / total * 100;
            if (Number.isNaN(p) || !Number.isFinite(p)) {
                p = 0;
            }
            return p;
        }
    };
}

/*
returns {
    <precinct_id>
        errors: ['error_code1', 'error_code2'],  -- all found errors for this precinct
        precinct: <precinct_instance>,
        ballots: {                               -- errors broken down to each ballot
            <ballot_id>: ['error_code1', 'error_code2'],
        }
        contests: {                              -- errors broken down to each contest
            <contest_id>: 'error_code',
        }
    },
}
*/
export function precinctsWithBadResults(allResults, locations, ballots) {
    let precincts = {};
    Object.values(locations).forEach(district => {
        Object.assign(precincts, district.precincts);
    });

    let badPrecincts = {};
    let makeBadPrecinct = (precinct) => {
        return badPrecincts[precinct.id] = badPrecincts[precinct.id] || {
            errors: [],
            precinct,
            ballots: {},
            contests: {},
        };
    };

    let precinctResults = Object.values(_.groupBy(allResults, 'precinct_id'));
    precinctResults.forEach(results => {
        let precinct = precincts[results[0].precinct_id];

        // Should never happen but it's safe to continue if it does
        if (!precinct) {
            console.error('precinctsWithBadResults() Results found for unknown precinct, precinct id ' + results[0].precinct_id);
            return;
        }


        if (precinct.tape_format === 'aggregate') {
            let getBallotTotalVotes = (ballotId) => {
                let bData = precinct.ballot_data.find(b => b.ballot_id === ballotId);
                return bData ?
                    bData.votes :
                    0;
            };

            // Aggregate formatted type so we need to aggregate the results across all same named contests over all ballots
            let contests = {};
            for (let bId of precinct.ballot_ids) {
                let ballot = ballots[bId];
                if (!ballot) continue;

                Object.values(ballot.contests).forEach(contest => {
                    let contestKey = makeKey(contest.name);
                    contests[contestKey] = contests[contestKey] || {key: contestKey, votes:0, onBallots:{}, vote_for: contest.vote_for};
                    contests[contestKey].onBallots[ballot.id] = ballot;
                });
            }

            results.forEach(res => {
                let contestKey = makeKey(res.contest_name);
                if (contests[contestKey]) {
                    contests[contestKey].votes += res.votes;
                    contests[contestKey].votes_discrepancy = res.votes_discrepancy;
                }
            });

            // rule 1: sum of each contest across its ballots should match the sum of its ballots votes
            Object.values(contests).forEach(contest => {
                let ballotVotes = 0;
                Object.values(contest.onBallots).forEach(ballot => {
                    ballotVotes += getBallotTotalVotes(ballot.id);
                });

                // // Check that vote_for is not more than 1, as if more than 1 candidate is to be voted for then for now we can't check the votes
                if (contest.vote_for <= 1 && contest.votes !== ballotVotes) {
                    let p = makeBadPrecinct(precinct);
                    p.contests[contest.key] = 'contest_votes_unmatch_ballot_votes';
                    p.errors.push('contest_votes_unmatch_ballot_votes');
                } 

                // Saving this code as it may be useful for when the vote_for feature is expanded on 
                // // Check that votes_discrepancy is not 0 or 100. And that vote_for is not more than 1, as if more than 1 candidate is to be voted for then for now we can't check the votes
                // if (contest.votes_discrepancy > 0 && contest.votes_discrepancy < 100 && contest.vote_for <= 1) {
                //     let allowedDiscrepancyInVotes = ballotVotes / 100 * contest.votes_discrepancy;

                //     // If the votes discrepancy is more than the allowed discrepancy then it's an error
                //     if (contest.votes < ballotVotes - allowedDiscrepancyInVotes || contest.votes > ballotVotes + allowedDiscrepancyInVotes) {
                //         let p = makeBadPrecinct(precinct);
                //         p.contests[contest.key] = 'contest_votes_unmatch_ballot_votes';
                //         p.errors.push('contest_votes_unmatch_ballot_votes');
                //     } 

                // // If the votes discrepancy is 0 then check the votes for errors like normal
                // } else if (contest.votes_discrepancy === 0 && contest.vote_for <= 1) {
                //     if (contest.votes !== ballotVotes) {
                //         let p = makeBadPrecinct(precinct);
                //         p.contests[contest.key] = 'contest_votes_unmatch_ballot_votes';
                //         p.errors.push('contest_votes_unmatch_ballot_votes');
                //     }
                // }
                // // If votes discrepancy is 100 then we won't check the votes 
            });

            // rule 2: sum of all ballots votes should match the precinct turnout
            let sumBallotVotes = _.sumBy(precinct.ballot_data, 'votes');

            // Check that votes_discrepancy is not 0 or 100. And that vote_for is not more than 1, as if more than 1 candidate is to be voted for then for now we can't check the votes
            if (precinct.votes_discrepancy > 0 && precinct.votes_discrepancy < 100) {

                let allowedDiscrepancyInVotes = sumBallotVotes / 100 * precinct.votes_discrepancy;

                // If the votes discrepancy is more than the allowed discrepancy then it's an error
                if (precinct.turnout < sumBallotVotes - allowedDiscrepancyInVotes || precinct.turnout > sumBallotVotes + allowedDiscrepancyInVotes) {
                    let p = makeBadPrecinct(precinct);
                    p.errors.push('ballot_votes_unmatch_precinct_turnout');
                } 

            // If the votes discrepancy is 0 then check the votes for errors like normal
            } else if (precinct.votes_discrepancy === 0) {
                if (precinct.turnout !== sumBallotVotes) {
                    let p = makeBadPrecinct(precinct);
                    p.errors.push('ballot_votes_unmatch_precinct_turnout');
                }
            }


        } else {

            // Split precinct (multiple ballots)
            if (precinct.ballot_ids.length > 1) {
                let errCodes = [];
                let ballotErrCodes = {};
                let totalBallotVotes = _.sumBy(precinct.ballot_data, 'votes');
                
                // rule 1: The sum of all ballot recorded total votes should match the precinct turnout
                if (precinct.turnout !== totalBallotVotes) {
                    errCodes.push('ballot_votes_unmatch_precinct_turnout');
                }

                // rule 2: The sum of each contest votes should match the recorded total votes on the ballot
                precinct.ballot_ids.forEach(bId => {
                    let ballot = ballots[bId];
                    if (!ballot) {
                        // Should never happen, but just be sure
                        console.error(`precinctsWithBadResults() Ballot ID ${bId} doesn't exist`);
                        return;
                    }

                    let ballotResults = results.filter(r => !!ballot.contests[r.contest_id]);
                    let ballotRecordedVotes = precinct.ballot_data.find(bd => bd.ballot_id === bId)?.votes || 0;

                    for (let cId in ballot.contests) {
                        let contest = ballot.contests[cId];
                        let contestResults = ballotResults.filter(r => r.contest_id === contest.id);
                        let contestsTotalVotes = _.sumBy(contestResults, 'votes');

                        if (contestsTotalVotes !== ballotRecordedVotes) {
                            errCodes.push('contest_votes_unmatch_ballot_votes');
                            ballotErrCodes[ballot.id] = ballotErrCodes[ballot.id] || [];
                            ballotErrCodes[ballot.id].push('contest_votes_unmatch_ballot_votes');

                            let badPrecinct = makeBadPrecinct(precinct);
                            badPrecinct.contests[contest.id] = 'contest_votes_unmatch_ballot_votes';
                        }
                    }
                });

                // Store all the errors found for each precinct, per ballot, and per contest
                if (errCodes.length > 0 || Object.keys(ballotErrCodes).length > 0) {
                    let badPrecinct = makeBadPrecinct(precinct);

                    let allBallotErrs = [];
                    for (let ballotId in ballotErrCodes) {
                        badPrecinct.ballots[ballotId] = _.uniq(ballotErrCodes[ballotId]);
                        allBallotErrs = allBallotErrs.concat(ballotErrCodes[ballotId]);
                    }

                    badPrecinct.errors = _.uniq(errCodes.concat(allBallotErrs));
                }
            } else {
                // Single ballot precincts

                let contestsResults = _.groupBy(results, 'contest_id');
                Object.values(contestsResults).forEach(contestResults => {
                    let errCodes = [];
                    let resultsTurnout = _.sumBy(contestResults, 'votes');
                    let turnoutPtc = ptc(precinct.turnout, resultsTurnout);

                    // Results totaling more than the turnout is a big no no
                    if (resultsTurnout > precinct.turnout) {
                        errCodes.push('contest_votes_mismatch_precinct_turnout');
                    }
                    // Threshhold = within 95%. >0 because this means these are empty results so not
                    // completed yet
                    if (turnoutPtc > 0 && Math.abs(turnoutPtc) <= 95) {
                        errCodes.push('contest_votes_mismatch_precinct_turnout');
                    }

                    if (errCodes.length > 0) {
                        let badPrecinct = makeBadPrecinct(precinct);
                        badPrecinct.errors = _.uniq(badPrecincts[precinct.id].errors.concat(errCodes));
                        badPrecinct.contests[contestResults[0].contest_id] = 'contest_votes_mismatch_precinct_turnout';
                    }
                });
            }
        }
    });

    return badPrecincts;
}



// Get our ballot and then merge our results into it per contest
// If a single ballot was given, then we can merge results into each contest by ID, but
// if multiple ballots were given then we merge results by the contest name (after normalising it).
// Merging by the name allows 'President + Vice Pres.' split across multiple ballots to be merged
// together and totalled as a single group in result tables
export function aggregateResultsPerContest(resultsFilter, allResults, ballot, electionData) {
    if (!ballot) {
        return {};
    }

    // if multiple ballots are being grouped then we can't include things like contest_id in the output
    let isGroupedContests = false;

    let results = _.chain(allResults).filter(resultsFilter).value();
    let availableContests = {};

    if (Array.isArray(ballot)) {
        isGroupedContests = true;
        ballot.forEach(b => Object.assign(availableContests, b.contests));
    } else {
        isGroupedContests = false;
        availableContests = ballot.contests;
    }

    // The contest might be filtered so just include that one
    if (resultsFilter.contest_id) {
        availableContests = availableContests[resultsFilter.contest_id] ?
            {
                [resultsFilter.contest_id]: availableContests[resultsFilter.contest_id]
            } :
            {};
    } else if (resultsFilter.contest_name) {
        let filteredContests = {};
        _.each(availableContests, contest => {
            if (contest.name === resultsFilter.contest_name) {
                filteredContests[contest.id] = contest;
            }
        });

        availableContests = filteredContests;
    }

    function keyFromName(inp) {
        return inp.replace(/[^a-z0-9]/ig, '').toLowerCase();
    }

    let contests = {};
    Object.values(availableContests).forEach(contest => {
        let contestKey = keyFromName(contest.name);

        // Group by contest
        contests[contestKey] = {
            rows: [],
            id: isGroupedContests ? contestKey : contest.id,
            name: contest.name,
            type: contest.type,
            position: contest.position,
            turnout: 0,
        };

        // Each row is a candidate
        contests[contestKey].rows = _.orderBy(contest.candidates, 'position').map(candidate => ({
            candidate_id: candidate.id,
            candidate_name: candidate.name,
            party: candidate.party,
            votes: 0,
        }));

        // Merge results to our candidate rows
        results.forEach(res => {
            if (!contests[contestKey]) {
                return;
            }

            let candidateRow = contests[contestKey].rows.find(r => {
                if (r.candidate_id !== res.candidate_id) {
                    return false;
                }

                // If a single contest, match the ID
                if (!isGroupedContests && contest.id !== res.contest_id) {
                    return false;
                }

                // But if grouping contests, then match against the name
                if (isGroupedContests && contestKey !== keyFromName(res.contest_name)) {
                    return false;
                }

                return true;
            });
            if (candidateRow) {
                candidateRow.votes += res.votes;
                contests[contestKey].turnout += res.votes;
            }
        });

        // Add some stats to our results for this contest
        let rows = contests[contestKey].rows;
        rows = electionData.resultsStats.groupAndSum(rows, ['candidate_id'], ['votes']);
        electionData.resultsStats.addPtc(rows, ['votes']);
        contests[contestKey].rows = rows;

        let winner = _.maxBy(rows, 'votes');
        if (winner) {
            winner.isWinner = true;
        }
    });

    return contests;
}

export function groupObjs(arr, groupProps, sumProps) {
    var helper = {};
    return arr.reduce(function(res, o) {
      var key = groupProps.reduce(function(resultingKey, prop) {
          return resultingKey + '-' + o[prop];
      }, '');
      
      if(!helper[key]) {
        helper[key] = Object.assign({}, o);
        res.push(helper[key]);
      } else {
        sumProps.forEach(function(p) {
            if (typeof helper[key][p] === 'number' && typeof o[p] === 'number') {
                helper[key][p] += o[p];
            }
        })
      }
    
      return res;
    }, []);
}

export function precinctStats(precinct) {
    // not in use anywhere
    return {
        get turnout() {
            return precinct.turnout
        },

        get turnoutPtc() {
            return Math.floor(ptc(precinct.registered, precinct.turnout));
        },

        get registered() {
            return precinct.registered;
        },
    };
}

export function updateableValue(originalVal, normaliseFn=null) {
    let state = {
        new: null,
        changed: false,
        get value() {
            return state.changed ?
                state.new :
                state.original;
        },
      
        set value(_newVal) {
            let newVal = normaliseFn ?
                normaliseFn(_newVal) :
                _newVal;

            if (newVal === state.original) {
                state.new = null;
                state.changed = false;
            } else {
                state.new = newVal;
                state.changed = true;
            }
        },

        get original() {
            return originalVal;
        },
        set original(newVal) {
            if (newVal === state.new) {
                state.new = null;
                state.changed = false;
                originalVal = newVal;
            } else if (state.changed) {
                state.changed = true;
                originalVal = newVal;
            } else {
                originalVal = newVal;
            }

            
        },
    };
    
    return state;
}


export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
