<template>
<div class="main">

<section class="section-login">
    <form class="w-96 mx-auto mt-24 bg-neutral-200 overflow-hidden rounded shadow" @submit.prevent="onLoginFormSubmit">
        <div class="m-5">
            <h3 class="mt-0 mb-10 text-center">TallyPoint Login</h3>

            <div v-if="error" class="bg-danger-300 text-danger-800 border border-red-500 p-5 mb-5 text-center" data-testid="invalid-login">{{error}}</div>
            <label>
                <span>Username</span>
                <input v-model="username" class="w-full" />
            </label>
            <label>
                <span>Password</span>
                <input v-model="password" type="password" class="w-full" />
            </label>

            <button
                type="submit"
                :disabled="loading"
                class="float-right"
                :class="[!loading ? 'btn-success' : '']"
            >{{loading ? 'Logging in...' : 'Login' }}</button>
        </div>

        <div class="p-5 clear-both">
            <router-link :to="{name:'requestdemo'}" class="text-sm no-underline hover:underline inline-block text-neutral-800">
                Request a demo
            </router-link>
        </div>
    </form>
</section>

</div>
</template>

<style scoped>
form label {
    display: block;
}

form label span {
    flex-grow: unset;
}

form label .label {
    display: block;
    margin-bottom: 5px;
}
</style>
<script>

export default {
    data: function() {
        return {
            username: '',
            password: '',
            error: '',
            loading: false,
        };
    },
    created() {
        if (this.$state.isLoggedIn()) {
            this.redirectToUserHome();
        }
    },
    
    methods: {
        async onLoginFormSubmit() {
            this.loading = true;
            this.error = '';
            try {
                let resp = await this.$api.post('/login', {
                    username: this.username,
                    password: this.password,
                });

                if (resp.error) {
                    this.error = resp.error.message;
                } else {
                    if (resp.otp === true){
                        console.log("OTP is required ... ")
                    }
                    this.$state.user.username = resp.username;
                    this.$state.user.admin = !!resp.admin;
                    this.$state.user.precinctId = resp.precinct;
                    this.$state.user.system = !!resp.system;
                    this.redirectToUserHome();
                }
            } catch (err) {
                console.log(err);
                this.error = 'Invalid login';
            }

            this.loading = false;
        },


        redirectToUserHome() {
            if (this.$state.isSystem()) {
                this.$router.push(this.routeurl('system.home'));
            } else if (this.$state.user.admin && !this.$state.isWorker()) {
                this.$router.push(this.routeurl('manager.precinct_status'));
            } else {
                this.$router.push(this.routeurl('record.home'));
            }
        }
    },
};

</script>