<template>
<div class="main">

<div class="card-title">
    <h3>Ballot styles</h3>
    <div>Review and change the bollot styles for each precinct</div>
</div>


<section class="card">

    <div class="border border-info-800 bg-info-200 text-info-900 p-5 m-10 ml-auto mr-auto text-center w-6/12">
        <p>This is how the contests will be presented in TallyPoint for each Precinct. It should be representative of ALL ballot styles for the precinct, and be in order with the receipt tape from the machine</p>
    </div>

    <div v-if="$results.loading">
        Loading data...
    </div>
    <table v-else class="w-full">
        <template v-for="district in locations">
        <template v-for="precinct in district.precincts">
            <tr :key="`precinct-1-${precinct.key}`">
                <td colspan=2 class="bg-gray-200">{{ precinct.key }} - {{district.name}}, {{precinct.name}}</td>
            </tr>

            <template v-if="precinct.tape_format === 'aggregate'">
                <tr :key="`precinct-2-${precinct.key}-aggregate`">
                    <td colspan=2 class="overflow-hidden text-right text-sm">
                        <span class="ml-5">Ballots: {{ Object.values(aggregateContests(precinct).onBallots).map(b=>b.name).join(', ') || 'None' }}</span>
                        <span class="ml-5">Tape format: {{tapeFormatText(precinct)}}</span>
                    </td>
                </tr>

                <tr v-for="contest in aggregateContests(precinct).contests" :key="`precinct-3-${precinct.key}-${contest.key}`">
                    <td>
                        {{contest.name}}
                        <span class="text-sm text-neutral-800">
                            {{ Object.values(contest.onBallots).map(b=>b.name).join(', ') }}
                        </span>
                    </td>
                    <td>
                        <div v-for="candidate in contest.candidates" :key="`contest-${contest.key}-candidate-${candidate.key}`" class="w-1/12 float-left bg-neutral-700 text-neutral-100 p-1 m-1 rounded text-sm">
                            {{ candidate.name }}
                        </div>
                    </td>
                </tr>
            </template>

            <template v-else v-for="ballotId in precinct.ballot_ids">
                <tr :key="`precinct-2-${precinct.key}-${ballotId}`">
                    <td colspan=2 class="overflow-hidden text-right text-sm">
                        <span class="ml-5">Ballot: {{ getBallot(ballotId).name }}</span>
                        <span class="ml-5">Tape format: {{tapeFormatText(precinct)}}</span>
                    </td>
                </tr>
                <template v-if="getBallot(ballotId)">
                    <tr v-for="contest in ballotContests(ballotId)" :key="`precinct-3-${precinct.key}-${ballotId}-${contest.id}`">
                        <td class="w-2/12">{{contest.name}}</td>
                        <td class="overflow-hidden">
                            <div v-for="candidate in contestCandidates(contest)" :key="'candidate-'+candidate.id" class="w-1/12 float-left bg-neutral-700 text-neutral-100 p-1 m-1 rounded text-sm">
                                {{ candidate.name }}
                            </div>
                        </td>
                    </tr>
                </template>
            </template>
            <template v-if="precinct.ballot_ids.length === 0">
                <tr :key="`precinct-4-${precinct.key}`">
                    <td colspan=2 class="text-center">
                        <span class="text-danger-800">No Ballot found for this precinct</span> <br />
                        <a v-if="ballotsArray.length===1" @click="setPrecinctBallot(precinct, ballotsArray[0].id)" class="btn-success my-3 inline-block">Set ballot to {{ballotsArray[0].name}}</a>
                    </td>
                </tr>
            </template>
        </template>
        </template>
    </table>
</section>

</div>
</template>

<script>

import _ from 'lodash';
import {
    ptc,
    makeKey,
} from '@/libs/misc';

export default {
    data: function() {
        return {
            filters: {
                district: '',
                precinct: '',
                contest: '',
            },
        };
    },
    computed: {
        results: function() {
            return this.$results.results;
        },
        ballots: function() {
            return this.$results.ballots;
        },
        ballotsArray: function() {
            return Object.values(this.$results.ballots);
        },
        locations: function() {
            return this.$results.locations;
        },
    },
    mounted() {
        this.$results.refresh();
    },
    methods: {
        tapeFormatText(precinct) {
            let labels = {
                aggregate: 'Aggregate ballots',
                individual: 'Individual ballots',
            };
            return labels[precinct.tape_format] || 'Unknown';
        },
        aggregateContests(precinct) {
            let aggregated = {
                onBallots: {},
                contests: {},
            };

            for (let bId of precinct.ballot_ids) {
                let ballot = this.ballots[bId];
                if (!ballot) {
                    continue;
                }

                for (let contest of Object.values(ballot.contests)) {
                    let contestKey = makeKey(contest.name);
                    let cont = aggregated.contests[contestKey] = aggregated.contests[contestKey] || {
                        key: contestKey,
                        name: contest.name,
                        position: contest.position,
                        candidates: {},
                        onBallots: {},
                    };

                    cont.onBallots[ballot.id] = ballot;
                    aggregated.onBallots[ballot.id] = ballot;

                    for (let candidate of contest.candidates) {
                        let candidateKey = makeKey(candidate.name);
                        cont.candidates[candidateKey] = {
                            key: candidateKey,
                            name: candidate.name,
                            party: candidate.party,
                            position: candidate.position,
                        };
                    }
                }
            }

            aggregated.contests = _.sortBy(aggregated.contests, 'position');
            for (let contest of aggregated.contests) {
                contest.candidates = _.sortBy(contest.candidates, 'position');
            }

            return aggregated;
        },
        getBallot(ballotId) {
            return this.ballots[ballotId];
        },
        ballotContests(ballotId) {
            let ballot = this.getBallot(ballotId);
            if (!ballot) {
                return [];
            }
            return _.orderBy(ballot.contests, 'position');
        },
        contestCandidates(contest) {
            return _.orderBy(contest.candidates, 'position');
        },
        async changePrecinctBallot(precinct, fromBallotId, toBallotId) {
            // Replace entries of fromBallotId to toBallotId
            let newBallotIds = [...precinct.ballot_ids];
            for (let i in newBallotIds) {
                if (newBallotIds[i] === fromBallotId) {
                    newBallotIds[i] = toBallotId;
                }
            }

            // {precincts:{1: {ballot:1}, 4:{ballot:1} }}
            await this.$api.post('/mgr/ballots', {
                precincts: {
                    [precinct.id]: {
                        ballots: newBallotIds,
                    }
                }
            });

            precinct.ballot_ids = newBallotIds;
        },
        async setPrecinctBallot(precinct, ballotId) {
            // {precincts:{1: {ballot:1}, 4:{ballot:1} }}
            await this.$api.post('/mgr/ballots', {
                precincts: {
                    [precinct.id]: {
                        ballots: [ballotId],
                    }
                }
            });

            precinct.ballot_ids = [ballotId];
        },
        ptc,
    },
};

</script>