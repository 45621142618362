<template>
    <div>
        <div v-if="showerrors" class="w-full h-full left-0 absolute top-0">
            <div ref="stickyStatus" class="sticky top-0 z-10 text-center">
                <div :class="resultsStatus.classes" class="flex">
                    <div class="flex-grow">
                        <template v-if="resultsStatus.code==='not_accepting'">
                            This election has now closed. No more results can be entered
                        </template>
                        <template v-if="resultsStatus.code==='precinct_not_ready'">
                            This precinct is not yet ready to enter results
                        </template>
                        <template v-if="resultsStatus.code==='no_precinct_set'">
                            Only pollworkers for this location can enter results
                        </template>
                        <template v-if="resultsStatus.code==='can_be_submitted'">
                            These results can now be submitted.
                            <a class="cursor-pointer inline-block underline" @click="submitResults" data-testid="precinct-submit_all">Submit results for review</a>
                        </template>
                        <template v-if="resultsStatus.code==='ballot_votes_mismatch'">
                            The combined ballot votes do not match the precinct turnout
                        </template>
                        <template v-if="resultsStatus.code==='contest_votes_mismatch'">
                            Some contests do not match their ballots total votes
                        </template>
                        <template v-if="resultsStatus.code==='single_ballot_contest_votes_mismatch'">
                            A contests total votes do not match the precinct turnout
                        </template>
                        <template v-if="resultsStatus.code==='submitted'">
                            These results have been submitted for review. No more changes can be made
                        </template>
                        <template v-if="resultsStatus.code==='pending'">
                            Enter the results for each contest to submit them for review
                        </template>
                    </div>

                    <svg-todo width="auto" height="auto" class="w-7 cursor-pointer opacity-60" @click="gotoTodo" />
                </div>
            </div>
        </div>

    <div class="main relative" :class="{'pt-28': showerrors}" :style="todoClasses">

    <section 
        ref="cardTodo"
        class="card top-0 overflow-y-hidden mx-auto"
        :class="{
            'w-10/12': todoIsSticky,
            'w-full': !todoIsSticky,
        }"
        style="transition: max-height 0.2s, padding 0.2s, width 0.2s;"
        :style="{
            'border-bottom': todoIsSticky ? '5px solid #39758f' : '',
            'padding-bottom': todoIsSticky ? '0' : '',
            'box-shadow': todoIsSticky ? '0px 10px 8px 1px #0000000f' : '',
        }"
    >
    
        <div class="">
            <div class="inline-block align-top py-4">
                <p
                    class="flex p-2 pr-10"
                    :class="{'bg-neutral-200':!todoActiveBallotId}"
                >
                    <img src="/icon-tick.svg" class="h-4 mr-2 mt-1">
                    <a @click="todoActiveBallotId=0; gotoLink('turnouts')" class="cursor-pointer no-underline">Precinct turnouts</a>
                </p>
                <p
                    v-for="ballot in aggResults"
                    :key="'tr-1-'+ballot.ballot.id"
                    class="flex p-2 pr-10"
                    :class="{'bg-neutral-200':ballot.ballot.id===todoActiveBallotId}"
                >
                    <img v-if="isBallotComplete(ballot)" src="/icon-tick.svg" class="h-4 mr-2 mt-1">
                    <img v-else src="/icon-cross.svg" class="h-4 mr-2 mt-1">
                    <a
                        @click="todoActiveBallotId=ballot.ballot.id;showBallotId=ballot.ballot.id;"
                        class="cursor-pointer no-underline"
                        :name="`todo_ballot_${ballot.ballot.id}`"
                    >{{ballot.ballot.name}}</a>
                </p>
            </div>
        
            <div v-if="todoActiveBallotId" class="inline-block align-top px-5 py-5 bg-neutral-200 rounded">
                <p
                    v-for="(contest, contestIdx) in todoActiveBallot.results"
                    :key="'tr-1-'+contest.id"
                    class="flex"
                    :class="{'pb-0': contestIdx}"
                >
                    <img v-if="isContestComplete(contest)" src="/icon-tick.svg" class="h-4 mr-2 mt-1">
                    <img v-else src="/icon-cross.svg" class="h-4 mr-2 mt-1">
                    <a
                        @click="gotoLink('contest_' + contest.id)"
                        class="cursor-pointer no-underline"
                        :name="`todo_contest_${contest.id}`"
                    >{{contest.name}}</a>
                </p>
            </div>
        </div>
    </section>
    
    <section v-if="!hasBallots" class="card">
        <div class="text-danger-800 bg-danger-300 border-b border-danger-800 p-5 m-5 text-center">
            <b>This precinct has no ballot assigned!</b>
            <p class="mt-5">Please contact the administrator to configure this precinct.</p>
        </div>
    </section>

<section class="card card--inline">
    <a name="turnouts"></a>
    <form @submit.prevent="onTurnoutFormSubmit">
        <div
            v-if="badResults && badResults.errors.includes('ballot_votes_unmatch_precinct_turnout')"
            class="text-danger-500 text-sm mb-3"
        >
            The total ballots cast ({{ballotTotalVotesSum}}) should match the precinct turnout ({{newTurnout.original}})
        </div>
        <label>
            <span>Precinct turnout</span>
            <input type="number" v-model="newTurnout.value" />
        </label>
        <label>
            <span>Curbside</span>
            <input type="number" v-model="newCurbside.value" />
        </label>

        <button type="submit" class="btn-success ml-auto block">Save</button>

        <tabulation-machine-switcher @selected="currentMachineNum=$event" :precinct="precinct" />
    </form>
</section>

<section class="card" v-for="ballot in filteredAggResults" :key="`ballot-entry-${ballot.ballot.id}`">
    <template v-if="isMultipleBallots">
        <h4>{{ballot.ballot.name}}</h4>
        <div class="bg-gray-200 p-3 mb-10">
            <form @submit.prevent="">
                Total votes for this ballot:
                <input
                    type="number"
                    :value="ballotTotalVotes[ballot.ballot.id]"
                    @change="saveBallotVotes(ballot.ballot.id, $event.target.value)"
                />
                <div v-if="ballotsWithMismatchedVotes[ballot.ballot.id]" class="text-danger-500">
                    <p class="mt-3">Some contests votes do not match this ballots total votes</p>
                </div>
            </form>
        </div>
    </template>

    <table class="w-full">
        <template v-for="(contest, contestIdx) in ballot.results">
        <tr :key="'tr-1-'+contest.id">
            <th colspan=4 class="text-left p-0">
                <a :name="'contest_' + contest.id"></a>
                <span v-if="isMultipleBallots" class="font-light mr-3">{{ballot.ballot.name}}</span>
                <span class="">{{contest.name}}</span>

                <span v-if="precinct.num_machines > 1 && currentMachineNum > 0" class="font-normal text-sm text-neutral-800 float-right">
                    Tabulation machine {{ currentMachineNum }}
                </span>
                <span v-if="precinct.num_machines > 1 && currentMachineNum === 0" class="font-normal text-sm text-neutral-800 float-right">
                    All tabulation machines
                </span>

                <div class="border-t border-gray-400"></div>
            </th>
        </tr>
        <tr :key="'tr-2-'+contest.id">
            <td colspan=4 class="text-sm">
                <div v-if="!(badResults && badResults.contests[contest.id]) || overrideContestSubmit[contest.id]" class="text-right text-neutral-800">
                    {{ contest.turnout }} votes, {{ truncForDisplay(ptc(precinct.turnout, contest.turnout)) }}% of the precinct turnout
                </div>
                <div v-else class="text-danger-500">
                    <span v-if="hasMultipleMachines">
                        This contest votes across all tabulation machines does not match {{ isMultipleBallots ? 'the ballot total votes' : 'the precinct turnout' }}.
                    </span>
                    <span v-else>
                        This contest votes does not match {{ isMultipleBallots ? 'the ballot total votes' : 'the precinct turnout' }}.
                    </span>
                    <a @click="$set(overrideContestSubmit, contest.id, true)" class="cursor-pointer">Submit anyway</a>
                </div>
            </td>
        </tr>
        <tr  :key="'tr-3-'+contest.id" class="bg-gray-200">
            <template v-if="contest.type==='election'">
                <th class="text-left">Party</th>
                <th class="text-left">Candidate</th>
            </template>
            <template v-else>
                <th class="text-left"></th>
                <th class="text-left">Answer</th>
            </template> 
            <th class="text-left">Votes</th>
            <th class="text-left">
                <div v-if="canEnterResults">
                    <a v-if="editingContest!==contest.id" class="cursor-pointer"  @click="editContest(contest.id)">[edit]</a>
                    <a v-else class="cursor-pointer" @click="editingContest=''">[cancel]</a>
                </div>
            </th>
        </tr>
        <tr v-for="row in contest.rows" class="row" :key="'tr-4-'+contest.id+'-candidate'+row.candidate_id">
            <td>{{row.party}}</td>
            <td>{{row.candidate_name}}</td>
            <td class="whitespace-nowrap">
                <template v-if="editingContest!==contest.id">
                {{row.votes}} <span class="text-neutral-700 text-sm">{{truncForDisplay(row.votesPtc)}}%</span>
                </template>
                <template v-else>
                    <form>
                        <input type="number" v-model="editingContestValues[row.candidate_id].votes" :form="'contest'+contest.id" />
                    </form>
                </template>
            </td>
            <td></td>
        </tr>
        <tr v-if="editingContest===contest.id" :key="'tr-5-'+contest.id">
            <td></td>
            <td></td>
            <td class="text-center py-5">
                <form @submit.prevent="onContestFormSubmit" :id="'contest'+contest.id">
                    <button type="submit" class="btn-success">Save</button>
                </form>
            </td>
            <td></td>
        </tr>

        <tr v-if="contestIdx < ballot.results.length - 1" :key="'tr-6-'+contest.id">
            <td colspan=4>
                <div class="mt-20"></div>
            </td>
        </tr>
        </template>
    </table>
</section>

</div>
</div>
</template>

<script>

import _ from 'lodash';
import {
    ptc,
    truncForDisplay,
    propSum,
    aggregateResultsPerContest,
    updateableValue,
    precinctsWithBadResults
} from '@/libs/misc';
import SvgTodo from '@/assets/svgs/todo.svg?inline';
import TabulationMachineSwitcher from './TabulationMachineSwitcher';


/*

Precinct
- Ballot 1
    - Contest 1
    - Contest 2
    - Contest 3
- Ballot 2
    - Contest 1
    - Contest 2
    - Contest 3
    - Contest 4
- Ballot 3
    - Contest 4
    

Rules to match against multiple ballots (split precincts):
    1. Ballot 1 votes + Ballot 2 votes = Precinct turnout
        - This works because a voter will only ever have 1 ballot
    2. sum(Ballot 1 candidate votes) = Ballot 1 total votes value

Rules to match against a single ballot:
    1. Each contest votes = Precinct turnout

Currently this component is kinda spaghetti due to figuring out these rules as I gradually
put things together. Maybe cleaner in a future refactor.

*/

export default {
    components: {
        SvgTodo,
        TabulationMachineSwitcher,
    },
    props: [
        'precinct',
        'ballots',
        'results',
        'showerrors',
        'isadmin',
    ],
    data: function() {
        return {
            todoIsSticky: false,
            todoActiveBallotId: 0,
            todoClasses: {},
            showBallotId: 0,
            editingContest: '',
            editingContestValues: {
                /*
                candidateName: 0,
                */
            },
            newTurnout: null,
            newCurbside: null,
            overrideContestSubmit: {
                /* contestId: true */
            },
            currentMachineNum: 0,
        };
    },
    watch: {
        todoIsSticky(newVal) {
            let el = this.$refs.cardTodo;

            if (!newVal) {
                el.style.maxHeight = el.clientHeight + 'px';
                this.$nextTick(() => {
                    el.style.maxHeight = '50em';
                });
            } else {
                el.style.maxHeight = el.clientHeight + 'px';
                this.$nextTick(() => {
                    el.style.maxHeight = '20vh';
                });
            }
        },
        precinct() {
            this.createUpdateValues();
        },
    },
    created() {
        // Auto select the first ballot in the todo list
        if (this.aggResults[0]) {
            this.todoActiveBallotId = this.aggResults[0].ballot.id;
            this.showBallotId = this.todoActiveBallotId;
        }

        // Auto select the only machine if there is only one
        if (this.precinct.num_machines === 1) {
            this.currentMachineNum = 1;
        }

        this.createUpdateValues();
    },
    mounted() {

        if (!this.isadmin) {
            // Roughly detect when the todo card gets stickied at the top so that we can adjust it's
            // classes when it happens
            this.todoObserver = new IntersectionObserver( 
                _.throttle(([e]) => {
                    this.todoIsSticky = e.intersectionRatio < 1;
                }, 500),
                {
                    rootMargin: '-30px 0px 0px 0px',
                    threshold: [1],
                },
            );

            // Some edge case where the cardTodo element isn't in the DOM yet
            this.$nextTick(() => {
                this.todoObserver.observe(this.$refs.cardTodo);
            });
        }
    },
    beforeDestroy() {
        if (this.todoObserver) {
            this.todoObserver.disconnect();
        }
    },
    computed: {
        ballotTotalVotesSum() {
            return _.sum(Object.values(this.ballotTotalVotes));
        },
        ballotTotalVotes() {
            let votes = {};
            let ballotData = this.precinct.ballot_data;
            for (let b of this.aggResults) {
                let ballot = b.ballot;
                votes[ballot.id] = _.sumBy(ballotData.filter(d => d.ballot_id === ballot.id), 'votes')
            }
            return votes;
        },
        todoActiveBallot() {
            if (!this.todoActiveBallotId) {
                return null;
            }

            return this.aggResults.find(b => b.ballot.id === this.todoActiveBallotId);
        },
        resultsStatusCode() {
            let p = this.precinct;
            if (!p) {
                return 'no_precinct_set';
            }

            if (this.$results.election.status==='completed') {
                return 'not_accepting';
            }
            if (p.status === 'completed') {
                return 'submitted';
            }
            if (this.allBallotsComplete) {
                return 'can_be_submitted';
            }

            if (this.badResults?.errors.includes('ballot_votes_unmatch_precinct_turnout')) {
                return 'ballot_votes_mismatch';
            }

            if (this.badResults?.errors.includes('contest_votes_unmatch_ballot_votes')) {
                return 'contest_votes_mismatch';
            }

            if (this.badResults?.errors.includes('contest_votes_mismatch_precinct_turnout')) {
                return 'single_ballot_contest_votes_mismatch';
            }

            if (!p.ready) {
                return 'precinct_not_ready';
            }
            if (p.status === 'pending') {
                return 'pending';
            }

            return '';
        },
        resultsStatus() {
            let statusCode = this.resultsStatusCode;
            let options = {
                not_accepting: {
                    classes: 'text-danger-900 bg-danger-300 border-b border-danger-800 p-7 font-semibold',
                },
                precinct_not_ready: {
                    classes: 'text-danger-900 bg-danger-300 border-b border-danger-800 p-7 font-semibold',
                },
                no_precinct_set: {
                    classes: 'text-danger-900 bg-danger-300 border-b border-danger-800 p-7 font-semibold',
                },
                can_be_submitted: {
                    classes: 'text-success-900 bg-success-300 border-b border-success-800 p-7 font-semibold',
                },
                ballot_votes_mismatch: {
                    classes: 'text-danger-900 bg-danger-300 border-b border-danger-800 p-7 font-semibold',

                },
                contest_votes_mismatch: {
                    classes: 'text-danger-900 bg-danger-300 border-b border-danger-800 p-7 font-semibold',
                },
                single_ballot_contest_votes_mismatch: {
                    classes: 'text-danger-900 bg-danger-300 border-b border-danger-800 p-7 font-semibold',
                },
                submitted: {
                    classes: 'text-success-900 bg-success-300 border-b border-success-800 p-7 font-semibold',
                },
                pending: {
                    classes: 'text-info-900 bg-info-200 border-b border-info-500 p-7 font-semibold',
                },
            };

            let ret = options[statusCode] || {};
            ret.code = statusCode;

            return ret;
        },
        hasBallots() {
            let thisLoc = this.precinct;
            return !thisLoc || thisLoc.ballot_ids.length === 0 ?
                false :
                true;
        },
        filteredAggResults() {
            if (!this.showBallotId) {
                return this.aggResults;
            }

            return this.aggResults.filter(r => r.ballot.id === this.showBallotId);
        },
        aggResults: function() {
            let thisLoc = this.precinct;
            if (!thisLoc) return [];

            let ballots = [];
            for (let ballotId of thisLoc.ballot_ids) {
                let ballot = _.find(this.ballots, {id:ballotId});
                if (!ballot) continue;                

                let contests = aggregateResultsPerContest(
                    { precinct_id: thisLoc.id },
                    this.currentResults,
                    ballot,
                    this.$results
                );

                contests = _.orderBy(contests, 'position');
                ballots.push({
                    ballot,
                    results: contests,
                });
            }

            return ballots;
        },
        canEnterResults() {
            // Need a machine to apply results to
            if (!this.currentMachineNum) {
                return false;
            }

            // Admins can always edit
            if (this.isadmin && this.precinct) {
                return true;
            }

            return this.$results.election.status !== 'completed' &&
                this.precinct &&
                this.precinct.status !== 'completed' &&
                this.precinct.ready;
        },
        turnoutStats: function() {
            let ret = {
                turnout: 0,
                registered: 0,
                ptc: 0,
            };

            let loc = this.precinct;
            if (loc) {
                ret.turnout = loc.turnout;
                ret.registered = loc.registered;
                ret.ptc = Math.floor(ptc(ret.registered, ret.turnout));
            }

            return ret;
        },
        ballotsWithMismatchedVotes() {
            return this.badResults?.ballots || {};
        },
        badResults() {
            // Send all this precincts results, not this.currentResults, because all the error checking
            // must be done across all tab. machines, not individual machines
            let r = precinctsWithBadResults(this.results, this.$results.locations, this.$results.ballots);
            for (let prop in r) {
                delete r[prop].precinct;
            }

            this.$emit('haserrors', !!r[this.precinct.id]);
            return r[this.precinct.id];
        },
        allContestsComplete() {
            return this.areContestsComplete();
        },
        allBallotsComplete() {
            return this.allContestsComplete;
        },
        isMultipleBallots() {
            let thisLoc = this.precinct;
            if (!thisLoc) return false;
            return thisLoc.ballot_ids.length > 1;
        },
        currentResults() {
            if (!this.currentMachineNum) {
                return this.results;
            }

            return this.results.filter(r => {
                return r.machine_num === this.currentMachineNum;
            });
        },
        hasMultipleMachines() {
            return this.precinct.num_machines > 1;
        },
    },
    methods: {
        ptc,
        propSum,
        truncForDisplay,
        createUpdateValues() {
            if (this.newTurnout) {
                this.newTurnout.original = this.precinct.turnout;
                this.newCurbside.original = this.precinct.curbside;
            } else {
                this.newTurnout = updateableValue(this.precinct.turnout, parseInt);
                this.newCurbside = updateableValue(this.precinct.curbside, parseInt);
            }
        },
        gotoTodo() {
            let cardTodoTop = this.$refs.cardTodo.getBoundingClientRect().top;
            let stickyStatus = this.$refs.stickyStatus.offsetHeight;
            let scrollTop = window.document.documentElement.scrollTop;
            window.scrollTo({top: (cardTodoTop + scrollTop) - stickyStatus - 20});
        },
        gotoLink(name) {
            // Safari has a broken scroll-padding-top CSS option so we can't simply link
            // to #fragment when we have the fixed positioned header at the top.
            // https://bugs.webkit.org/show_bug.cgi?id=179379

            this.$nextTick(() => {
                let el = document.querySelector(`a[name="${name}"]`);
                if (el) {
                    let newTop = el.getBoundingClientRect().top + window.scrollY;
                    newTop = newTop - this.$refs.cardTodo.getBoundingClientRect().height - 16;
                    window.scrollTo({
                        top: newTop,
                        left: 0,
                        // behavior: 'smooth',
                    });
                }
            });
        },
        findContestResults(contestId) {
            for (let ballot of this.aggResults) {
                let contest = ballot.results.find(r => r.id === contestId);
                if (contest) {
                    return contest;
                }
            }

            return null;
        },
        areContestsComplete(contests=null) {
            if (!contests) {
                contests = [];
                for (let ballot of this.aggResults) {
                    contests = contests.concat(ballot.results);
                }
            }

            if (contests.find(contest => !this.isContestComplete(contest))) {
                return false;
            }

            return true;
        },
        isContestComplete(contest) {
            if (this.overrideContestSubmit[contest.id]) {
                return true;
            }

            if (this.badResults?.contests[contest.id]) {
                return false;
            }

            let totalVotes = _.sumBy(contest.rows, 'votes');
            if (totalVotes === 0 && this.precinct.turnout > 0) {
                return false;
            }

            return true;
        },
        isBallotComplete(ballot) {
            return this.areContestsComplete(ballot.results);
        },
        editContest(contestId) {
            // Find and copy the current contest so we edit the copy only. The user
            // may cancel the edit at any point
            let contest = this.findContestResults(contestId);
            if (!contest) {
                console.error(`Could not find contest '${contestId}'`);
                return;
            }

            let currentValues = {};
            contest.rows.forEach((row) => {
                // Copy the object so we're not modifying the original
                currentValues[row.candidate_id] = Object.assign({}, row);
            });

            this.editingContestValues = currentValues;
            this.editingContest = contestId;
        },
        onContestFormSubmit: async function() {
            let newValues = this.editingContestValues;

            try {
                let resp = await this.$api.post('/data/contest', {
                    precinct: this.precinct.id,
                    machine: this.currentMachineNum,
                    contest: this.editingContest,
                    totals: newValues,
                });

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                // Reset any overrides for this contest so that they must re-read any warnings with
                // the changed results
                this.$delete(this.overrideContestSubmit, this.editingContest);
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }

            this.editingContestValues = {};
            this.editingContest = '';
        },
        submitResults: async function() {
            if (!confirm('Submitting for review will lock your results from any other changes. Continue?')) {
                return;
            }

            try {
                let resp = await this.$api.post('/pollworker/save', {
                    precinct: this.precinct.id,
                    status: 'completed',
                });

                if (resp.error) {
                    throw new Error(resp.error.message);
                }
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }
        },
        async onTurnoutFormSubmit() {
            try {
                let toUpdate = {
                    precinct: this.precinct.id,
                    turnout: this.newTurnout.value,
                    curbside: this.newCurbside.value,
                };

                let resp = await this.$api.post('/pollworker/save', toUpdate);
                if (resp.error) {
                    throw new Error(resp.error.message);
                }
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }
        },
        async saveBallotVotes(ballotId, votes) {
            try {
                let resp = await this.$api.post('/pollworker/save', {
                    precinct: this.precinct.id,
                    ballots: [{ id: ballotId, votes: parseInt(votes)}]
                });

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }
        },
    },
};

</script>