var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.precinct.num_machines > 1)?_c('div',{staticClass:"machine-switcher mt-10"},[_c('div',{staticClass:"font-bold"},[_vm._v("Tabulation machines")]),(!_vm.currentMachineNum)?_c('div',[_vm._v("Which machine are you entering results for?")]):_c('div',[_vm._v("Currently entering results from machine "+_vm._s(_vm.currentMachineNum))]),_c('div',{staticClass:"flex gap-5 mt-2"},_vm._l((_vm.precinct.num_machines),function(idx){return _c('a',{key:idx,staticClass:"cursor-pointer block no-underline border-2 flex items-center justify-center",class:{
                'border-green-600': _vm.currentMachineNum === idx,
                'bold': _vm.currentMachineNum === idx,
                'text-2xl': _vm.currentMachineNum === idx,
                'border-neutral-700':  _vm.currentMachineNum !== idx,

            },style:([
                _vm.currentMachineNum === idx ?
                    {'height': '75px', 'width': '66px'} :
                    {'height': '66px', 'width': '58px'} ]),on:{"click":function($event){_vm.currentMachineNum=idx}}},[_c('span',[_vm._v(_vm._s(idx))])])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }