

<template>
    <div
        id="messages"
        class="
            fixed bottom-0 right-5
            flex flex-col
            z-10
            pb-2
            max-h-3/4
            box-border
            shadow-md
            border-2 border-gray-100
            rounded-t overflow-hidden
            bg-white
        "
        :class="{
            'w-5/6': isOpen,
            'md:w-2/6': isOpen,
        }"
    >

        <!-- This is the messsages section that i need to investigate -->
        <div class="border-b p-2 text-sm bg-neutral-700 text-white">
            <!-- This is the header -->
            <template v-if="isOpen">

                <span v-if="section === 'inbox'" @click="()=> {
                        isOpen=!isOpen;
                        setInboxType(`all`);
                    }" 
                class="cursor-pointer">Inbox</span>
                


                <span v-if="section === 'thread'" @click="openInbox" class="cursor-pointer">
                    <span class="text-sm">❮</span> Back to messages
                </span>


                <span @click="isOpen=!isOpen" class="float-right cursor-pointer px-2">
                    <svg-down class="w-4 fill-current relative" style="top:3px;" data-testid="hide-messages"/>
                </span>
            </template>

            <!-- This is minimised header -->
            <template v-else>
                <div @click="() => {
                        isOpen=!isOpen;
                        setInboxType(`initialOpen`);
                    }" class="cursor-pointer inline-block">
                    <span>Messages</span>
                    <span
                        v-if="hasUnreadInboxItems"
                        class="rounded-full relative inline-block w-3 h-3 bg-red-700 ml-3 animate-pulse"
                        style="top:2px;"
                    ></span>
                </div>
                <span @click="() => {
                        isOpen=!isOpen;
                        setInboxType(`initialOpen`);
                    }" class="float-right cursor-pointer px-2 ml-10">
                    <svg-up class="w-4 fill-current relative" style="top:3px;" data-testid="show-messages"/>
                </span>
            </template>
        </div>


        <template v-if="isOpen">

            <div class="w-full flex content-center pt-0.5">
                
                <button @click="setInboxType(`message`)" 
                :class="[
                    inboxType === 'message' ? 
                        'w-3/6 h-8 flex items-center justify-start bg-neutral-700 text-white hover:text-white py-2 px-2 border border-neutral-700 hover:border-blue-500 hover:border-transparent rounded' : '', 
                    inboxType === 'notice' ? 
                    'w-3/6 h-8 flex items-center justify-start bg-white text-black py-2 px-2 border border-neutral-700 rounded hover:border-transparent hover:text-white hover:bg-neutral-700' : '', 
                    inboxType === 'all' ? 
                        'w-3/6 h-8 flex items-center justify-start bg-neutral-700 text-white hover:text-white py-2 px-2 border border-neutral-700 hover:border-blue-500 hover:border-transparent rounded' : '',
                    !inboxType ? 
                    'w-3/6 h-8 flex items-center justify-start bg-white text-black py-2 px-2 border border-neutral-700 rounded hover:border-transparent hover:text-white hover:bg-neutral-700' : '' ]"
                >
                    Messages
                    <span
                        v-if="unreadMessageVariable"
                        class="rounded-full relative inline-block w-3 h-3 bg-red-700 ml-3 animate-pulse"
                        style="top:2px;"
                    ></span>
                </button>
                <button @click="setInboxType(`notice`)" class="ml-0.5"
                :class="[
                    inboxType === 'notice' ? 
                        'w-3/6 h-8 flex items-center justify-start bg-neutral-700 text-white hover:text-white py-2 px-2 border border-neutral-700 hover:border-blue-500 hover:border-transparent rounded' : '', 
                    inboxType === 'message' ? 
                    'w-3/6 h-8 flex items-center justify-start bg-white text-black py-2 px-2 border border-neutral-700 rounded hover:border-transparent hover:text-white hover:bg-neutral-700' : '', 
                    inboxType === 'all' ? 
                        'w-3/6 h-8 flex items-center justify-start bg-neutral-700 text-white hover:text-white py-2 px-2 border border-neutral-700 hover:border-blue-500 hover:border-transparent rounded' : '',
                    !inboxType ? 
                    'w-3/6 h-8 flex items-center justify-start bg-white text-black py-2 px-2 border border-neutral-700 rounded hover:border-transparent hover:text-white hover:bg-neutral-700' : '' ]"
                >
                Notices
                <span
                        v-if="unreadNoticeVariable"
                        class="rounded-full relative inline-block w-3 h-3 bg-orange-600 ml-3 animate-pulse"
                        style="top:1px;"
                    ></span>
                </button>
            </div>

            <actions v-if="$state.isAdmin()"></actions>


            <template v-if="section === 'inbox'">
                <div class="overflow-y-auto mb-2 pr-2 ml-2" ref="elInboxScroller">

                    <div v-if="!inboxType" class="text-center py-5">
                        No inbox selected!<br />
                        Please selected an inbox from above
                    </div>
                    <div v-else-if="threads.length === 0" class="text-center py-5">
                        Your inbox is empty!<br />
                        <a @click="$refs.newMessage.focusInput()" class="cursor-pointer">Send a message</a>
                    </div>


                    <div
                        v-for="thread in threads"
                        :key="`thread-${thread.id}`"
                        @click="openThread(thread.precinct_id)"
                        class="cursor-pointer hover:bg-gray-300 px-2 py-2 text-sm"
                    >
                        <div class="float-right text-neutral-700 text-xs">{{formatTime(thread.created_at)}}</div>
                        <div class="whitespace-nowrap font-bold">
                            <span
                                v-if="unreadMessageVariable && thread.type == 'message'"
                                class="rounded-full relative inline-block w-3 h-3 bg-red-700 mr-2"
                                style="top:2px;"
                            ></span>
                            <span
                                v-if="unreadNoticeVariable && thread.type == 'notice'"
                                class="rounded-full relative inline-block w-3 h-3 bg-orange-700 mr-2"
                                style="top:2px;"
                            ></span>
                            <!--{{thread.from_admin ? 'Admin' : `${thread.precinct_key} - ${thread.precinct_name}`}}-->
                            {{isWorker ? 'Admin' : `${thread.precinct_key} - ${thread.precinct_name}`}}
                        </div>

                        <div class="text-sm text-neutral-700 italic mt-1 overflow-hidden overflow-ellipsis whitespace-nowrap">
                            {{thread.body.substr(0, 100)}}
                        </div>
                    </div>
                </div>

                <new-message
                    ref="newMessage"
                    v-model="newMessageBody"
                    @send="sendNewMessage"
                    class="ml-2"
                    :key="'newmessage-inbox'"
                >
                    <template v-slot:toolbar>
                        <form class="text-sm p-1 m-1 bg-neutral-200">
                            <select v-if="!isWorker" v-model="newMessageToPrecinct">
                                <option value="*">Notice to all precincts</option>
                                <option v-for="p in precincts" :value="p.id" :key="p.id">{{p.key}} - {{p.name}}</option>
                            </select>
                            <div v-else>
                                To: Admin
                            </div>
                        </form>
                    </template>
                </new-message>
            </template>

            <template v-else-if="section === 'thread'">
                <div class="overflow-y-auto mb-2 pr-2 ml-2" ref="elThreadScoller">
                    <div
                        v-for="msg in currentThread"
                        :key="msg.id"
                        class="mb-7 mt-2"
                        :class="[msg.type==='notice'?'border-l-4 border-accent-300 pl-3':'']"
                    >
                        <div class="overflow-hidden text-sm">
                            <span class="float-left font-bold">
                                {{msg.from_admin ? 'Admin' : `${msg.precinct_key} - ${msg.precinct_name}`}}
                            </span>
                            <span class="float-right text-gray-600 text-xs">{{formatTime(msg.created_at)}}</span>
                        </div>
                        <div class="mt-2.5 whitespace-pre">{{msg.body}}</div>
                    </div>
                </div>

                <new-message
                    ref="newMessage"
                    v-model="newMessageBody"
                    @send="sendReplyMessage"
                    class="ml-2"
                    :key="'newmessage-thread'"
                ></new-message>
            </template>
        </template>
    </div>
</template>


<script>

import _ from 'lodash';
import SvgDown from '../../assets/svgs/down.svg?inline';
import SvgUp from '../../assets/svgs/up.svg?inline';
import NewMessage from './MessagesNewMessage.vue';
import Actions from './Actions';

export default {
    components: {
        Actions,
        NewMessage,
        SvgDown,
        SvgUp,
    },
    data: function() {
        return {
            isOpen: false,
            section: 'inbox',
            threadPrecinctId: 0,
            lastInboxScrollTop: 0,
            newMessageHasFocus: false,
            newMessageBody: '',
            newMessageToPrecinct: '*',
            inboxType: 'all',
            unreadMessageVariable : false,
            unreadNoticeVariable : false
        };
    },
    computed: {
        isWorker() {
          return this.$state.isWorker();
        },
        hasUnreadInboxItems() {
            let unreadCount = 0
            this.threads.map(t => {
                if (t.hasUnread){
                    unreadCount += 1;
                }
            });
            return unreadCount;
        },
        messages() {
            return this.$services.messages.state.messages;
        }, 
        threads() {
            let ordered = _.orderBy(this.messages, 'created_at', ['asc']);
            let locationThreads = {};
            ordered.forEach(m => {
                // We don't want to include notices in the inbox view as a notice to everyone
                // just floods the list and looses context of message discussions
                // if (m.type !== 'notice') {
                //     locationThreads[m.precinct_id] = locationThreads[m.precinct_id] || [];
                //     locationThreads[m.precinct_id].push(m);
                // }
            
                if (this.inboxType == 'message') {
                    if (m.type !== 'notice') {
                        locationThreads[m.precinct_id] = locationThreads[m.precinct_id] || [];
                        locationThreads[m.precinct_id].push(m);
                    }
                } else if (this.inboxType == 'notice'){
                    if (m.type == 'notice') {
                        locationThreads[m.precinct_id] = locationThreads[m.precinct_id] || [];
                        locationThreads[m.precinct_id].push(m);
                    }
                } else if (this.inboxType == "all"){
                    locationThreads[m.precinct_id] = locationThreads[m.precinct_id] || [];
                    locationThreads[m.precinct_id].push(m);
                }
            });

            let threads = [];
            Object.values(locationThreads).forEach(threadMsgs => {
                let lastMsg = threadMsgs[threadMsgs.length - 1];
                threads.push({
                    ...lastMsg,
                    hasUnread: this.isThreadUnread(lastMsg.precinct_id)
                });
            });

            threads = _.orderBy(threads, ['hasUnread', 'created_at', 'precinct_key'], ['desc', 'desc', 'asc']);
            this.hasUnreadNotices();
            this.hasUnreadMessages();
            return threads;
        },
        currentThread() {
            let messages = this.messages.filter(m => {
                if (m.precinct_id === this.threadPrecinctId && this.inboxType === "all"){
                    return m
                }else if (m.precinct_id === this.threadPrecinctId && m.type === this.inboxType){
                    return m
                }
            });
            return _.orderBy(messages, 'created_at', ['asc'])
        },
        precincts() {
            return this.$results.precincts;
        },
    },
    created() {
        this.listen(this.$state, 'message.open', () => {
            // console.log("Messages.vue -- message.open triggered ")
            this.isOpen = true;
            // console.log("this.isOpen : " + this.isOpen)
            this.$nextTick(() => {
                // console.log("Messages.vue -- this.openInbox() triggered")
                this.openInbox();

            });
        });

        this.listen(this.$state, 'message.new', (event={}) => {
            if (this.newMessageBody) {
                if (!confirm('Clear existing message to start a new one?')) {
                    return;
                }
            }

            // Accept either a precinctId or precinctKey
            let precinctId = event.precinctId;
            if (!precinctId && event.precinctKey) {
                let p = _.find(this.precincts, {key: event.precinctKey});
                precinctId = p ? p.id : null;
            }

            let precinctThread = this.threads.find(t => t.precinct_id === precinctId);

            this.clearNewMessage();
            this.isOpen = true;


            // Inside nextTick as the messages UI might be closed and needs a tick to open
            this.$nextTick(() => {
                this.newMessageBody = event.body || '';
                this.newMessageToPrecinct = precinctId || '*';

                if (precinctThread) {
                    this.openThread(precinctId);
                } else {
                    this.openInbox();
                }

                this.$refs.newMessage.focusInput();
            });
        });
    },
    methods: {
        hasUnreadNotices() {
            // console.log("hasUnreadNotices() ....")

            if (this.$services.messages.state.unreadCountTypeNotice > 0){
                this.unreadNoticeVariable =  true 
            } else {
                this.unreadNoticeVariable = false
            }
            // console.log(this.$services.messages.state.unreadCountTypeNotice);
        },
        hasUnreadMessages() {
            // console.log("hasUnreadMessages() ....")

            if (this.$services.messages.state.unreadCountTypeMessage > 0){
                this.unreadMessageVariable =  true 
            } else {
                this.unreadMessageVariable = false
            }
            // console.log(this.$services.messages.state.unreadCountTypeMessage);
        },

        setInboxType(event){

            // When the message box in opened from a minimised state then set inboxType to the following based on the user type
            if (event == "initialOpen"){
                if (this.$state.user.admin || this.$state.user.system){
                    this.inboxType = "all"
                } else {
                    this.inboxType = "message"
                }
                return 
            }

            if (!this.inboxType && event){
                this.inboxType = event
                return 
            } else if (this.inboxType == "all" && event == "message"){
                this.inboxType = event
                return 
            } else if (this.inboxType == "all" && event == "notice"){
                this.inboxType = event
                return 
            } else if (event == this.inboxType){
                this.inboxType = null;
                return 
            } else if (event != this.inboxType && this.inboxType != "all" ){
                this.inboxType = "all";
            }

        },

        isFromUs(msg) {
            return msg.from_admin === this.$state.isAdmin();
        },
        formatTime(d) {
            let out = d.toLocaleDateString() + ', ' + d.toLocaleTimeString();
            return out;
        },
        async sendNewMessage() {
            let toPrecint = this.newMessageToPrecinct;
            if (!toPrecint) {
                return;
            }

            let body = this.newMessageBody.trim();
            if (!body) {
                return;
            }

            await this.$services.messages.sendNewMessage(toPrecint, body);
            this.clearNewMessage();
            this.loadMessages();
        },
        async sendReplyMessage() {
            let body = this.newMessageBody.trim();
            if (!body) {
                return;
            }

            await this.$services.messages.sendNewMessage(this.threadPrecinctId, body);

            this.clearNewMessage();
            await this.loadMessages();
            let el = this.$refs.elThreadScoller;
            el.scrollTop = el.scrollHeight;
        },
        clearNewMessage() {
            this.newMessageBody = '';
            this.newMessageToPrecinct = '*';
        },
        async loadMessages() {
            await this.$services.messages.loadMessages();
        },
        isThreadUnread(precinct_id) {

            let threadMessages = _.filter(this.messages, {precinct_id});
            return !!threadMessages.find(m => this.isMessageUnread(m));
        },
        isMessageUnread(message) {
            if (this.isWorker && !message.from_admin) {
                // we sent it
                return false;
            }

            if (this.isWorker && message.from_admin) {
                return !message.read_at;
            }

            if (!this.isWorker && message.from_admin) {
                return false;
            }

            if (!this.isWorker && !message.from_admin) {
                return !message.read_at;
            }
        },
        openInbox() {
            this.hasUnreadNotices();
            this.hasUnreadMessages();

            this.section = 'inbox';
            this.$nextTick(() => {
                if (this.lastInboxScrollTop !== undefined) {
                    this.$refs.elInboxScroller.scrollTop = this.lastInboxScrollTop;
                }
            });
        },
        openThread(precinctId) {    
            this.section = 'thread';
            this.threadPrecinctId = precinctId;
            this.lastInboxScrollTop = this.$refs.elInboxScroller?.scrollTop;
            this.$nextTick(() => {
                let el = this.$refs.elThreadScoller;
                el.scrollTop = el.scrollHeight;


                if (this.isThreadUnread(this.threadPrecinctId)) {
                    let msgIds = [];

                    this.currentThread.forEach(msg => {

                        if (!msg.read_at) {
                            msgIds.push(msg.id);
                        }
                    });
                    this.$services.messages.markMessageRead(msgIds);
                    this.hasUnreadNotices();
                    this.hasUnreadMessages();
                }
            });
        }
    },
};
</script>

<style scoped>
table td {
    padding: 5px 5px;
}

</style>
