const EventEmitter = require('events');
export default class Api extends EventEmitter {
    constructor(path, deviceId) {
        super();

        this.path = path;
        this.deviceId = deviceId || '';
        this.longpolling = false;
        this.failedReqs = 0;
    }

    url(path, query) {
        let url = joinUrlPaths(this.path, path);
        if (query) url = addQueryString(url, query);
        return url;
    }

    longpoll() {
        return {
            post: (url, query, data, fetchOpts={}) => {
                fetchOpts.longpolling = true;
                return this.post(url, query, data, fetchOpts);
            },
            get: (url, query, fetchOpts={}) => {
                fetchOpts.longpolling = true;
                return this.get(url, query, fetchOpts);
            }
        };
    }

    req(method, _url, query, body, fetchOpts={}) {
        let url = joinUrlPaths(this.path, _url);
        if (query) url = addQueryString(url, query);

        let opts = {
            method: method,
            credentials: 'include',
        };

        let headers = fetchOpts.headers || {};
        headers['Authorization'] = 'Bearer ' + this.deviceId;

        if (body) {
            if (body.constructor === FormData) {
                opts.body = body;
            } else {
                headers['Content-Type'] = 'application/json';
                opts.body = JSON.stringify(body);
            }
        }

        opts = { ...opts, ...fetchOpts, headers };

        let longpolling = !!fetchOpts.longpolling;
        let abortCtrl = null;
        let timeoutTmr = null;
        
        let api = this;
        let prom = new Promise((resolve, reject) => {
            async function doReq() {
                let req = null;
                try {
                    // A previous request may have been cancelled
                    if (prom.cancelled) {
                        throw new Error('Cancelled');
                    }

                    abortCtrl = new AbortController();
                    opts.signal = abortCtrl.signal;

                    if (longpolling) {
                        timeoutTmr = setTimeout(() => {
                            prom.timedout = true;
                            timeoutTmr = null;
                            abortCtrl.abort();
                         }, 30000);
                    }

                    req = await fetch(url, opts);
                    if (timeoutTmr) {
                        clearTimeout(timeoutTmr);
                        timeoutTmr = null;
                    }
                } catch (err) {
                    if (timeoutTmr) {
                        clearTimeout(timeoutTmr);
                        timeoutTmr = null;
                    }

                    if ((err.name === 'AbortError' && !prom.timedout) || prom.cancelled) {
                        // prom.cancelled = true;
                        let cancelledErr = new Error('Request cancelled');
                        cancelledErr.name = 'cancelled';
                        reject(cancelledErr);
                        return;
                    }
                }




                

                // Getting a 403 (request forbidden) means that we don't have access to make
                // any API calls. The admin needs to approve this instance
                if (req?.status === 403) {
                    try {
                        let authErr = await req.json();
                        if (authErr?.error?.code === 'rejected_device') {
                            api.emit('authorized', {error: 'rejected_device'});
                        }
                    } catch (err) {
                        console.error('Error parsing 403 error')
                    }

                    reject(req);
                    return;
                } else if (req?.status === 401) {
                    // Getting a 401 (request unauthorized) means that we're not logged in anymore
                    api.emit('authorized', {error: 'logged_out'});
                    reject(req);
                    return;
                } else {
                    // api.emit('authorized', null);
                }

                // if long polling and an error occured, wait a bit until the next request
                if (longpolling && !req && !prom.timedout) {
                    this.failedReqs = 0;
                    setTimeout(doReq, 10000);
                    return
                // If we timed out+aborted, req will be null
                } else if (longpolling && (!req || req.status === 504)) {
                    // Either we timed ourselves out or the server timedout (504)
                    this.failedReqs = 0;
                    setTimeout(doReq, 100);
                    return
                } else if (longpolling && req?.status > 500) {
                    // longpolling but some http/server error occured
                    this.failedReqs++;
                    let waitLen = Math.min(1000 * 40, (2 ** this.failedReqs) * 1000);
                    setTimeout(doReq, waitLen);
                    return;
                }

                this.failedReqs = 0;

                if (req.status >= 200 && req.status < 300) {
                    if (fetchOpts.responseType === 'blob') {
                        let blob = await req.blob();
                        let contentDisposition = req.headers.get('Content-Disposition');
                        resolve({ blob, contentDisposition, status: req.status });
                    } else {
                        let json = await req.json();
                        resolve({ ...json, status: req.status });
                    }
                } else if (req.status === 503) {
                    let json = await req.json();
                    resolve({ ...json, status: req.status });
                } else {
                    let error = await req.text();
                    reject({ error, status: req.status });
                }
            }
            
            setTimeout(doReq, 0)
        });

        prom.cancel = () => {
            if (abortCtrl) {
                abortCtrl.abort();
            }
            prom.cancelled = true;
        };
        prom.cancelled = false;
        prom.timedout = false;
        return prom;
    }

    post(url, query, data, fetchOpts={}) {
        // accept (url, data) or (url, query, data)
        if (!data) {
            data = query;
            query = null;
        }
        return this.req('POST', url, query, data, fetchOpts);
    }

    get(url, query, fetchOpts={}) {
        return this.req('GET', url, query, null, fetchOpts);
    }
}

function addQueryString(url, queryData) {
    let query = objToQuerystring(queryData);
    return url.indexOf('?') > -1 ?
        url + '&' + query :
        url + '?' + query;
}

function objToQuerystring(obj) {
    return Object.keys(obj).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    }).join('&');
}

function joinUrlPaths(...args) {
    let joined = args.shift() || '/';
    for (let i=0; i<args.length; i++) {
        let part = args[i];
        if (joined[joined.length - 1] === '/' && part[0] === '/') {
            joined += part.substr(1);
        } else if (joined[joined.length - 1] !== '/' && part[0] !== '/') {
            joined += '/' + part;
        } else {
            joined += part;
        }
    }

    return joined;
}
