<template>

    <div>
        <div class="auth-level-box-container">
            <div class="auth-level-box" :class="{'auth-level-box--active': openAuthLevelBox === 'accept_all'}" @click="openAuthLevelBox='accept_all'">
                <div class="mb-3">
                    <span class="font-bold">Any device</span>
                    <div v-if="authLevel==='accept_all'" class="inline ml-3 text-sm float-right">
                        <span class="bg-success-600 text-white inline-block px-2 py-0 rounded">Active</span>
                    </div>
                </div>
                <div class="text-sm">
                    <p>Any device can be used to access your election as long as they have a correct username and password.</p>
                    <a class="mr-5 inline-block no-underline cursor-pointer hover:underline">
                        More <svg-down-squared class="inline" />
                    </a>
                </div>
            </div>

            <div class="auth-level-box" :class="{'auth-level-box--active': openAuthLevelBox === 'known_device'}" @click="openAuthLevelBox='known_device'">
                <div class="mb-3">
                    <span class="font-bold">Trusted devices only</span>
                    <div v-if="authLevel==='known_device'" class="inline ml-3 text-sm float-right">
                        <span class="bg-success-600 text-white inline-block px-2 py-0 rounded">Active</span>
                    </div>
                </div>
                <div class="text-sm">
                    <p>Only the trusted devices below can be used to log in. The device may be used on any WiFi or internet connection.</p>
                    <a class="mr-5 inline-block no-underline cursor-pointer hover:underline">
                        More <svg-down-squared class="inline" />
                    </a>
                </div>
            </div>

            <div class="auth-level-box" :class="{'auth-level-box--active': openAuthLevelBox === 'known_device_location'}" @click="openAuthLevelBox='known_device_location'">
                <div class="mb-3">
                    <span class="font-bold">Locked down</span>
                    <div v-if="authLevel==='known_device_location'" class="inline ml-3 text-sm float-right">
                        <span class="bg-success-600 text-white inline-block px-2 py-0 rounded">Active</span>
                    </div>
                </div>
                <div class="text-sm">
                    <p>Only trusted devices from their approved locations can be used to log in.</p>
                    <a class="mr-5 inline-block no-underline cursor-pointer hover:underline">
                        More <svg-down-squared class="inline" />
                    </a>
                </div>
            </div>
        </div>


        <div class="bg-neutral-200 border border-neutral-600 rounded-b">
            <div v-if="!openAuthLevelBox" class="h-1">
            </div>
            <div v-else class="flex my-5 mx-6">
                <svg-help />

                <div v-if="openAuthLevelBox==='accept_all'">
                    <p>Any device can be used to access your election as long as they have a correct username and password.</p>
                    <p>Devices and locations will be recorded below so that you can optionally trust them in future for a live election. This may be good during training or testing.</p>
                    <div v-if="authLevel !== 'accept_all'">
                        <button @click="setAuthLevel('accept_all')" class="btn-success">Set active</button>
                    </div>
                    <div v-else>
                        <span class="bg-success-600 text-white inline-block px-5 py-1">Currently active</span>
                    </div>
                </div>

                <div v-if="openAuthLevelBox==='known_device'">
                    <p>Only the trusted devices below can be used to log in. The device may be used on any WiFi or internet connection.</p>
                    <p>If TallyPoint has been deployed to all your devices from the same location, you can trust all your devices
                        safely together before distributing them. Any attempt to log in using an unknown device or location must
                        be approved by an admin.</p>
                    <p
                        v-if="authLevel !== 'known_device' && Object.keys(precinctsWithoutTrustedDevices).length > 0"
                        class="border-danger-600 border p-5"
                    >
                        <span class="text-danger-600"><span class="font-bold">Warning!</span> These precincts do not yet have any trusted devices logged in.</span> <br />
                        <span>Activating this level now may disconnect any users logged into these precincts until you trust their devices.</span>
                        <ul>
                            <li class="ml-3" v-for="p in precinctsWithoutTrustedDevices" :key="p.key">{{p.key + ' - ' + p.name}}</li>
                        </ul>
                    </p>
                    <div v-if="authLevel !== 'known_device'">
                        <button @click="setAuthLevel('known_device')" class="btn-success">Set active</button>
                    </div>
                    <div v-else>
                        <span class="bg-success-600 text-white inline-block px-5 py-1">Currently active</span>
                    </div>
                </div>

                <div v-if="openAuthLevelBox==='known_device_location'">
                    <p>Only trusted devices from their approved locations can be used to log in.</p>
                    <p>TallyPoint devices cannot be moved to another location, use a different WiFi or internet connection. Any attempt to log in using an unknown device or location must be approved by an admin.</p>
                    <div v-if="authLevel !== 'known_device_location'">
                        <button @click="setAuthLevel('known_device_location')" class="btn-success">Set active</button>
                    </div>
                    <div v-else>
                        <span class="bg-success-600 text-white inline-block px-5 py-1">Currently active</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
.auth-level-box-container {
    @apply flex;
}
.auth-level-box {
    @apply flex-1 mx-2 p-3 bg-white border border-b-0 border-neutral-600 rounded-t relative cursor-pointer;
    top: 1px;
}
.auth-level-box:hover {
    @apply bg-neutral-200;
}
.auth-level-box--active {
    @apply bg-neutral-200;
}
.auth-level-box:first-child {
    @apply ml-0;
}
.auth-level-box:last-child {
    @apply mr-0;
}

</style>

<script>

import _ from 'lodash';
import SvgHelp from '../../assets/svgs/help.svg?inline';
import SvgDownSquared from '../../assets/svgs/down_squared.svg?inline';

export default {
    components: {
        SvgHelp,
        SvgDownSquared,
    },
    data: function() {
        return {
            openAuthLevelBox: '',
        };
    },
    computed: {
        authLevel() {
            return this.$results.election.auth_level;
        },
        precinctsWithoutTrustedDevices() {
            let precincts = {};
            this.$results.precincts.forEach(precinct => {
                precincts[precinct.id] = {id: precinct.id, key: precinct.key, name: precinct.name, trustedDevs: 0};
            });

            this.$services.devices.users.forEach(user => {
                user.locations.forEach(loc => {
                    if (loc.approved_at && precincts[user.precinct_id]) {
                        precincts[user.precinct_id].trustedDevs++;
                    }
                });
            });

            for (let pId in precincts) {
                if (precincts[pId].trustedDevs > 0) {
                    delete precincts[pId];
                }
            }

            return precincts;
        },
    },
    mounted() {
    },
    methods: {
        orderBy: _.orderBy,
        async setAuthLevel(newLevel) {
            await this.$api.post('/mgr/election', {
                auth_level: newLevel,
            });
            //this.$results.refresh();
            // TODO: should be an api call that sets+returns the new level, which then sets that to election.auth_level
            //this.$results.election.auth_level = newLevel;
        },
    },
};

</script>
