export default class QueueListener {
    constructor(api, appState) {
        this.api = api;
        this.appState = appState;

        // Loaded = has loaded at least once and is working
        this.loaded = false;
        this.lastEventTs = 0;
        this.running = false;
        this.tmr = 0;
    }

    async refresh() {
        try {
            let data = await this.api.longpoll().get('/data/listen', {cb: Date.now(), after: this.lastEventTs});
            this.loaded = true;

            (data.events || []).forEach((ev, evIdx) => {
                try {
                    this.appState.$emit('serverevent.' + ev.name, ev.payload);
                } catch (err) {
                    console.error(err);
                }

                // Record the last event so we can get events after it on the next request
                if (evIdx === data.events.length - 1) {
                    this.lastEventTs = ev.ts;
                }
            });
        } catch (err) {
            // Timeouts are expected as we're long polling
            if (err.status !== 504) {
                console.error(err);
            }
        }
    }

    async start() {
        this.running = true;
        if (this.inCall) return; // already running

        this.inCall = true;

        try {
            await this.refresh();
        } catch (err) {
            console.error('QueueListener data error:', err);
        }

        this.inCall = false;
        if (!this.running) {
            return;
        }

        this.tmr = setTimeout(() => {
            this.start();
        }, 1000);
    }

    stop() {
        clearTimeout(this.tmr);
        this.running = false;
    }
}
