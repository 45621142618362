import Vue from 'vue';
export default class Messages {
    constructor(api, appState) {
        this.api = api;
        this.appState = appState;

        this.state = Vue.observable({
            messages: [],
            unreadCount: 0,
            unreadCountTypeMessage:0,
            unreadCountTypeNotice:0
        });

        appState.$on('serverevent.messages.new', () => {
            this.loadMessages();
        });
    }

    unreadMessages() {
        return this.state.messages.filter(m => {
            if (m.read_at) {
                return false;
            }

            if (this.appState.isAdmin() && !m.from_admin) {
                return true;
            }

            if (this.appState.isWorker() && m.from_admin) {
                return true;
            }
        });
    }

    unreadMessagesByType(passedType) {
        return this.state.messages.filter(m => {
            if(m.type !== passedType){
                return false
            }
            if (m.read_at) {
                return false;
            }

            if (this.appState.isAdmin() && !m.from_admin) {
                return true;
            }

            if (this.appState.isWorker() && m.from_admin) {
                return true;
            }
        });
    }

    async loadMessages() {
        let resp = await this.api.get('/data/messages', {precinct: this.appState.user.precinctId});
        this.state.messages = resp.messages.map(msg => {
            msg.created_at = new Date(msg.created_at);
            msg.read_at = msg.read_at ? new Date(msg.read_at) : null;
            return msg;
        });

        this.state.unreadCount = this.unreadMessages().length;
        this.state.unreadCountTypeMessage = this.unreadMessagesByType("message").length;
        this.state.unreadCountTypeNotice = this.unreadMessagesByType("notice").length;
    }

    async markMessageRead(messageIds) {
        let msgIds = Array.isArray(messageIds) ?
            messageIds :
            [messageIds];

        await this.api.post('/data/message', {
            read: msgIds,
        });

        this.state.unreadCountTypeMessage = this.unreadMessagesByType("message").length;
        this.state.unreadCountTypeNotice = this.unreadMessagesByType("notice").length;

        await this.loadMessages();
    }

    async sendNewMessage(toPrecinct, body) {
        let isWorker = this.appState.isWorker();
        let query = {};

        if (isWorker) {
            // Workers can only send to the admin (represented by 0)
            toPrecinct = 0;

            // Admins can override as a precinct
            query.precinct = this.appState.user.precinctId;
        }

        await this.api.post('/data/messages', query, {
            body,
            precinct: toPrecinct,
        });
    }
}
