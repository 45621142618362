<template>
    <div class="main">
        <header class="bg-neutral-700 text-neutral-100">
        <div class="md:flex">
            <div class="flex-grow my-2">
                <div><router-link :to="{name:'home'}" class="font-bold no-underline text-neutral-300">TallyPoint</router-link></div>
                <div class="text-sm" v-if="this.$state.site.locationName">
                    {{ this.$state.site.locationName }}, {{ this.$state.site.electionName }}
                </div>
            </div>
            <div class="flex items-center my-2">
                <nav>
                    <div><router-link :to="{name:'home'}" class="font-bold no-underline text-neutral-300">Home</router-link></div>
                </nav>
            </div>
        </div>
    </header>
        <section class="w-96 mx-auto mt-24 bg-neutral-200 overflow-hidden rounded shadow flex flex-col content-center flex-wrap">
            <h4 class="flex justify-center text-neutral-800">About</h4>
            <div class="w-2/4 flex justify-between	">
                <label class="text-neutral-800">Name</label>
                <!-- <label class="mx-2">:</label> -->
                <label class="font-semibold">TallyPoint</label>
            </div>
            <div class="pt-2 w-2/4 mb-5 flex justify-between	">
                <label class="text-neutral-800">Version</label>
                <!-- <label class="mx-2">:</label> -->
                <label class="items-end font-semibold">{{version}}</label>
            </div>
        </section>
    </div>
</template>

    
<script>

export default {
    data() {
        return {
          version: process.env.VUE_APP_VERSION || "1 . 7 . 1"
        };
    },
    methods: {
      async logout() {
          window.location = this.$api.location("/");
      },
  },
};
 
</script>