<template v-if="displayOptions.display_provisional_voter">
<div class="main">

<div class="card-title">
    <h3>Provisional ballots</h3>
    <div>Add, verify or change the provisional voters for this precinct</div>
</div>

<section class="card card--inline">
    <a
        @click="showSection='list'"
        class="inline-block cursor-pointer p-5 mr-5 bg-neutral-200 hover:bg-neutral-400"
        :class="{'no-underline': showSection!=='list'}"
    >Review entries</a>
    <a
        @click="showSection='addnew'"
        class="inline-block cursor-pointer p-5 mr-5 bg-neutral-200 hover:bg-neutral-400"
        :class="{'no-underline': showSection!=='addnew'}"
    >Submit provisionals</a>
</section>

<section v-if="showSection==='list'" class="card">
    <provisional-ballot-table :provisionals="provisionals" @update:provisionals="updateProvisionals"/>
</section>

<section v-if="showSection==='addnew'" class="card">
    <div class="w-full md:w-7/12 lg:w-6/12 bg-neutral-200 p-5">
        <div v-if="complete">
            <div class="border border-success-800 bg-success-200 text-success-900 p-5 m-10 ml-auto mr-auto text-center w-6/12">
                <p>Provisional ballot has been submitted. <a class="cursor-pointer underline" @click="complete=false">Submit another</a></p>
            </div>
        </div>
        <form v-else @submit.prevent="onVoterInfoFormSubmit">
            <h4 class="text-center">Voter Information</h4>

            <label>
                <span>First Name</span>
                <input v-model="form.first_name" v-focus />
            </label>
            <div v-if="formErrors.first_name" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.first_name.join(' ')}}</div>

            <label>
                <span>Middle Name</span>
                <input v-model="form.middle_name" />
            </label>
            <div v-if="formErrors.middle_name" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.middle_name.join(' ')}}</div>

            <label>
                <span>Last Name</span>
                <input v-model="form.last_name" />
            </label>
            <div v-if="formErrors.last_name" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.last_name.join(' ')}}</div>

            <label>
                <span>Address</span>
                <input v-model="form.address" />
            </label>
            <div v-if="formErrors.address" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.address.join(' ')}}</div>

            <label>
                <span>Phone</span>
                <input v-model="form.phone" />
            </label>
            <div v-if="formErrors.phone" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.phone.join(' ')}}</div>

            <label>
                <span>Year of Birth</span>
                <input v-model="form.dob" type="number" />
            </label>
            <div v-if="formErrors.dob" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.dob.join(' ')}}</div>

            <label>
                <span>Did Voter Sign Provisional Envelope?</span>
                <select v-model="form.voter_signed">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select>
            </label>
            <div v-if="formErrors.voter_signed" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.voter_signed.join(' ')}}</div>

            <label>
                <span>Did EO Sign Provisional Envelope?</span>
                <select v-model="form.eo_signed">
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                </select>
            </label>
            <div v-if="formErrors.eo_signed" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.eo_signed.join(' ')}}</div>

            <label>
                <span>Reason for provisional</span>
                <select v-model="form.reason">
                    <option v-for="reason in provisionalReasons" :value="reason.code" :key="reason.code">
                        {{reason.label}}
                        {{ reason.requiresNote ? '(Please explain below)' : '' }}
                    </option>
                </select>
            </label>
            <div v-if="formErrors.reason" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.reason.join(' ')}}</div>

            <label v-if="this.$results.election.election_settings.provisional_parties.parties_enabled">
                <span>Party</span>
                <select v-model="form.party">
                    <option v-for="party in this.$results.election.election_settings.provisional_parties.parties" :value="party.name" :key="party.name">
                        {{party.name}}
                    </option>
                </select>
            </label>
            <div v-if="formErrors.party" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.party.join(' ')}}</div>

            <label>
                <span>Notes to office</span>
                <textarea v-model="form.notes"></textarea>
            </label>
            <div v-if="formErrors.notes" class="text-danger-500 text-right mb-5 text-sm">{{formErrors.notes.join(' ')}}</div>

            <button
                type="submit"
                class="btn-success mx-auto block mt-7"
                :disabled="loading"
            >{{ loading ? 'Sending...' : 'Submit Voter Information' }}</button>
        </form>
    </div>
</section>

</div>
</template>

<script>

import * as Validator from 'validatorjs';
import ProvisionalBallotTable from '../../components/ProvisionalBallotTable';

export default {
    components: {
        ProvisionalBallotTable,
    },
    data: function() {
        return {

            provisionals: [],
            loading: false,
            form: {
                first_name: '',
                middle_name: '',
                last_name: '',
                address: '',
                phone: '',
                dob: '',
                reason: '',
                party: '',
                notes: '',
                voter_signed: null,
                eo_signed: null,
            },
            formErrors: {},
            complete: false,
            showSection: 'list',
            openRows: {},
            displayOptions: {
                display_provisional_voter : false
            }
        };
    },
    computed: {
        provisionalReasons() {
            return this.$results.election.election_settings.provisional_types;
        },
    },
    created() {
        if (this.$results.election.election_settings.provisional_types && this.$results.election.election_settings.provisional_types[0]) {
          this.form.reason = this.$results.election.election_settings.provisional_types[0].code;
        }
    },
    mounted() {
        // This will return the user back to "/worker" if the display_provisional_voter boolean in the elections db is false. 
        // The manager would have to change this to true in election settings to alter this functionality.
        this.pullDisplayOptions();
        this.reloadProvisionals();
    },
    methods: {
        updateProvisionals(updatedData) {
            this.provisionals = updatedData;
        },
        async pullDisplayOptions(){
            await this.$api.post('/pollworker/displayoptions',{options:null})
            .then(resp => {
                if (!resp.returnedOptions.provisional){
                    this.$router.push(this.routeurl('record.home'));
                }
            })
        },
        async reloadProvisionals() {
            try {
                let resp = await this.$api.get('/pollworker/provisionals');
                if (resp && resp.provisionals) {
                    this.provisionals = resp.provisionals
                }
            } catch (err) {
                console.error(err);
            }
        },
        onVoterInfoFormSubmit: async function() {
            let form = {
                first_name: this.form.first_name,
                middle_name: this.form.middle_name,
                last_name: this.form.last_name,
                address: this.form.address,
                phone: this.form.phone,
                dob: this.form.dob,
                reason: this.form.reason,
                party: this.form.party,
                notes: this.form.notes,
                precinct: this.$state.user.precinctId,
                voter_signed: this.form.voter_signed,
                eo_signed: this.form.eo_signed,
            };

            let formRules = {
                first_name: 'required',
                middle_name: 'present',
                last_name: 'required',
                address: 'required',
                phone: 'present',
                dob: 'required|date',
                reason: 'required|alpha_num|between:0,5',
                party: this.$results.election.election_settings.provisional_parties.parties_enabled ? 'required' : 'present',
                notes: 'present',
                voter_signed: 'required|boolean',
                eo_signed: 'required|boolean',
            };
            let validate = new Validator(form, formRules);
            this.formErrors = validate.errors.all();
            if (validate.fails()) {
                console.log(validate.errors.all())
                return;
            }

            try {
                this.loading = true;
                let resp = await this.$api.post('/pollworker/report_voter', form);

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                // Reset the form
                for (let prop in this.form) {
                    this.form[prop] = '';
                }
                this.complete = true;
                this.reloadProvisionals();

            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.log(err.message);
            }

            this.loading = false;
        },
    },
};

</script>
