<template>
    <div class="main">
    
        <div v-if="isElectionDate && showDatePopup" class="w-full h-20 left-0 absolute top-0">
            <div ref="stickyStatus" class="sticky top-0 z-10 text-center">
                <div class="flex text-info-900 bg-info-200 border-b border-info-500 p-7 font-semibold">
                    <div class="flex-grow">
                        <template v-if="isElectionDate">
                            It is now the day of the election. Remember to set the election status to "Open"
                        </template>
                    </div>
    
                    <svg-cross width="auto" height="auto" class="w-7 cursor-pointer opacity-60" @click="showDatePopup = !showDatePopup"/>
                </div>
            </div>
        </div>
    
    <section v-if="stats.total === 0">
            <div class="text-danger-800 bg-danger-300 border border-danger-800 p-5 m-5 w-6/12 mx-auto text-center">
                <p><b>No polling locations have yet been added</b><p>
                <p>Review your election settings and import your election data to start recording results</p>
                <p class="mt-5"><router-link :to="route('manager.import_election', null, '#election-import')">Election Settings</router-link></p>
            </div>
    </section>
    
      <section v-if="stats.total > 0" class="section-election-status">
          <div class="block md:flex justify-evenly my-10 mb-20">
            <div class="text-center">
                <h3>{{ stats.total }} Precincts</h3>
                <div class="flex justify-center">
                    <div class="mx-2 bg-green-600 p-6 rounded shadow-md w-32 h-28">
                        <div class="text-xl">{{ stats.total }}</div>
                        <div>Ready</div>
                    </div>
                    <div class="mx-2 bg-orange-300 p-6 rounded shadow-md w-32 h-28">
                        <div class="text-xl">{{ stats.pending }}</div>
                        <div>Pending</div>
                    </div>
                    <div class="mx-2 bg-orange-300 p-6 rounded shadow-md w-32 h-28">
                        <div class="text-xl">{{ stats.completed }}</div>
                        <div>Completed</div>
                    </div>
                </div>
            </div>
    
            <div class="text-center my-10 md:my-0">
                <h3>{{displayNumber(voterStats.registered)}} Registered voters</h3>
                <vc-donut
                    background="white" foreground="grey"
                    :size="120" unit="px" :thickness="30"
                    has-legend legend-placement="right"
                    :sections="[
                        {label: 'Turnout', value: voterStats.turnout > voterStats.registered ? voterStats.registered : voterStats.turnout},
                    ]" :total='voterStats.registeredIsZero ? voterStats.registered+1 : voterStats.registered'
                    :start-angle="0" :auto-adjust-text-size="true"
                    @section-click="(section, event)=>{alert(section.label)}">
                    <h4>{{ displayNumber(parseInt(voterStats.turnoutPtc)) }}%</h4>
                </vc-donut>
            </div>
          </div>
      </section>
    
      <section v-if="stats.total > 0" class="card">
            <div class="block md:flex">
                <form class="md:w-6/12 md:mr-12">
                    <h3 class="mt-0">Election</h3>
                    <label>
                        <span class="font-bold">Status: </span>
                        <select v-model="electionStatus" class="w-full font-bold">
                            <option value="open">Open</option>
                            <option value="closed">Polls Closed - Collecting Results</option>
                            <option value="completed">Closed</option>
                        </select>
                    </label>
                </form>
    
                <div class="md:w-6/12 my-10 md:my-0">
                    <h3 class="mt-0">Exports</h3>
                    <div>
                        <a @click="showExports=!showExports" class="cursor-pointer no-underline" data-testid="export-reveal-link">Export a canvas report, VERIS import or website <span class="text-sm">❯</span></a>
                    </div>
    
                    <div v-if="showExports" class="mt-10">
                        <form v-if="false">
                            Import spreadsheets such as VERIS reports to enable various reports for download.
                            <router-link :to="route('manager.import_election')">Import data</router-link>
                        </form>
    
                        <form>
                            <div class="mb-5">
                                <div class="flex gap-4 mt-4">
                                    <div class="btn-export-div">
                                        <button @click.prevent="exportFile(`exporting`, `canvas`, `precincts`, `canvas`)" class="btn-export w-48" data-testid="export-canvas-precinct">Canvas : Precincts only</button>
                                        <button v-if="canvas.exporting" class="btn-export btn-export-svg"><SvgLoading class="rotating"/></button>
                                        <button v-if="(canvas.download_id && !canvas.error && !canvas.exporting) || (canvas.completed && !canvas.error && !canvas.exporting)" class="btn-export btn-export-svg" @click.prevent="exportFile(`download`, `canvas`, null, `canvas`)"><SvgDownload/></button>
                                        <button v-if="canvas.error" class="btn-export btn-export-svg"><SvgDownloadError/></button>
                                        <!-- <button v-if="canvas.completed || canvas.error" class="btn-export btn-export-svg" @click.prevent="exportFile(`reset`, `canvas`, `precincts`, `canvas`)"><SvgCross/></button> -->
                                    </div>
    
                                    <div class="flex-grow italic text-sm text-right pr-1 flex flex-col justify-center ">
                                        Precincts only will remove the <br> under/over votes and mail-ins.
                                    </div>
                                </div>
    
                                <div class="flex gap-4 mt-4">
                                    <div class="btn-export-div">
                                        <button @click.prevent="exportFile(`exporting`, `canvas`, `canvas_full`, `canvasFull`)" class="btn-export w-48" data-testid="export-canvas-precinct">Canvass : Full report</button>
                                        <button v-if="canvasFull.exporting" class="btn-export btn-export-svg"><SvgLoading class="rotating"/></button>
                                        <button v-if="(canvasFull.download_id && !canvasFull.error && !canvasFull.exporting) || (canvasFull.completed && !canvasFull.error && !canvasFull.exporting)" class="btn-export btn-export-svg" @click.prevent="exportFile(`download`, `canvasFull`, null, `canvasFull`)"><SvgDownload/></button>
                                        <button v-if="canvasFull.error" class="btn-export btn-export-svg"><SvgDownloadError/></button>
                                        <!-- <button v-if="canvasFull.completed || canvasFull.error" class="btn-export btn-export-svg" @click.prevent="exportFile(`reset`, `canvas`, `canvas_full`, `canvasFull`)"><SvgCross/></button> -->
                                    </div>
                                </div>
                            </div>
    
                            <div class="mb-5 border-t border-neutral-500"></div>   
    
    
                            <div class="flex gap-4 mt-4 mb-4" v-if="supportsVeris">
                                    <div class="btn-export-div">
                                        <button @click.prevent="exportFile(`exporting`, `veris`, null, `verisReport`)" class="btn-export w-48" data-testid="export-veris">Export VERIS report</button>
                                        <button v-if="verisReport.exporting" class="btn-export btn-export-svg"><SvgLoading class="rotating"/></button>
                                        <button v-if="(verisReport.download_id && !verisReport.error && !verisReport.exporting) || (verisReport.completed && !verisReport.error && !verisReport.exporting)" class="btn-export btn-export-svg" @click.prevent="exportFile(`download`, `verisReport`, null, `verisReport`)"><SvgDownload/></button>
                                        <button v-if="verisReport.error" class="btn-export btn-export-svg"><SvgDownloadError/></button>
                                        <!-- <button v-if="verisReport.completed || verisReport.error" class="btn-export btn-export-svg" @click.prevent="exportFile(`reset`, `veris`, null, `verisReport`)"><SvgCross/></button> -->
                                    </div>
    
                                    <div class="flex-grow italic text-sm text-right pr-1 flex flex-col justify-center ">
                                        Only completed precincts will be<br>included in the VERIS export.
                                    </div>
                            </div>
                            <div class="flex gap-4 mt-4 mb-4" v-else>
                                    <div class="btn-export-div">
                                        <button @click.prevent="importVeris" class="btn-export w-48" data-testid="export-veris">Import VERIS report</button>
                                    </div>
    
                                    <div class="flex-grow italic text-sm text-right pr-1 flex flex-col justify-center ">
                                        A VERIS report must first be uploaded<br> in the 'Election settings and data' page .
                                    </div>
                            </div>
    
    
    
                            <div class="mb-5 border-t border-neutral-500">
    
                            <div class="flex gap-4 mt-4">
                                    <div class="btn-export-div">
                                        <button @click.prevent="exportFile(`exporting`, `evReport`, null, `evReport`)" class="btn-export w-48" data-testid="export-ev">Export EV report</button>
                                        <button v-if="evReport.exporting" class="btn-export btn-export-svg"><SvgLoading class="rotating"/></button>
                                        <button v-if="(evReport.download_id && !evReport.error && !evReport.exporting) || (evReport.completed && !evReport.error && !evReport.exporting)" class="btn-export btn-export-svg" @click.prevent="exportFile(`download`, `evReport`, null, `evReport`)"><SvgDownload/></button>
                                        <button v-if="evReport.error" class="btn-export btn-export-svg"><SvgDownloadError/></button>
                                        <!-- <button v-if="evReport.completed || evReport.error" class="btn-export btn-export-svg" @click.prevent="exportFile(`reset`, `evReport`, `precincts`, `evReport`)"><SvgCross/></button> -->
                                    </div>
    
                                    <div class="flex-grow italic text-sm text-right pr-1 flex flex-col justify-center ">
                                        Export for enchanced voting ENR
                                    </div>
                                </div>
                            </div>
    
    
                            <div class="mb-5 border-t border-neutral-500 pt-5">
                                <button @click.prevent="publishPublicSite" class="btn-success" :disabled="isPublishing" data-testid="export-website">
                                    {{ isPublishing ? 'Publishing...' : 'Publish public website' }}
                                </button>
                                <br>
                                <button v-if="orderedPublishJobs.recent.length > 0 && !isPublishing" @click.prevent="openPublicSite(orderedPublishJobs.recent)" class="btn-success mt-3">
                                    {{ isPublishing ? 'Publishing...' : 'Open public website' }}
                                </button>
    
                                <a
                                    v-if="orderedPublishJobs.recent.length > 0"
                                    @click="publishJobsShowList=!publishJobsShowList"
                                    class="text-sm cursor-pointer ml-5 no-underline hover:underline block mt-5"
                                >Published reports ›</a>
                                <table v-if="publishJobsShowList" class="mt-5 border-2">
                                    <tr v-for="job in orderedPublishJobs.recent" :key="job.id">
                                        <td>{{formatTime(job.createdAt)}}</td>
                                        <td>
                                            <a
                                                v-if="!!job.completedAt && job.public_site_path"
                                                :href="`/published/${job.exportName}/results/`"
                                                target="_blank"
                                            >Open</a>
                                            <span v-else>Publishing..</span>
                                            </td>
                                    </tr>
    
                                    <template v-if="!publishJobsShowOlder">
                                        <tr>
                                            <td colspan=2>
                                                <a @click="publishJobsShowOlder=true" class="text-sm cursor-pointer">Show more...</a>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr v-for="job in orderedPublishJobs.older" :key="job.id">
                                            <td>{{formatTime(job.createdAt)}}</td>
                                            <td>
                                                <a
                                                    v-if="!!job.completedAt && job.public_site_path"
                                                    :href="`/published/${job.exportName}/results/`"
                                                    target="_blank"
                                                >Open</a>
                                                <span v-else>Publishing..</span>
                                                </td>
                                        </tr>
                                        <tr>
                                            <td colspan=2>
                                                <a @click="publishJobsShowOlder=false" class="text-sm cursor-pointer">Show less</a>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
      </section>
    
    
      <section v-if="stats.total > 0" class="card">
          <div>
            <form class="flex">
                <div>
                    <label class="inline-block">
                        <select class="w-full font-bold" v-model="filters.district">
                            <option value="">All Districts</option>
                            <option v-for="d in districts" :value="d.id" :key="d.id">{{d.key}} - {{d.name}}</option>
                        </select>
                    </label>
                </div>
    
                <div class="flex-grow text-right">
                    <label class="ml-2 border bg-white rounded p-2 inline-block text-sm" :class="{'border-green-600': filters.offline, 'border-neutral-500' :!filters.offline}">
                        Offline <input type="checkbox" style="margin-top:0;margin-bottom:0;" v-model="filters.offline" />
                    </label>
    
                    <label class="ml-2 border bg-white rounded p-2 inline-block text-sm" :class="{'border-green-600': filters.pending, 'border-neutral-500' :!filters.pending}">
                        Pending results <input type="checkbox" style="margin-top:0;margin-bottom:0;" v-model="filters.pending" />
                    </label>
                </div>
            </form>
          </div>
    
          <table class="w-full">
              <thead>
              <tr class="">
                  <th class="bg-gray-200 sticky top-0">
                      <div class="help-icon text-left">
                        <span
                            class="border border-neutral-800 cursor-pointer flex justify-center text-neutral-800"
                            style="border-radius:50%;width:1.3em;line-height:1.3em;"
                        >?</span>
                        <div class="bg-neutral-100 border border-neutral-700 text-left">
                            <table border=0>
                                <tr>
                                    <td><span class="text-green-600 font-bold">✓</span></td>
                                    <td>Precinct Ready</td>
                                </tr>
                                <tr>
                                    <td><span class="text-red-600 font-bold">✉</span></td>
                                    <td>Unread Message</td>
                                </tr>
                                <tr>
                                    <td><span class="text-red-600 font-bold">⚠</span></td>
                                    <td>Results Discrepancy</td>
                                </tr>
                            </table>
                        </div>
                      </div>
                  </th>
                  <th class="bg-gray-200 sticky top-0 text-left"><table-column-order title="Precinct" :currentOrder="orderKey" orderKey="key" @order="orderPrecincts" /></th>
                  <th class="bg-gray-200 sticky top-0 text-left"><table-column-order title="Registered" :currentOrder="orderKey" orderKey="registered" @order="orderPrecincts" /></th>
                  <th class="bg-gray-200 sticky top-0 text-left"><table-column-order title="Turnout" :currentOrder="orderKey" orderKey="turnout" @order="orderPrecincts" /></th>
                  <th class="bg-gray-200 sticky top-0 text-left"><table-column-order title="Results" :currentOrder="orderKey" orderKey="status" @order="orderPrecincts" /></th>
                  <th class="bg-gray-200 sticky top-0 text-left"><table-column-order title="Ready" :currentOrder="orderKey" orderKey="ready" @order="orderPrecincts" /></th>
                  <th class="bg-gray-200 sticky top-0 text-left"></th>
              </tr>
              </thead>
    
              <template v-for="row in orderBy(filteredResults, orderKey, orderDirection)">
              <tr :key="`view-${row.id}`" class="hover:bg-neutral-300">
                  <td class="w-1 whitespace-nowrap">
                      <span title="Ready" class="text-green-600 font-bold mx-1">{{row.ready ? '✓' : ''}}</span>
                      <span
                        v-if="unreadPrecinctMessages(row.id)"
                        title="Message"
                        class="text-red-600 font-bold cursor-pointer mx-1"
                        @click="$state.$emit('message.new', {precinctId: row.id})"
                      >✉</span>
                      <span
                        v-if="precinctsWithBadResults[row.id]"
                        title="Results discrepancy"
                        class="text-red-600 mx-1"
                      >⚠</span>
                  </td>
                  <td>
                      <span
                        :title="activePrecincts[row.id] ? 'Online' : 'Offline'"
                        class="rounded-full h-2 w-2 inline-block mr-2" :class="{
                          'bg-red-600': !activePrecincts[row.id],
                          'bg-green-600': activePrecincts[row.id],
                        }"
                      ></span>
                      <router-link :to="route('manager.precinct_overview', {precinct:row.id})">{{row.key}} - {{row.name}}</router-link>
                  </td>
                  <td @click="editPrecinctRow(row)" class="cursor-pointer">{{displayNumber(row.registered)}} <span class="on-td-hover flip-h">✎</span></td>
                  <td @click="editPrecinctRow(row)" class="cursor-pointer">{{displayNumber(row.turnout)}} <span class="text-gray-700 text-sm">{{Math.floor(ptc(row.registered, row.turnout)) }}%</span> <span class="on-td-hover flip-h">✎</span></td>
                  <td>{{row.status}}</td>
                  <td>{{row.ready ? 'Yes' : 'No'}}</td>
                  <td><a @click="$state.$emit('message.new', {precinctId: row.id})" class="no-underline cursor-pointer">✉</a></td>
              </tr>
    
              <tr v-if="row.id===editingPrecinct" :key="`edit-${row.id}`" class="precinct-row-edit">
                  <td></td>
                  <td></td>
                  <td><form @submit.prevent=""><input v-model="editingPrecinctVals.registered" type="number"></form></td>
                  <td><form @submit.prevent=""><input v-model="editingPrecinctVals.turnout" type="number"></form></td>
                  <td colspan=2>
                      <form @submit.prevent="" class="text-center mb-10">
                          <button @click="stopEditPrecinctRow" class="mr-5">Cancel</button>
                          <button @click="saveEditPrecinctRow" class="btn-success">Save</button>
                      </form>
                  </td>
              </tr>
              </template>
          </table>
      </section>
    </div>
    </template>
    
    <style scoped>
    .on-td-hover {
        visibility: hidden;
    }
    
    td:hover .on-td-hover {
        visibility: visible;
    }
    
    .flip-h {
        display: inline-block;
        transform: scaleX(-1);
    }
    
    .help-icon > div {
        display: none;
        position: absolute;
        z-index: 1000;
    }
    .help-icon:hover > div {
        display: block;
    }

    .btn-success {
        height: 50px; 
        @apply w-48 p-0;
    }

    .btn-success:hover {
        height: 50px; 
        @apply w-48 p-0;
    }
    
    .btn-export-div {
        display: flex;
        flex-direction: row;
    }
    .btn-export {
        height: 50px; 
        vertical-align: middle;
        @apply border border-green-600 text-green-600 cursor-pointer;
    }
    .btn-export:hover {
        @apply bg-green-600 text-white border border-green-600 cursor-pointer;
    }
    .btn-export-svg {
        width: 50px; 
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
    }
    
    @keyframes spin {
        0% { transform: rotate(360deg); }
        100% { transform: rotate(0deg); }
    }
    
    .rotating {
        animation: spin 3s linear infinite;
    }
    </style>
    
    <script>
    
    import _ from 'lodash';
    import TableColumnOrder from './TableColumnOrder';
    import { ptc, sleep, precinctsWithBadResults, displayNumber } from '@/libs/misc';
    import SvgCross from '@/assets/svgs/cross.svg?inline';
    import SvgLoading from '@/assets/svgs/loading.svg?inline';
    import SvgDownload from '@/assets/svgs/download.svg?inline';
    import SvgDownloadError from '@/assets/svgs/downloadError.svg?inline';
    
    
    
    
    
    export default {
        components: {
            TableColumnOrder,
            SvgCross,
            SvgLoading, 
            SvgDownload,
            SvgDownloadError
        },
        data: function() {
          return {
            // mini stats at the top
            // ...
    
            // results overview
            filters: {
                district: '',
                offline: false,
                pending: false,
            },
            orderKey: 'key', // precinct key by default
            orderDirection: 'asc',
            editingPrecinct: 0,
            editingPrecinctVals: {
                turnout: 0,
                registered: 0,
            },
    
            // system
            showExports: false,
            publishPublic: {
                loading: false,
            },
            publishUrl: '',
            publishJobs: [],
            publishJobsShowOlder: false,
            publishJobsShowList: false,
            showDatePopup : true,
    
            canvas: {
                exporting: false,
                completed: false,
                error: false,
                download_id : null,
            }, 
            canvasFull: {
                exporting: false,
                completed: false,
                error: false,
                download_id : null,
            }, 
            evReport: {
                exporting: false,
                completed: false,
                error: false,
                download_id : null,
            }, 
            verisReport: {
                exporting: false,
                completed: false,
                error: false,
                download_id : null,
            }
          }
        },
        computed: {
            // mini stats at the top
            stats: function() {
                let total = 0;
                let statuses = {
                    completed: 0,
                    reporting: 0,
                    pending: 0,
                };
    
                this.$results.precincts.forEach(row => {
                    total++;
                    // Only total up known statuses
                    if (typeof statuses[row.status] !== 'undefined') {
                        statuses[row.status] = statuses[row.status] || 0;
                        statuses[row.status]++;
                    }
    
                    if (row.ready) {
                        statuses.reporting++;
                    }
                });
    
                let ret = { total };
    
                // Add a % for each status
                for (let status in statuses) {
                    ret[status] = statuses[status];
                    ret[status + 'Ptc'] = ptc(total, statuses[status]);
                }
    
                return ret;
            },
    
            isElectionDate(){
                if (!this.$state.site.electionDate){
                    return false;
                }
                
                let currentDate = new Date;
                // Checks whether the election date that was set in the 'Election Settings' page, and the current date are the same. 
                // Also checks whether the time is after 4am, and if the election status is not open then this function will trigger. 
                // This will then allow the popup to display. 
                if (this.$state.site.electionDate.substring(0, 10) == currentDate.toISOString().substring(0, 10) &&
                currentDate.toISOString().substring(11,13) > 4 && this.electionStatus != "open"){
                    return true;
                } else {
                    return false;
                }
    
            },
    
            activePrecincts() {
                let precinctIds = {};
                for (let id of this.$services.activeSessions.precinctIds) {
                    precinctIds[id] = true;
                }
    
                return precinctIds;
            },
    
            voterStats() {
                let registered = 0;
                let registeredIsZero = false;
                let turnout = 0;
                let absentee = 0;
                Object.values(this.$results.precincts).forEach(p => {
                    registered += p.registered;
                    turnout += p.turnout;
                });
    
                // Set registered to a minimum of 1, as the chart module was creating the following error 
                // when it was set to 0
                // Invalid prop: custom validator check failed for prop "total".
                if (registered === 0){
                    registeredIsZero = true;
                }
    
                return {
                    registered,
                    turnout,
                    turnoutPtc: ptc(registered, turnout),
                    absentee,
                    absenteePtc: ptc(registered, absentee),
                    registeredIsZero
                };
                //(10435 + 7565) / 36000 * 100) 
    
            },
    
            // results overview
            districts: function() {
                return this.$results.districts;
            },
            filteredResults: function() {
                let filter = {};
                if (this.filters.district) filter.district_id = this.filters.district;
                if (this.filters.pending) filter.status = 'pending';
    
                let ret = _.filter(this.$results.precincts, filter);
    
                if (this.filters.offline) {
                    ret = ret.filter(precinct => {
                        return !this.$services.activeSessions.precinctIds.includes(precinct.id);
                    });
                }
    
                return ret;
            },
            electionStatus: {
                set: async function(newVal) {
                    await this.$api.post('/mgr/election', {
                        status: newVal,
                    });
                    this.$results.refresh();
                },
                get: function() {
                    return this.$results.election.status || 'completed';
                },
            },
            precinctsWithBadResults() {
                return precinctsWithBadResults(this.$results.results, this.$results.locations, this.$results.ballots);
            },
    
            // system
            supportsVeris() {
                let hasCongDist = (precinct) => {
                    for (let bId of precinct.ballot_ids) {
                        let bData = _.find(precinct.ballot_data, {ballot_id: bId});
                        //return !!bData?.cong_dist;
                        if (bData?.cong_dist) return true;
                    }
                    return false;
                };
    
                let veris = false;
                // VERIS imported data includes cong_dist and 'FIPS'.
                this.$results.precincts.forEach(p => {
                    if (p.extra_data['FIPS'] && hasCongDist(p)) {
                        veris = true;
                    }
                });
                return veris;
            },
            isPublishing() {
                if (this.publishPublic.loading) {
                    return true;
                }
    
                if (this.$results.raw.publishing && this.$results.raw.publishing.job && this.$results.raw.publishing.job.id) {
                    return true;
                }
    
                return false;
            },
            orderedPublishJobs() {
                let rows = _.orderBy(this.publishJobs, ['startedAt'], ['desc']);
    
                return {
                    recent: rows.slice(0, 5),
                    older: rows.slice(5),
                };
            },
        },
        created() {
            this.listen(document, 'click', () => {
                // 
            });
    
            this.updatePublishJobs();
            this.latestDownloads();
        },
        methods: {
          orderBy: _.orderBy,
          ptc,
          displayNumber,
    
          // system
          async exportVeris() {
              let res = await this.$api.get('/mgr/export/veris');
              window.open(res.url);
          },
          async importVeris(){
            window.location.href = "/mgr/import_election?scrollTo=#VERIS";
          },
          async exportEV() {
              let res = await this.$api.get('/mgr/export/evReport');
              window.open(res.url);
          },
          async latestDownloads(){
                let res = await this.$api.get(`/mgr/export/latest`);

                if (res.status === 200){

                    if (!res.data || res.data.length === 0){
                        this.evReport.download_id = null;
                        this.canvas.download_id = null;
                        this.canvasFull.download_id = null;
                        this.verisReport.download_id = null;
                    } else {
                        let latestExports = res.data.reduce((acc, curr) => {
                            acc[curr.export_type] = curr.export_jobs_id;
                            return acc;
                        }, {});

                        this.evReport.download_id = latestExports.evreport || null;
                        this.canvas.download_id = latestExports.canvasprecincts || null;
                        this.canvasFull.download_id = latestExports.canvasfull || null;
                        this.verisReport.download_id = latestExports.verisreport || null;
                    }
                }

            },
          async exportFile(instruction, fileType, subType = null, localVariableName) {
            const resetStatus = () => {
                this[localVariableName].exporting = false;
                this[localVariableName].completed = false;
                this[localVariableName].error = false;
            };
        
            const setExportingStatus = () => {
                this[localVariableName].exporting = true;
                this[localVariableName].completed = false;
                this[localVariableName].error = false;
            };
        
            const downloadFile = async (id) => {
                let cacheBuster = Date.now(); // Current timestamp
                let route = `/mgr/export/downloadfile?id=${id}&cb=${cacheBuster}`;
                let downloadFileRequest = await this.$api.get(route, null, { responseType: 'blob' });
            
                if (downloadFileRequest.status === 200) {
                    let blob = new Blob([downloadFileRequest.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let link = document.createElement('a');
                    link.href = url;
                
                    let contentDisposition = downloadFileRequest.contentDisposition;
                    let filename = contentDisposition.split('filename=')[1];
                    link.download = filename;
                
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                
                    return { success: true, status: downloadFileRequest.status };
                }
            
                return { success: false, status: downloadFileRequest.status };
            };
        
            const checkDownloadStatus = async (id) => {
                let { success, status } = await downloadFile(id);
                if (success) {
                    resetStatus();
                    this[localVariableName].completed = true;
                    this[localVariableName].download_id = id;
                } else if (status === 202) {
                    setTimeout(() => checkDownloadStatus(id), 2000); // every 5 seconds
                } else {
                    resetStatus();
                    this[localVariableName].error = true;
                }
            };
        
            if (instruction === 'exporting') {
                setExportingStatus();
            
                let res = subType === null
                    ? await this.$api.post(`/mgr/export/${fileType}`)
                    : await this.$api.post(`/mgr/export/${fileType}`, { "type": subType });
            
                if (res.error == null && res.exportFilesId) {
                    setExportingStatus();
                    await sleep(2000);
                    checkDownloadStatus(res.exportFilesId);
                } else {
                    resetStatus();
                    this[localVariableName].error = true;
                }
            } else if (instruction === 'download') {
                let { success } = await downloadFile(this[localVariableName].download_id);
                if (!success) {
                    resetStatus();
                    this[localVariableName].error = true;
                }
            } else if (instruction === 'reset') {
                resetStatus();
            }
          },
          openPublicSite(publishJobs) {
            let sitePath = publishJobs[0].public_site_path.toLowerCase();
            let locationName = this.$state.site.locationName.toLowerCase();
    
            window.open(`https://www.eresultslive.com/${sitePath}/${locationName}`, '_blank');
          },
          async publishPublicSite() {
              this.publishPublic.loading = true;
              let resp = await this.$api.get('/mgr/export/website');
              await this.updatePublishJobsUntilAllComplete();
                this.publishUrl = resp.preview_url;
                  this.publishPublic.loading = false;
          },
          async updatePublishJobs() {
              let jobs = await this.$api.get('/mgr/export/website/list');
              this.publishJobs = jobs.jobs;
          },
    
            // Just keep running until the last publish has completed
          async updatePublishJobsUntilAllComplete() {
              while (this.$el.parentNode) {
                  await this.updatePublishJobs();
                  if (this.orderedPublishJobs.recent[0]?.completedAt) {
                      break;
                  }
    
                  await sleep(5000);
              }
          },
    
          canOpenPollWorker(districtId) {
              let district = this.districts.find(d => d.id === districtId);
              if (!district) {
                  return false;
              }
    
              // All precincts (including 900 district) can be opened as a pollworker
              return true;
          },
          openPollWorker(precinctId) {
              this.$state.user.precinctId = precinctId;
              this.$router.push(this.routeurl('record.home'));
          },
    
          unreadPrecinctMessages(precinctId) {
              return !!this.$services.messages.unreadMessages().filter(m => m.precinct_id === precinctId).length;
          },
    
          orderPrecincts(key) {
              if (this.orderKey === key) {
                  this.orderDirection = this.orderDirection === 'asc' ?
                    'desc' :
                    'asc';
              } else {
                  this.orderKey = key;
                  this.orderDirection = 'asc';
              }
          },
    
          editPrecinctRow(row) {
              this.editingPrecinct = row.id;
              this.editingPrecinctVals.registered = row.registered;
              this.editingPrecinctVals.turnout = row.turnout;
    
              this.$nextTick(() => {
                  let el = this.$el.querySelector('.precinct-row-edit input');
                  if (el) {
                    el.select();
                  }
              });
          },
    
          stopEditPrecinctRow() {
              this.editingPrecinct = 0;
          },
    
          async saveEditPrecinctRow() {
                let toSave = {
                    id: this.editingPrecinct,
                    registered: parseInt(this.editingPrecinctVals.registered),
                    turnout: parseInt(this.editingPrecinctVals.turnout),
                };
    
                try {
                    this.saving = true;
                    let resp = await this.$api.post('/mgr/results/enter', {precincts: [toSave]});
                    if (resp.error) {
                        throw new Error(resp.error.message);
                    }
                    await this.$results.refresh();
                } catch (err) {
                    console.error(err);
                    alert('There was an error saving the precincts. Please try again');
                }
    
                this.saving = false;
                this.editingPrecinct = 0;
          },
            formatTime(t) {
                let d = new Date(t);
                let out = d.toLocaleDateString() + ', ' + d.toLocaleTimeString();
                return out;
            }
        },
    };
    
    </script>