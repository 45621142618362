<template>
  <div class="main">
      <messages></messages>

      <main>
        <router-view v-if="$results.loaded" />
      </main>
  </div>
</template>

<script>

import Messages from '@/components/messages/Messages.vue';

export default {
  name: 'Manager',
  components: {
    Messages,
  },
  mounted() {
  },
}
</script>
