import System from './System.vue';
import Header from '@/components/Header';

export default {
    components: { default: System, header: Header},
    children: [
        {
            name: 'system.home',
            path: '/',
            component: () => import(/* webpackChunkName: "system" */ './Monitor'),
        },
        {
            name: 'system.electionmanager',
            path: 'elections',
            component: () => import(/* webpackChunkName: "system" */ './Elections'),
        },
        {
            name: 'system.users',
            path: 'users',
            component: () => import(/* webpackChunkName: "system" */ './Users'),
        },
        {
            name: 'system.audits',
            path: 'audits',
            component: () => import(/* webpackChunkName: "system" */ './Audits'),
        },
    ],
};
