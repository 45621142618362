import Vue from 'vue';

Vue.directive('focus', {
    // Support conditional eg. v-focus="false"
    bind(el, bindings) {
        el.dataset.focus = bindings.value === undefined || !!bindings.value;
    },
    // When the bound element is inserted into the DOM...
    inserted(el) {
        // dataset properties are strings
        if (el.dataset.focus !== 'true') {
            return;
        }
        // Element is supported so focus it
        if (['INPUT', 'TEXTAREA'].includes(el.tagName)) {
            el.focus();
            return;
        }
        // Try to focus the first input element
        let input = el.getElementsByTagName('input')[0];
        if (input) {
            input.focus();
        }
    },
});

// Timer functions that are auto cleaned up when a component is destroyed
Vue.mixin({
  beforeDestroy: function beforeDestroy() {
      (this.timerEvents || []).forEach((tmr) => clearTimeout(tmr));
  },
  methods: {
      setInterval(...args) {
          this.timerEvents = this.timerEvents || [];
          let v = setInterval(...args);
          this.timerEvents.push(v);
          return v;
      },
      setTimeout(...args) {
          this.timerEvents = this.timerEvents || [];
          let v = setTimeout(...args);
          this.timerEvents.push(v);
          return v;
      },
  },
});

// Add a handy this.listen() fn to Vue instances. Saves on the need to add an event listener
// and then manually remove them all the time.
Vue.mixin({
  beforeDestroy: function beforeDestroy() {
      (this.listeningEvents || []).forEach((fn) => fn());
  },
  methods: {
      listen: function listen(source, event, fn) {
          this.listeningEvents = this.listeningEvents || [];
          let off = () => {
              (source.removeEventListener || source.$off || source.off).call(source, event, fn);
          };
          this.listeningEvents.push(off);
          (source.addEventListener || source.$on || source.on).call(source, event, fn);
          return off;
      },
      listenOnce: function listenOnce(source, event, _fn) {
          let fn = _fn;
          this.listeningEvents = this.listeningEvents || [];
          let off = () => {
              (source.removeEventListener || source.$off || source.off).call(source, event, fn);
          };
          this.listeningEvents.push(off);

          if (source.addEventListener) {
              // Create our own once handler as the DOM doesn't support this itself
              fn = function onceFn(...args) {
                  source.removeEventListener(event, onceFn);
                  fn(...args);
              };

              source.addEventListener(event, fn);
          } else {
              (source.$once || source.once).call(source, event, fn);
          }

          return off;
      },
  },
});
