<template>
<div class="main">

<div class="card-title">
    <h3>Registered voters</h3>
    <div>Edit the number of registered voters for each precinct</div>
</div>


<section class="card">
    <form>
        <label class="w-1">
            <select class="" v-model="filters.district">
                <option value="">All Districts</option>
                <option v-for="d in districts" :value="d.id" :key="d.id">{{d.name}}</option>
            </select>

        </label>
    </form>

    <div v-if="$results.loading">
        Loading data...
    </div>
    <form v-else @submit.prevent="saveRegisteredVoters">
        <table class="w-full">
            <tr class="bg-gray-200">
                <th class="text-left">Precinct</th>
                <th class="text-left">Registered Voters</th>
                <th class="text-left">Current Turnout</th>
            </tr>
            <template v-for="district in filteredDistricts">
            <tr v-for="precinct in district.precincts" :key="precinct.id">
                <td>{{precinct.key}} - {{district.name}}, {{precinct.name}}</td>
                <td><input v-model="precinct.registered" type="number" :disabled="saving" @input="dirty=true" /></td>
                <td>{{precinct.turnout}} <span class="text-gray-700">({{Math.floor(ptc(precinct.registered, precinct.turnout)) }}%)</span></td>
            </tr>
            </template>
            <tr>
                <td></td>
                <td>
                    <span v-if="saving">Saving...</span>
                    <span v-else-if="!saving && !dirty && hasSaved">Saved!</span>
                    <button v-else type="submit" class="btn-success">Save</button>
                </td>
                <td></td>
            </tr>
        </table>
    </form>
</section>

</div>
</template>

<script>

import _ from 'lodash';
import { ptc } from '@/libs/misc';

export default {
    data: function() {
        return {
            filters: {
                district: '',
            },
            saving: false,
            hasSaved: false,
            dirty: false,
            locations: {},
        };
    },
    computed: {
        districts: function() {
            return this.$results.locations;
        },
        filteredDistricts: function() {
            let ret = {};
            if (this.filters.district) {
                let district = this.locations[this.filters.district];
                if (district) {
                    ret[district.id] = district;
                }
            } else {
                ret = this.locations;
            }

            return ret;
        },
    },
    created() {
        this.updateLocations();
    },
    watch: {
        '$results.locations'() {
            // Only update the displayed location info if we haven't changed anything, otherwise
            // we could be overwriting unsaved data
            if (!this.dirty) {
                this.updateLocations();
            }
        },
    },
    methods: {
        ptc,
        updateLocations() {
            // deep clone so that we can update our numbers without it getting overriden by the
            // continiously updating results data
            this.locations = _.cloneDeep(this.$results.locations);
        },
        async saveRegisteredVoters() {
            let precincts = [];
            Object.values(this.filteredDistricts).forEach(district => {
                precincts = precincts.concat(Object.values(district.precincts));
            });

            let toSave = precincts.map(precinct => {
                return {
                    id: precinct.id,
                    registered: precinct.registered,
                };
            });

            try {
                this.saving = true;
                let resp = await this.$api.post('/mgr/results/enter', {precincts: toSave});
                if (resp.error) {
                    throw new Error(resp.error.message);
                }
                await this.$results.refresh();
                this.updateLocations();
            } catch (err) {
                console.error(err);
                alert('There was an error saving the precincts. Please try again');
            }

            this.saving = false;
            this.hasSaved = true;
            this.dirty = false;

        },
    },
};

</script>