<template>
    <div class="inline-block" style="width:2.5em; line-height:initial;">
        <input type="checkbox" :checked="value" @change="onChanged" :id="id" />
        <label :for="id">Toggle</label>
    </div>
</template>

<style scoped>
input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
    position: absolute;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 100%;
	height: 1em;
	background: grey;
	display: block;
	border-radius: 1000px;
	position: relative;
    margin: 0;
}

label:after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 1em;
	height: 1em;
	background: #fff;
    background-clip: padding-box;
    border: 0.1em solid transparent;
	border-radius: 9000px;
	transition: 0.2s;
}

input:checked + label {
	background: #bada55;
}

input:checked + label:after {
	left: calc(100% - 0.1em);
	transform: translateX(-100%);
}

label:active:after {
	width: 1.5em;
}

</style>

<script>
export default {
    props: ['value'],
    data: function() {
        return {
            id: (Math.random()*10000000).toString(36).replace('.', ''),
        };
    },
    methods: {
        onChanged () {
            this.$emit('input', !this.value)
        }
    }
}
</script>