<template>
<div class="main">

        <div class="card-title">
            <h3>Election results overview</h3>
            <div>Preview aggregated results for the election</div>
        </div>


<section class="card">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-10 bg-neutral-200 p-5 mb-10">
        <div>
            <form>
                <div class="font-bold">Choose District</div>
                <label>
                    <select class="w-full font-bold" v-model="filters.district">
                        <option value="">All Districts</option>
                        <option v-for="d in districts" :value="d.id" :key="d.id">{{d.name}}</option>
                    </select>

                </label>
            </form>
        </div>

        <div>
            <form>
                <div class="font-bold">Choose Precinct</div>
                <label>
                    <select class="w-full font-bold" v-model="filters.precinct">
                        <option value="">All Precincts</option>
                        <option v-for="p in precincts" :value="p.id" :key="p.id">{{p.name}}</option>
                    </select>
                </label>
            </form>
        </div>

        <div>
            <form>
                <div class="font-bold">Choose Contest</div>
                <label>
                    <select class="w-full font-bold" v-model="filters.contest">
                        <option value="">All Contests</option>
                        <option v-for="n in uniqueContestNames" :value="n" :key="n">{{n}}</option>
                    </select>
                </label>
            </form>
        </div>
    </div>

    <div v-if="$results.loading">
        Loading data...
    </div>
    <table v-else class="w-full">
        <template v-for="contest in aggResults">
        <tr :key="`contest-1-${contest.id}`">
            <th colspan=3>{{contest.name}}</th>
        </tr>
        <tr :key="`contest-2-${contest.id}`" class="bg-gray-200">
            <th class="text-left">Party</th>
            <th class="text-left">Candidate</th>
            <th class="text-left">Vote %</th>
            <th class="text-left">Total</th>
        </tr>
        <tr :key="`contest-3-${contest.id}-${row.candidate_name}`" v-for="row in contest.rows">
            <td>{{row.party}}</td>
            <td>{{row.candidate_name}}</td>
            <td>{{truncForDisplay(row.votesPtc)}}%</td>
            <td>{{row.votes}}</td>
        </tr>
        <tr :key="`contest-4-${contest.id}`">
            <td colspan=4 class="h-20"></td>
        </tr>
        </template>
    </table>
</section>

</div>
</template>
<script>

import _ from 'lodash';
import {
    aggregateResultsPerContest,
    ptc,
    propSum,
    truncForDisplay
} from '@/libs/misc';

export default {
    data: function() {
        return {
            filters: {
                district: '',
                precinct: '',
                contest: '',
            },
        };
    },
    computed: {
        aggResults: function() {
            let filter = { };
            if (this.filters.district) filter.district_id = this.filters.district;
            if (this.filters.precinct) filter.precinct_id = this.filters.precinct;
            if (this.filters.contest) filter.contest_name = this.filters.contest;

            if (!this.$results.ballots || Object.values(this.$results.ballots).length === 0) {
                return {};
            }

            // Collect ballots only associated with the filtered locations
            let ballots = {};
            this.precincts.forEach(p => {
                if (this.filters.precinct && p.id !== this.filters.precinct) {
                    return;
                }

                p.ballot_ids.forEach(ballotId => {
                    if (this.$results.ballots[ballotId]) {
                        ballots[ballotId] = this.$results.ballots[ballotId];
                    }
                });
            });

            let contests = aggregateResultsPerContest(
                filter,
                this.$results.results,
                Object.values(ballots),
                this.$results,
            );

            return _.orderBy(contests, 'position');
        },
        districts: function() {
            return this.$results.districts;
        },
        precincts: function() {
            let filter = {};
            if (this.filters.district) filter.district_id = this.filters.district;
            return _.chain(this.$results.precincts).filter(filter).value();
        },
        contests: function() {
            let c = [];
            for (let prop in this.$results.ballots) {
                let ballot = this.$results.ballots[prop];
                for (let contestId in ballot.contests) {
                    c.push(ballot.contests[contestId]);
                }
            }
            return c;
        },
        uniqueContestNames() {
            let names = this.contests.map(c => c.name);
            return _.uniq(names);
        },
    },
    mounted() {
        this.$results.refresh();
    },
    methods: {
        ptc,
        propSum,
        truncForDisplay,
    },
    watch: {
        'filters.district'() {
            this.filters.precinct = '';
        },
    }
};

</script>