<template>
<div class="main">

<section class="section-login">
    <div class="md:w-6/12 sm:w-10/12 mx-auto">
        <h3 class="text-center">Access has been requested</h3>

        <div class="m-5">
            <p class="">Either this location or device has not been recognised</p>
            <p>A request has been sent to the admin to approve of this device. Please try again in a few minutes</p>
            <p><a @click="reloadPage" class="cursor-pointer">Refresh TallyPoint</a></p>
        </div>
    </div>
</section>

</div>
</template>

<style scoped>

</style>

<script>

export default {
    data: function() {
        return {
        };
    },
    created() {
        this.setInterval(() => {
            this.$state.refreshStatus();
        }, 10000);
    },
    methods: {
        reloadPage() {
            window.location.reload();
        },
    },
};

</script>