<template>
<div class="main">

<section>
    <div class="w-6/12 mx-auto">
        <h3 class="text-center">Page not found</h3>
    </div>
</section>

</div>
</template>

<script>

export default {
    data: function() {
        return {
        };
    },
    computed: {
    },
    methods: {
    },
};

</script>