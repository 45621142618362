<template>
    <div class="main">
        <section v-if="stats.total === 0">
                <div class="text-danger-800 bg-danger-300 border border-danger-800 p-5 m-5 w-6/12 mx-auto text-center">
                    <p><b>No contests have yet been added</b></p>
                    <p>Review your election settings and import your election data to upload contest ordering data</p>
                    <p class="mt-5"><router-link :to="route('manager.import_election', null, '#election-import')">Election Settings</router-link></p>
                </div>
        </section>

        <section v-if="stats.total > 0" class="card">
          <div class="text-center">
                  <h3>{{ stats.total }} Contests</h3>
                  <div class="flex justify-center">
                      <div class="mx-2 bg-green-600 p-6 rounded shadow-md w-32 h-28">
                          <div class="text-xl">{{ stats.publicallyShown }}</div>
                          <div>Displayed</div>
                      </div>

                      <div class="mx-2 bg-orange-300 p-6 rounded shadow-md w-32 h-28">
                          <div class="text-xl">{{ stats.publicallyHidden }}</div>
                          <div>Hidden</div>
                      </div>
                  </div>
          </div>
        </section>

        <section v-if="stats.total > 0" class="card">
            <div>
              <form>
                  <div class="flex justify-between pb-2">
                      <div>                
                          <select id="filterOptions" v-model="filters" @change="setFilters($event.target.value)">
                              <option label="Public Contests" name="filters" value="public" selected v-on:click="setFilters(`public`)">Public Contests</option>
                              <option label="Hidden Contests" name="filters" value="hidden" v-on:click="setFilters(`hidden`)">Hidden Conests</option>
                              <option label="All Contests" name="filters" value="all" v-on:click="setFilters(`all`)">All Contests</option>
                          </select>
                      </div>
                  </div>
              </form>
            </div>
        
            <table class="w-full">
                <thead>
                    <tr>
                        <!-- Order columns currently not in use. original Order refers to the order from the spreadsheet when uploaded. Computed order is the order created after bad entries have been deduped -->
                        <!-- <th class="bg-gray-200 sticky top-0 text-left">Current Order</th> -->
                        <!-- <th class="bg-gray-200 sticky top-0 text-left">Orignal Order</th> -->
                        <th class="bg-gray-200 sticky top-0 text-left">Contest</th>
                        <th class="bg-gray-200 sticky top-0 text-left">Candidate</th>
                        <th class="bg-gray-200 sticky top-0 text-left">Publish?</th>

                        <th class="bg-gray-200 sticky top-0 text-right w-5 p-0">
                            <button type="button" class="custom-height color-fade sticky select-none btn btn-default btn-sm rounded p-3 bg-neutral-200 border-neutral-500 " disabled v-if="this.saving" title="Saving">
                              <SvgSaving class="rotation-animation sticky" style="width:24px; height:24px;"/>
                            </button>

                            <button type="button" class="custom-height color-fade sticky select-none btn btn-default btn-sm rounded p-3 bg-neutral-200 border-neutral-500 " disabled v-else-if="this.savingError" title="Error saving">
                              <SvgError class="sticky"/>
                            </button>
                      
                            <button type="button" class="custom-height sticky btn btn-default btn-sm rounded p-3 bg-neutral-200 border-neutral-500 select-none" disabled v-else-if="this.savingError == false && this.saving == false" title="Saved">
                              <SvgSaved class="sticky"/>
                            </button>
                        </th>
                    </tr>
                </thead>
            
                <draggable :list="contestsList" @change="rowChanged" @start="dragging = true" @end="dragging = false" is="draggable" animation="200" tag="tbody">
                    <transition-group tag="tr" :name="!drag ? 'flip-list' : null" v-for="(row, i) in contestsList" :key="i" v-on:click="setCurrentContestSelected(row.contestOrderComputed)" :class="hackyFiltering(row)">
                            <!-- TD's not currently being used -->
                            <!-- <td :value="row.contestOrderComputed" scope="row" :key="(i + `contestOrderComputed`)">{{ row.contestOrderComputed }}</td> -->
                            <!-- <td :value="row.contestOrderFromSpreadsheet" scope="row" :key="(i + `contestOrderFromSpreadsheet`)">{{ row.contestOrderFromSpreadsheet }}</td> -->
                            <td :value="row.contestName" :key="(i + `contestName`)">{{ row.contestName }}</td>
                            <td :value="row.contestCandidate" :key="(i + `contestCandidate`)">{{ row.contestCandidate }}</td>
                            <td :value="row.contestShown" :key="(i + `contestShownIf`)" class="flex" v-if="row.contestShown == true">Yes<SvgSwitch class="sticky ml-3" @click="toggleContestShown(row)"/></td>
                            <td :value="row.contestShown" :key="(i + `contestShownElse`)" class="flex" v-else>No<SvgSwitch class="sticky sticky ml-3" @click="toggleContestShown(row)"/></td>
                            <td :key="(i + `isContestSaving`)"></td>
                    </transition-group>
                </draggable>
            </table>
        </section>
</div>
</template>


<script>


import _ from 'lodash';
import { ptc , displayNumber } from '@/libs/misc';
import draggable from "vuedraggable";
import SvgSaved from '../../assets/svgs/saved.svg?inline';
import SvgSaving from '../../assets/svgs/saving.svg?inline';
import SvgError from '../../assets/svgs/error.svg?inline';
import SvgSwitch from '../../assets/svgs/switch.svg?inline';


export default {
    components: {
        draggable,
        SvgSaved,
        SvgSaving,
        SvgError,
        SvgSwitch
    },
    data: function() {
      return {
        filters:"public",
        contestsList:[],
        currentContestSelected:null,
        timesReordered: 0,
        enabled: true,
        dragging: false,
        saving: false,
        savingError : false, 
        drag: false,
        ignoreContestInStyling: []
      }
    },

    created () {
        // This will initially populate the table by setting the filter to set the dropdown option to it's default (public). 
        // The setFilters function will run and pull the table data from "this.$results.election.agg_results_ordering"
        this.setFilters("public");
    },

    props : ["contestList"],

    computed: {
        // This sets stats for the top panel with the num of contests being displayed / hidden
        stats: function() {
            let total = 0;
            let publicallyShown = 0;
            let publicallyHidden = 0;

            if(this.$results.election.agg_results_ordering){
                this.$results.election.agg_results_ordering.forEach(row => {
                    total++;
                    if(row.contestShown == true){
                        publicallyShown++;
                    } else if (row.contestShown == false){
                        publicallyHidden++;
                    }
                });
            }

            let ret = { total , publicallyShown , publicallyHidden};
            return ret;
        },

        // Sets some of the styling options for the vue draggable plugin used to create dragging effects on the table rows 
        dragOptions() {
          return {
            animation: 0,
            group: "description",
            disabled: false,
            ghostClass: "ghost"
          };
        }

    },

    methods: {
        orderBy: _.orderBy,
        ptc,
        displayNumber,

        setFilters(clickedFilter){
            // Sets the dropdown option to the option that the user clicked
            this.filters = clickedFilter;

            // clickedFilter set to "all". This is because as a workaround to a bug found, rather than filtering the contestsList in this setFilters 
            // function, I needed to filter it directly in the html but could not filter it due to the v-for / v-if restrictions in vue.
            clickedFilter = "all";

            // This resets the ignoreContestInStyling array when a dropdown option is clicked 
            this.ignoreContestInStyling = [];

            if (clickedFilter === "all"){
                this.contestsList = this.$results.election.agg_results_ordering;
                return
            }
            

            // const filteredContestList = this.$results.election.agg_results_ordering.filter(contest =>{
            //     if (contest.contestShown == true && clickedFilter == "public"){
            //         return contest
            //     } else if (contest.contestShown == false && clickedFilter == "hidden"){
            //         return contest
            //     }
            // })
            // this.contestsList = filteredContestList;
        },

        hackyFiltering(row){
            // Loops through the contests and depending on the filter in use, will return the css property "hidden" so that contest doesn't display 
            if (row.contestShown == false && this.filters == "hidden"){
                return "show";
            } else if (row.contestShown == true && this.filters == "public") {
                return "show";
            } else if (this.filters == "all"){
                return "show";
            } else if (this.ignoreContestInStyling.includes(row.contestOrderComputed)){
                return "show";
            } else {
                return "hidden";
            }
        },

        toggleContestShown(currentRow){
            // Toggle button for the publish column in the contests Yes or No value. If pressed then contest added to ignoreContestInStyling array so that contest will stay in its original filter option
            // list until the user refreshes the page or changes the filter option
            this.contestsList[currentRow.contestOrderComputed -1].contestShown = !currentRow.contestShown;
            this.ignoreContestInStyling.push(currentRow.contestOrderComputed);
            this.updateDatabase(this.contestsList);
        },

      
        setCurrentContestSelected(contestOrder){
            if (this.currentContestSelected === contestOrder){
                this.currentContestSelected = null;
            } else {
                this.currentContestSelected = contestOrder;
            }
        },

        setRowKey(contestOrder, rowName = null){
            // Not currently in use. 
            if (rowName == null){
                this.timesReordered++;
                return `contestOrder : ${contestOrder}, timesReordered : ${this.timesReordered}`;
            } else {
                this.timesReordered = 1;
                return `contestOrder : ${contestOrder}, rowName : ${rowName} , timesReordered : ${this.timesReordered}`;
            }

        },

        rowChanged(){
            // Triggered by vue.draggable component when a row is changed. Will update the contestsList array and the database when a row is dragged and dropped.

            const newList = [...this.contestsList].map((item, index) => {
                const newSort = index + 1;
                // also add in a new property called has changed if you want to style them / send an api call
                item.hasChanged = item.contestOrderComputed !== newSort;
                if (item.hasChanged) {
                  item.contestOrderComputed = newSort;
                }
                return item;
              });
            this.contestsList = newList;
            this.updateDatabase(newList);
        },

        delay(time) {
                return new Promise(resolve => setTimeout(resolve, time));
        },

        async updateDatabase(updatedContestsList){

            // Turns off savingError boolean which will remove savingError svg icon if present
            this.savingError = false;

            try {
                // Turns on saving boolean which will add rotating saving svg icon 
                this.saving = true;
                let resp = await this.$api.post('mgr/updateaggresults', {"updatedContestsList":updatedContestsList});
                console.log(resp);
                // Turns off saving boolean after delay
                this.delay(1400).then(()=>this.saving = false);
            } catch (err){
                this.saving = false;
                this.savingError = true;
                console.error(err);
                alert('There was an error saving the new contest order. Please try again');
            }
        },
    },
};

</script>



<style scoped>

tr:hover{
    animation-duration: 500ms;
    background-color: theme('colors.neutral.100');
}
.on-td-hover {
    visibility: hidden;
}

td:hover .on-td-hover {
    visibility: visible;
}

.flip-h {
    display: inline-block;
    transform: scaleX(-1);
}

.help-icon > div {
    display: none;
    position: absolute;
    z-index: 1000;
}
.help-icon:hover > div {
    display: block;
}


.rotation-animation {
    -webkit-animation: rotation 1.5s 60 linear;
    -moz-animation: rotation 1.5s 60 linear;
    -o-animation: rotation 1.5s 60 linear;
    animation: rotation 1.5s 60 linear;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
}


@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to   {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to   {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to   {-o-transform: rotate(359deg);}
}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to   {transform: rotate(359deg);}
}

.color-fade{
    background-color: theme('colors.neutral.200');
    animation: FadeIn 2s ease-in forwards;
}

@keyframes FadeIn {
  0% {
    background-color: theme('colors.neutral.200');
  }
  70%{
    background-color: theme('colors.neutral.600');
  }
  100% {
    background-color: theme('colors.neutral.200');
  }
}

.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.custom-height {
    height: 90%;
}
</style>

