<template>
<div>
    <div v-if="showerrors" class="w-full h-full left-0 absolute top-0">
        <div ref="stickyStatus" class="sticky top-0 z-10 text-center">
            <div :class="resultsStatus.classes" class="flex">
                <div class="flex-grow">
                    <template v-if="resultsStatus.code==='not_accepting'" data-testid="precinct-submit_none">
                        This election has now closed. No more results can be entered
                    </template>
                    <template v-if="resultsStatus.code==='precinct_not_ready'">
                        This precinct is not yet ready to enter results
                    </template>
                    <template v-if="resultsStatus.code==='can_be_submitted'">
                        These results can now be submitted.
                        <a class="cursor-pointer inline-block underline" @click="submitResults" data-testid="precinct-submit_all">Submit results for review</a>
                    </template>
                    <template v-if="resultsStatus.code==='ballot_votes_mismatch'">
                        The combined ballot votes do not match the precinct turnout -
                        <a @click="submitResults" data-testid="precinct-submit_anyway" class="cursor-pointer"> Submit anyway</a>
                    </template>
                    <template v-if="resultsStatus.code==='contest_votes_mismatch'">
                        A contests recorded votes does not match its ballots turnout
                    </template>
                    <template v-if="resultsStatus.code==='submitted'">
                        <p data-testid="precinct-submit_none">These results have been submitted for review. No more changes can be made</p>
                    </template>
                    <template v-if="resultsStatus.code==='pending'">
                        Enter the results for each contest to submit them for review
                    </template>
                </div>

                <svg-todo width="auto" height="auto" class="w-7 cursor-pointer opacity-60" />
            </div>
        </div>
    </div>

<div class="main relative" :class="{'pt-28': showerrors}">

<section class="card card--inline">

    <div class="font-bold mt-10">Turnout</div>


    <a name="turnouts"></a>

    <form @submit.prevent="onTurnoutFormSubmit">
        <label>
            <span class="mr-2">Voters Checked In</span>
            <input v-if="canEnterResults || $state.user.admin" type="number" v-model="newTurnout.value"/>
            <input v-else type="number" v-model="newTurnout.value" disabled/>
        </label>
        <label>
            <span class="mr-2">Curbside</span>
            <input v-if="canEnterResults || $state.user.admin" type="number" v-model="newCurbside.value"/>
            <input v-else type="number" v-model="newCurbside.value" disabled/>
        </label>

        <tabulation-machine-switcher @selected="currentMachineNum=$event" :precinct="precinct" />
    
        <div class="font-bold mt-10">Number of Votes Cast on Machine(s)</div>
        <div v-if="!ballotVotesMatchPrecinctTurnout" class="text-danger-500 text-sm mt-3">
            The total ballots cast should match the precinct turnout
        </div>
        <label v-for="ballot in newBallotVotes" :key="`ballot-turnout-${ballot.id}`">
            <span class="mr-2">{{ballot.name}}</span>
            <input
                v-if="canEnterResults || $state.user.admin"
                type="number"
                v-model="ballot.val.value"
                :data-testid="'precinct-ballot-'+ ballot.name"
            />
            <input
                v-else
                type="number"
                v-model="ballot.val.value"
                disabled
            />
        </label>
        <div class="flex flex-col items-end">
            <button v-if="canEnterResults" type="submit" class="btn-success float-right" data-testid="precinct-ballot-save">Save</button>
            <button v-else class="float-right" :class="toggleSavedButtonInfo ? 'btn-warning' : 'btn-blank'" @click="toggleSavedButtonInfo = true">Saved</button>
            <p v-if="toggleSavedButtonInfo" class="mr-2 mt-2 text-danger-500">These results have been submitted for review. No more changes can be made</p>
        </div>
    </form>
</section>

<section class="card">
    <table class="w-full">
        <template v-for="(contest, contestIdx) in contests">
            <tr :key="'tr-1-'+contest.key">
                <th colspan=4 class="text-left p-0">
                    <a :name="'contest_' + contest.key"></a>
                    <span class="">{{contest.name}}</span>
                    <span class="font-normal text-sm text-neutral-800 inline-block ml-10">
                        {{ Object.values(contest.onBallots).map(b => b.name).join(', ') }}
                    </span>
                    <span v-if="precinct.num_machines > 1 && currentMachineNum > 0" class="font-normal text-sm text-neutral-800 float-right">
                        Tabulation machine {{ currentMachineNum }}
                    </span>
                    <span v-if="precinct.num_machines > 1 && currentMachineNum === 0" class="font-normal text-sm text-neutral-800 float-right">
                        All tabulation machines
                    </span>
                    <div class="border-t border-gray-400"></div>
                </th>
            </tr>
            <tr :key="'tr-2-'+contest.key">
                <td colspan=4 class="text-sm text-right">
                    <div v-if="badContests[contest.key]" class="text-danger-500">
                        <span v-if="hasMultipleMachines">
                            This contest votes across all tabulation machines should match its ballots turnout ({{badContests[contest.key].ballotVotes}}).
                        </span>
                        <span v-else>
                            This contest votes ({{contest.turnout}}) should match its ballots turnout ({{badContests[contest.key].ballotVotes}}).
                        </span>
                        <a @click="$set(overrideContestSubmit, contest.key, true)" class="cursor-pointer" :data-testid="`contest-${contestIdx}-submit_anyway`">Submit anyway</a>
                    </div>
                    <div v-else class="text-neutral-800">
                        {{ contest.turnout }} votes, {{ truncForDisplay(ptc(workoutSplit(contest, precinct), contest.turnout)) }}% of the precinct turnout
                    </div>
                </td>
            </tr>
            <tr  :key="'tr-3-'+contest.key" class="bg-gray-200">
                <template v-if="contest.type==='election'">
                    <th class="text-left">Party</th>
                    <th class="text-left">Candidate</th>
                </template>
                <template v-else>
                    <th class="text-left"></th>
                    <th class="text-left">Answer</th>
                </template> 
                <th class="text-left">Votes</th>
                <th class="text-left">
                    <div v-if="canEnterResults">
                        <a v-if="editingContest!==contest.key" class="cursor-pointer"  @click="editContest(contest.key)" :data-testid="`contest-edit-${contestIdx}`">[edit]</a>
                        <a v-else class="cursor-pointer" @click="editingContest=''">[cancel]</a>
                    </div>
                </th>
            </tr>
            <tr v-for="(candidate, candidateIndex) in contest.candidates" class="row" :key="'tr-4-'+contest.key+'-candidate'+candidate.key">
                <td>{{candidate.party}}</td>
                <td>{{candidate.name}}</td>
                <td class="whitespace-nowrap">
                    <template v-if="editingContest!==contest.key">
                        {{candidate.votes}} <span class="text-neutral-700 text-sm">{{truncForDisplay(ptc(contest.turnout, candidate.votes))}}%</span>
                    </template>
                    <template v-else>
                        <form>
                            <input type="number" v-model="editingContestValues[candidate.key].votes" :form="'contest'+contest.key" :data-testid="`contest-edit-${contestIdx}-input-${candidateIndex}`"/>
                        </form>
                    </template>
                </td>
                <td></td>
            </tr>
            <tr v-if="editingContest===contest.key" :key="'tr-5-'+contest.key">
                <td></td>
                <td></td>
                <td class="text-center py-5">
                    <form @submit.prevent="onContestFormSubmit" :id="'contest'+contest.key">
                        <button type="submit" class="btn-success" :data-testid="`contest-save-${contestIdx}`">Save</button>
                    </form>
                </td>
                <td></td>
            </tr>

            <tr v-if="contestIdx < contests.length - 1" :key="'tr-6-'+contest.key">
                <td colspan=4>
                    <div class="mt-20"></div>
                </td>
            </tr>
        </template>
    </table>
</section>

</div>
</div>
</template>

<script>

import _ from 'lodash';
import {
    ptc,
    truncForDisplay,
    propSum,
    updateableValue,
    makeKey,
    precinctsWithBadResults,
} from '@/libs/misc';
import SvgTodo from '@/assets/svgs/todo.svg?inline';
import TabulationMachineSwitcher from './TabulationMachineSwitcher';


/*

Aggregate ballots:
    Precinct turnout = 1000

    Ballot 1 votes = 300
    Ballot 2 votes = 700

    Contest 1
        Candidate 1 votes = 50
        Candidate 2 votes = 

    Sum of all ballot turnouts should = precinct turnout  (?? check with Ricky)
        - Because each person only gets 1 ballot
    A single contests votes should = the sum of all ballots turnouts that the contest is on
        - Because the contest results are inserted as an aggregate, but we have the individual ballot turnout counts


*/

export default {
    components: {
        SvgTodo,
        TabulationMachineSwitcher,
    },
    props: [
        'precinct',
        'ballots',
        'results',
        'showerrors',
        'isadmin',
    ],
    watch: {
        precinct() {
            this.createUpdateValues();
        },
    },
    data: function() {
        return {
            currentMachineNum: 0,
            editingContest: '',
            editingContestValues: {
                /*
                candidateName: 0,
                */
            },
            newTurnout: null, //updateableValue(0),
            newCurbside: null, //updateableValue(0),
            newBallotVotes: [],
            overrideContestSubmit: {
                /* contestKey: true */
            },
            toggleSavedButtonInfo: false,
        };
    },
    created() {
        // Auto select the only machine if there is only one
        if (this.precinct.num_machines === 1) {
            this.currentMachineNum = 1;
        }

        this.createUpdateValues();
    },
    computed: {
        currentResults() {
            if (!this.currentMachineNum) {
                return this.results;
            }

            return this.results.filter(r => {
                return r.machine_num === this.currentMachineNum;
            });
        },
        hasMultipleMachines() {
            return this.precinct.num_machines > 1;
        },
        badResults() {
            // Send all this precincts results, not this.currentResults, because all the error checking
            // must be done across all tab. machines, not individual machines
            let r = precinctsWithBadResults(this.results, this.$results.locations, this.$results.ballots);
            for (let prop in r) {
                delete r[prop].precinct;
            }

            this.$emit('haserrors', !!r[this.precinct.id]);
            return r[this.precinct.id];
        },
        ballotVotesMatchPrecinctTurnout() {
            return !this.badResults?.errors.includes('ballot_votes_unmatch_precinct_turnout');
        },
        allContestsCompleted() {
            if (Object.keys(this.badContests).length > 0) {
                return false;
            }

            // We must actually have some results for this to be complete
            if (this.currentResults.length === 0) {
                return false;
            }

            return true;
        },
        hasAnyResultsAtAll() {
            return _.sumBy(this.contests, 'turnout') === 0 ?
                false :
                true;
        },
        badContests() {
            let bad = this.badResults?.contests;
            if (!bad) {
                return {};
            }

            let contests = this.contests;
            let badContests = {};

            let sumBallotVotes = (ballots) => {
                let total = 0;
                for (let bData of this.precinct.ballot_data) {
                    if (ballots[bData.ballot_id]) {
                        total += bData.votes;
                    }
                }

                return total;
            };

            // Contests appearing on both ballots = total number of ALL ballots cast

            for (let contest of contests) {
                if (!bad[contest.key]) continue;
                if (this.overrideContestSubmit[contest.key]) continue;
                let totalBallotVotes = sumBallotVotes(contest.onBallots);
                badContests[contest.key] = {
                    contestVotes: contest.turnout,
                    ballotVotes: totalBallotVotes,
                };
            }

            return badContests;
        },
        keyedResults() {
            let results = {
                /*
                <contestKey>: {
                    <candidateKey>: {
                        votes: 0,
                    }
                }
                */
            };
            this.currentResults.forEach(res => {
                let contestKey = makeKey(res.contest_name);
                let candidateKey = makeKey(res.candidate_name);
                results[contestKey] = results[contestKey] || {};
                results[contestKey][candidateKey] = results[contestKey][candidateKey] || {votes:0};
                results[contestKey][candidateKey].votes += res.votes;
            });

            return results;
        },
        contests() {
            let results = this.keyedResults;

            let contests = {};
            this.ballots.forEach(ballot => {
                Object.values(ballot.contests).forEach(contest => {
                    let cKey = makeKey(contest.name);
                    let cont = contests[cKey] = contests[cKey] || {
                        key: cKey,
                        saveToBallotId: ballot.id,
                        saveToContestId: contest.id,
                        onBallots: {},
                        name: contest.name,
                        type: contest.type,
                        candidates: {},
                        turnout: 0,
                        turnoutPtc: 0,
                    };

                    // Add a refrence to each ballot this contest was found on
                    cont.onBallots[ballot.id] = ballot;

                    contest.candidates.forEach(candidate => {
                        let candKey = makeKey(candidate.name);
                        cont.candidates[candKey] = cont.candidates[candKey] || {
                            key: candKey,
                            saveToCandidateId: candidate.id,
                            name: candidate.name,
                            party: candidate.party,
                            position: candidate.position,
                            votes: results[cKey]?.[candKey]?.votes || 0,
                        };
                    });
                });
            });

            contests = _.sortBy(Object.values(contests), 'position');
            for (let contest of contests) {
                contest.candidates = _.sortBy(Object.values(contest.candidates), 'position');
                contest.turnout = _.sumBy(contest.candidates, 'votes');
                contest.turnoutPtc = ptc(this.precinct.turnout, contest.turnout);
            }

            return contests;
        },
        ballotTotalVotes() {
            let votes = {};
            let ballotData = this.precinct.ballot_data;
            for (let ballot of this.ballots) {
                votes[ballot.id] = _.sumBy(ballotData.filter(d => d.ballot_id === ballot.id), 'votes')
            }
            return votes;
        },
        resultsStatusCode() {
            let p = this.precinct;

            if (this.$results.election.status==='completed') {
                return 'not_accepting';
            }
            if (p.status === 'completed') {
                return 'submitted';
            }

            if (!p.ready) {
                return 'precinct_not_ready';
            }

            if (!this.ballotVotesMatchPrecinctTurnout) {
                return 'ballot_votes_mismatch';
            }

            if (!this.hasAnyResultsAtAll) {
                return 'pending';
            }

            if (this.allContestsCompleted) {
                return 'can_be_submitted';
            }

            if (!this.allContestsCompleted) {
                return 'contest_votes_mismatch';
            }

            if (p.status === 'pending') {
                return 'pending';
            }

            return '';
        },
        resultsStatus() {
            let errorCodes = {
                'precinct_not_ready': true,
                'ballot_votes_mismatch': true,
                'contest_votes_mismatch': true,
            };
            let infoCodes = {
                'not_accepting': true,
                'pending': true,
            };
            let successCodes = {
                'submitted': true,
                'can_be_submitted': true,
            };

            let statusCode = this.resultsStatusCode;
            let classes = '';
            if (errorCodes[statusCode]) {
                classes = 'text-danger-900 bg-danger-300 border-b border-danger-800 p-7 font-semibold';
            } else if (infoCodes[statusCode]) {
                classes = 'text-info-900 bg-info-300 border-b border-info-500 p-7 font-semibold';
            } else if (successCodes[statusCode]) {
                classes = 'text-success-900 bg-success-300 border-b border-success-800 p-7 font-semibold';
            }

            return {
                classes,
                code: statusCode,
            };
        },
        canEnterResults() {
            // Need a machine to apply results to
            if (!this.currentMachineNum) {
                return false;
            }

            // Admins can always edit
            if (this.isadmin && this.precinct) {
                return true;
            }

            return this.$results.election.status !== 'completed' &&
                this.precinct &&
                this.precinct.status !== 'completed' &&
                this.precinct.ready;
        },
    },
    methods: {
        ptc,
        propSum,
        truncForDisplay,

        workoutSplit(contest, precinct){

            let runningTotal = 0;

            let ballotIdsForContest = Object.keys(contest.onBallots);

            const ballotIdsAsNumbers = ballotIdsForContest.map(id => parseInt(id));

            precinct.ballot_data.forEach((ballot) => {
                if (ballotIdsAsNumbers.includes(ballot.ballot_id)) {
                    runningTotal += ballot.votes;
                }
            });

            return runningTotal;

        },

        createUpdateValues() {
            if (this.newTurnout) {
                this.newTurnout.original = this.precinct.turnout;
                this.newCurbside.original = this.precinct.curbside;

                for (let bVotes of this.newBallotVotes) {
                    let ballotVotes = this.ballotTotalVotes[bVotes.id];
                    bVotes.val.original = ballotVotes;
                }
            } else {
                this.newTurnout = updateableValue(this.precinct.turnout, parseInt);
                this.newCurbside = updateableValue(this.precinct.curbside, parseInt);

                this.newBallotVotes = [];
                for (let ballotId in this.ballotTotalVotes) {
                    let ballot = this.ballots.find(b => b.id === parseInt(ballotId));
                    let ballotVotes = this.ballotTotalVotes[ballotId];
                    this.newBallotVotes.push({
                        id: ballot.id,
                        name: ballot.name,
                        val: updateableValue(ballotVotes, parseInt),
                    });
                }
            }
        },
        editContest(contestKey) {
            // Find and copy the current contest so we edit the copy only. The user
            // may cancel the edit at any point
            let contest = _.find(this.contests, {key: contestKey});
            if (!contest) {
                console.error(`editContest() Could not find contest key '${contestKey}'`);
                return;
            }

            let currentValues = {};
            contest.candidates.forEach((candidate) => {
                // Copy the object so we're not modifying the original
                currentValues[candidate.key] = {...candidate};
            });

            this.editingContestValues = currentValues;
            this.editingContest = contestKey;
        },
        onContestFormSubmit: async function() {
            let newValues = this.editingContestValues;
            let contestKey = this.editingContest;

            let contest = _.find(this.contests, {key: contestKey});
            if (!contest) {
                console.error(`onContestFormSubmit() Could not find contest key '${contestKey}'`);
                return;
            }

            let newTotals = {};
            for (let candidateKey in newValues) {
                newTotals[candidateKey] = {
                    candidate_id: newValues[candidateKey].saveToCandidateId,
                    votes: newValues[candidateKey].votes,
                };
            }

            try {
                let resp = await this.$api.post('/data/contest', {
                    precinct: this.precinct.id,
                    machine: this.currentMachineNum,
                    contest: contest.saveToContestId,
                    totals: newTotals,
                });

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                // Reset any overrides for this contest so that they must re-read any warnings with
                // the changed results
                this.$delete(this.overrideContestSubmit, this.editingContest);
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }

            this.editingContestValues = {};
            this.editingContest = '';
        },
        submitResults: async function() {
            if (!confirm('Submitting for review will lock your results from any other changes. Continue?')) {
                return;
            }

            try {
                let resp = await this.$api.post('/pollworker/save', {
                    precinct: this.precinct.id,
                    status: 'completed',
                });

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                this.$router.push({
                  path: '/worker', // base URL
                  query: {
                    dropdown: 'submitted'
                  }
                });


                

            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }
        },
        async onTurnoutFormSubmit() {
            try {
                let toUpdate = {
                    precinct: this.precinct.id,
                    turnout: this.newTurnout.value,
                    curbside: this.newCurbside.value,
                    ballots: [],
                };

                this.newBallotVotes.forEach(b => {
                    if (b.val.changed) {
                        toUpdate.ballots.push({
                            id: b.id,
                            machine: this.currentMachineNum,
                            votes: b.val.value,
                        });
                    }
                });

                let resp = await this.$api.post('/pollworker/save', toUpdate);
                if (resp.error) {
                    throw new Error(resp.error.message);
                }

            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }
        },
    },
};

</script>