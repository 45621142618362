<template>
  <div class="main">
      <main>
        <section class="card">
          <router-link :to="{name:'system.home'}" class="mr-4 no-underline">Live monitor</router-link>
          <router-link :to="{name:'system.electionmanager'}" class="mr-4 no-underline">Manage elections</router-link>
          <router-link :to="{name:'system.users'}" class="mr-4 no-underline">System users</router-link>
          <router-link :to="{name:'system.audits'}" class="no-underline">Audit Logs</router-link>
        </section>
        <router-view />
      </main>
  </div>
</template>

<script>

export default {
    name: 'System',
    components: {
    },
    computed: {
    },
}
</script>
