import Worker from './Worker.vue';
import Home from './Home';
import ProvisionalVoter from './ProvisionalVoter';
import EnterResults from './EnterResults';

import Header from '@/components/Header';

export default {
    components: { default: Worker, header: Header},
    children: [
        {name: 'record.home', path: '', component: Home },
        {name: 'record.summary', path: 'enterresults', component: EnterResults },
        {name: 'record.report_voter', path: 'provisional', component: ProvisionalVoter },
    ],
};
