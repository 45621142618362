import Vue from 'vue';
import App from './App.vue';
import getRouter from './router';
import { ElectionData, uuidv4 } from '@/libs/misc';
import QueueListener from '@/libs/QueueListener';
import Messages from '@/services/messages';
import DevicesService from '@/services/UserDevices';
import ActiveSessionsService from '@/services/ActiveSessions';
import Api from '@/libs/api';

import './vueExtras';

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
// import ToggleButton from 'vue-js-toggle-button';
// Vue.use(ToggleButton);


Vue.use(Donut);

import './assets/styles/index.css';

Vue.config.productionTip = false

let appState = new Vue({
  data: {
    user: {
      username: '',
      admin: false,
      system: false,
      precinctId: 0,
      authApproved: true,
      authApprovedError: null,
    },
    site: {
      electionName: '',
      locationName: '',
      electionDate: ''
    },
  },
  methods: {
    isLoggedIn() {
      return !!this.user.username;
    },
    isWorker() {
      return this.isLoggedIn() && !this.user.admin && !this.user.system;
    },
    isAdmin() {
      return this.isLoggedIn() && this.user.admin && !this.user.system;
    },
    isSystem() {
      return this.isLoggedIn() && this.user.system;
    },
    clearUser() {
      this.user.username = '';
      this.user.admin = false;
      this.user.precinctId = 0;
    },
    async refreshStatus() {
      let status = await apiInstance.get('/mystatus');
      if (status.username) {
        this.user.username = status.username;
        this.user.admin = status.admin;
        this.user.precinctId = status.precinct;
        this.user.system = !!status.system;
      }
    },
  },
});

let router = getRouter(appState);
let apiInstance = new Api(window.apiUrl || '/api/', getOrGenerateDeviceId());

let messageLoader = new Messages(apiInstance, appState);
let electionData = new ElectionData(apiInstance, appState);
let serverEvents = new QueueListener(apiInstance, appState);

appState.$watch('user.username', (newVal) => {
  if (newVal) {
    // logged in
    if (appState.isWorker() || appState.isAdmin()) {
      electionData.refresh();
      messageLoader.loadMessages();
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          command: 'setUser',
          user: { username: newVal },
        });
      }
    }
    serverEvents.start();
  } else {
    // logged out
    serverEvents.stop();
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        command: 'logout',
      });
    }
  }
});

appState.$watch('user.precinctId', () => {
  // when an admin switches precinct, load the relevent messages right away
  messageLoader.loadMessages();
});

apiInstance.on('authorized', (err) => {
  if (err?.error === 'rejected_device') {
    if (appState.user.authApproved) {
      appState.user.authApproved = false;
      appState.user.authApprovedError = err;
    }
    serverEvents.stop();
  } else if (err?.error === 'logged_out') {
    appState.clearUser();
    router.push('/');
    Vue.nextTick(() => {
        window.location.reload();
    });
  } else {
    if (!appState.user.authApproved) {
      // Reload the page to start everything from fresh. Easier than trying to restart all app
      // services and hope we've not missed anything
      window.location.reload();
    }
  }
});

let svcActiveSessions = new ActiveSessionsService(apiInstance, appState);
svcActiveSessions.listen();

let svcDevices = new DevicesService(apiInstance, appState);
svcDevices.listen();

Vue.mixin({
  computed: {
    '$state'() {
      return appState;
    },
    '$results'() {
      return electionData;
    },
    '$api'() {
      return apiInstance;
    },
    '$services'() {
      return {
        devices: svcDevices,
        messages: messageLoader,
        activeSessions: svcActiveSessions,
      }
    },
  },
  methods: {
    // route - used in a <router-link :to="{name, query:{}">Home</router-link> link
    route(name, query, hash) {
      return {name, query, hash};
    },
    // routeurl - used to generate a href string of the route
    routeurl(name, query) {
      let prefix = '';
      if (name.indexOf('api.') === 0) {
        prefix = router.apiBase;
        name = name.replace('api.', '');
      }
      return prefix + router.resolve({name, query}).href;
    },
  },
});

function getOrGenerateDeviceId() {
  let tpdev = window.localStorage.getItem('tpdev') || '{}';
  let device = null;
  try {
    device = JSON.parse(tpdev);
  } catch (err) {
    console.error('Error parsing device data');
  }

  if (!device || !device.id) {
    device = {
      id: uuidv4(),
      created: new Date().toISOString(),
    };
    window.localStorage.setItem('tpdev', JSON.stringify(device));
  }

  return device.id;
}

async function initServiceWorker() {
  if (navigator.serviceWorker.controller) {
    console.log('Service worker found');
    let registration = await navigator.serviceWorker.ready;
    if (registration) {
      try {
        console.log('Updating service worker...');
        await registration.update();
        console.log('Service worker updated successfully');
        registration.active.postMessage({command: 'version'});
      } catch(err) {
        console.error('Error updating service worker', err);
      }
    }
  } else {
    console.log('No service worker found');
  }
}

(async function() {
  await initServiceWorker();

  // Get our current user status before any UI related things are shown
  await appState.refreshStatus();

  window.app = new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})();


Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})