<template>
<div class="main">

<section class="section-login">
    <div class="md:w-6/12 sm:w-10/12 mx-auto">
        <h3 class="text-center">TallyPoint - Request a demo</h3>

        <template v-if="!complete">
        <div class="m-5">
            <p class="">Request a demo account to trial TallyPoint at a time that suits you.</p>
            <p>There is no obligation, and a member of our accounts team will contact you with your demo account information.</p>
        </div>

        <form class=" p-5 bg-neutral-500 overflow-hidden" @submit.prevent="onDemoFormSubmit">
            <div v-if="error" class="bg-danger-300 text-danger-800 border border-red-500 p-5 mb-5 text-center">{{error}}</div>
            <label>
                <div class="label">Name</div>
                <input v-model="name" class="w-6/12" />
            </label>
            <label>
                <div class="label">Email</div>
                <input v-model="email" class="w-6/12" />
            </label>
            <label>
                <div class="label">Telephone</div>
                <input v-model="tel" class="w-6/12" />
            </label>
            <label>
                <div class="label">Company / County</div>
                <input v-model="company" class="w-6/12" />
            </label>
            <label>
                <div class="label">Notes <span class="text-sm">(optional)</span> </div>
                <textarea v-model="notes" rows=5 class="w-full"></textarea>
            </label>

            <button
                type="submit"
                :disabled="loading || !formReady"
                class="mx-auto block mt-10"
                :class="[!loading ? 'btn-success' : '']"
            >{{loading ? 'Requesting...' : 'Request demo' }}</button>
        </form>
        </template>
        <template v-else>
            <div class="text-success-800 bg-success-300 border border-success-800 p-5 w-6/12 mx-auto my-5 text-center">
                <p>Thank you for requesting a demo account! A member of our accounts team will contact you as soon as possible.</p>
            </div>
        </template>
    </div>
</section>

</div>
</template>

<style scoped>
form label {
    display: block;
}

form label span {
    flex-grow: unset;
}

form label .label {
    display: block;
    margin-bottom: 5px;
}
</style>

<script>

export default {
    data: function() {
        return {
            name: '',
            tel: '',
            email: '',
            company: '',
            notes: '',
            error: '',
            loading: false,
            complete: false,
        };
    },
    computed: {
        formReady() {
            let required = [
                'name',
                'tel',
                'email',
                'company',
            ];

            for (let field of required) {
                if (!this[field].trim()) {
                    return false;
                }
            }

            return true;
        },
    },
    methods: {
        async onDemoFormSubmit() {
            this.loading = true;
            this.error = '';
            try {
                let resp = await this.$api.post('/requestdemo', {
                    name: this.name,
                    tel: this.tel,
                    email: this.email,
                    company: this.company,
                    notes: this.notes,
                });

                if (resp.error) {
                    this.error = resp.error.message;
                } else {
                    this.complete = true;
                }
            } catch (err) {
                console.log(err);
                this.error = 'There was an issue requesting you demo. Please try again soon';
            }

            this.loading = false;
        },
    },
};

</script>