<template>
<div class="main">

<div class="card-title">
    <h3>Users and devices</h3>
    <div>Manage your users and the devices they can use to log in</div>
</div>

<section class="card card--inline">
    <a
        @click="showSection='users'"
        class="inline-block cursor-pointer p-5 mr-5 bg-neutral-200 hover:bg-neutral-400"
        :class="{'no-underline': showSection!=='users'}"
    >Users</a>
    <a
        @click="showSection='locations'"
        class="inline-block cursor-pointer p-5 bg-neutral-200 hover:bg-neutral-400"
        :class="{'no-underline': showSection!=='locations'}"
    >Trusted devices</a>
</section>

<users-locations v-if="showSection==='locations'" :users="users"></users-locations>
<section v-if="showSection==='users'" class="card">
    <div class="">
        <div class="mb-3">

            <div class="box-container">       
                <!-- <div class="new-user-box" :class="{'new-user-box--active': openStylingBox === 'create_user'}" style="display: flex; flex-direction: column; justify-content: space-between;"> -->
                
                
                <div class="new-user-box" :class="{'new-user-box--active': openStylingBox}" style="display: flex; flex-direction: column; justify-content: space-between;">
                    <div class="mb-3 pl-3 pr-3">
                        <span class="font-bold">Create a new user</span>
                    </div>
                    <div class="text-sm w-full flex bg-neutral-200 hover:bg-neutral-400 justify-center" @click="()=>{
                            showNewUser=!showNewUser;
                            if (openStylingBox){
                                openStylingBox = false;
                            } else {
                                openStylingBox=true;
                            }
                            
                        }" >
                        <a class="cursor-pointer no-underline hover:underline h-10 flex items-center">New user 
                            <svg-add class="inline pt-0.5 ml-1" v-if="!openStylingBox"/>
                            <svg-hide class="inline pt-0.5 ml-1" v-else/>
                        </a>
                    </div>
                </div>
                
                
                <div class="auth-level-box">
                    <div class="mb-3 pl-3">
                        <span class="font-bold">Case-sensitive passwords</span>
                    </div>
                    <div class="text-sm flex pl-3 pr-3">
                        <p>Pollworker's passwords are case-sensitive</p>
                    </div>
                    <div class="w-full flex content-center ">
                        <!-- <label class="pr-5 pl-5 py-5 border border-slate-300">{{caseSensitive ? "Sensitivity" : "Sensitivity"}}</label> -->
                        <a
                            @click="()=> setCaseSensitivity(false)"
                            :class="caseSensitive ? 
                            'flex items-center flex-wrap justify-center bg-neutral-200  cursor-pointer  hover:bg-neutral-400 h-10 w-3/6' : 
                            'flex items-center flex-wrap justify-center bg-neutral-600  cursor-pointer  hover:bg-neutral-600 h-10 w-3/6'"
                            style="border-top: 1px solid #C0D4DB; "
                        >Off</a>
                        <a
                            @click="()=> setCaseSensitivity(true)"
                            :class="caseSensitive ? 
                            'flex flex-wrap justify-center items-center bg-neutral-600   cursor-pointer  hover:bg-neutral-600 h-10 w-3/6' : 
                            'flex flex-wrap justify-center items-center bg-neutral-200   cursor-pointer  hover:bg-neutral-400 h-10 w-3/6'"
                            style="border-top: 1px solid #C0D4DB; "
                        >On</a>
                    </div>
                </div>
                
            </div>

            <div v-if="!openStylingBox" class="bg-neutral-200 border border-neutral-600 rounded-b h-1 w-3/6">
            </div>

            <div v-else >
            </div>


            <form v-if="showNewUser" @submit.prevent="onNewUserFormSubmit" class="w-full md:w-9/12 lg:w-6/12 border border-neutral-500 rounded p-5 bg-neutral-200">
                <label>
                    <span class="mr-5">Username:</span>
                    <input v-focus v-model="newUser.username" />
                </label>
                <label>
                    <span class="mr-5">Password:</span>
                    <input v-model="newUser.password" class="w-8/12" :class="passwordStrength(newUser.password) === 'strong' ? 
                            'border input-bottom-border-green' : (passwordStrength(newUser.password) === 'medium' ? 
                            'input-bottom-border-yellow' :  (!newUser.password ? '' : 'input-bottom-border-red'))"/>
                    <button class="password-strength-button w-10 flex justify-center" v-if="newUser.password" 
                                :class="passwordStrength(newUser.password) === 'strong' ? 
                                    'bg-green-600 border input-bottom-border-green' : (passwordStrength(newUser.password) === 'medium' ? 
                                    'bg-yellow-600 border input-bottom-border-yellow' : 'bg-red-600 bg-opacity-80 border input-bottom-border-red')" style="user-select: none; vertical-align: top;"
                                    :title="`${passwordStrength(newUser.password)} password strength`" onclick="return false"
                                >
                                    <SvgPadlock/>
                    </button>
                </label>
                <label>
                    <span class="mr-5">Type:</span>
                    <select v-model="newUser.type">
                        <option value="worker">Worker</option>
                        <option value="admin">Admin</option>
                    </select>
                </label>
                <label>
                    <span class="mr-5">Default precinct:</span>
                    <select v-model="newUser.precinct">
                        <option v-for="p in precincts" :value="p.id" :key="p.id">{{p.key}} - {{p.name}}</option>
                    </select>
                </label>

                <div class="text-right">
                    <a href="" @click.prevent="()=>{
                        showNewUser=!showNewUser
                        openStylingBox = false;
                    }" class="no-underline mr-7">Cancel</a>
                    <button type="submit">Save user</button>
                </div>
            </form>
        </div>

        <form onsubmit="return false">
        <table class="w-full">
            <tr class="bg-gray-200 w-full">
                <th class="w-2/12 sticky top-0 bg-gray-200">Username</th>
                <th class="w-2/12 sticky top-0 bg-gray-200">Type</th>
                <th class="w-3/12 sticky top-0 bg-gray-200">Precinct</th>
                <th class="w-3/12 sticky top-0 bg-gray-200 pr-0">Password</th>
                <th class="bg-gray-200 sticky top-0 text-right w-2/12 p-0 grow">

                    <button type="button" class="custom-height color-fade sticky select-none btn btn-default btn-sm rounded p-3 bg-neutral-200 border-neutral-200 " disabled v-if="this.saving" title="Saving">
                      <SvgSaving class="rotation-animation sticky" style="width:24px; height:24px;"/>
                    </button>
                    <button type="button" class="custom-height color-fade sticky select-none btn btn-default btn-sm rounded p-3 bg-neutral-200 border-neutral-200 " disabled v-else-if="this.savingError" title="Error saving">
                      <SvgError class="sticky" style="width:24px; height:24px;"/>
                    </button>
                    <button type="button" class="custom-height sticky btn btn-default btn-sm rounded p-3 bg-neutral-200 border-neutral-200 select-none" disabled v-else-if="this.savingError == false && this.saving == false" title="Saved">
                      <SvgSaved class="sticky" style="width:24px; height:24px;"/>
                    </button>

                </th>
            </tr>
            <tr v-for="user in orderBy(users, ['admin', 'username'], ['desc', 'asc'])" :key="user.id" class="hover:bg-neutral-100">
                <td class="align-middle">{{user.username}}</td>
                <td class="align-middle">{{user.admin ? 'Admin' : 'Poll worker'}}</td>
                <td>
                    <select v-model="user.precinct_id" @change="saveUsers()">
                        <option v-for="p in precincts" :value="p.id" :key="p.id">{{p.key}} - {{p.name}}</option>
                    </select>
                </td>



                    <td class="align-middle pr-0" v-if="!user.admin">
                        <div class="flex">
                            <input v-model="user.password" :class="passwordStrength(user.password) === 'strong' ? 
                            'border input-bottom-border-green' : (passwordStrength(user.password) === 'medium' ? 
                            'input-bottom-border-yellow' : 'input-bottom-border-red')" style="user-select: none; vertical-align: top;"
                            @focus="handleFocusForPasswords(user)">
                            
                            <!-- If the password being edited is not this password -->
                            <div class="pl-0 flex" v-if="!user.admin && user.username !== usernameBeingEdited">
                                <button title="Generate new password" type="button" @click="generateNewPassword(user.username)" 
                                :class="passwordStrength(user.password) === 'strong' ? 'border input-bottom-border-green' : (passwordStrength(user.password) === 'medium' ? 
                                'input-bottom-border-yellow' : 'input-bottom-border-red')" style="user-select: none; vertical-align: top;">
                                    <svg-refresh-password></svg-refresh-password>
                                </button>
                                <button class="password-strength-button"  
                                :class="passwordStrength(user.password) === 'strong' ? 
                                    'bg-green-600 border input-bottom-border-green' : (passwordStrength(user.password) === 'medium' ? 
                                    'bg-yellow-600 border input-bottom-border-yellow' : 'bg-red-600 bg-opacity-80 border input-bottom-border-red')" style="user-select: none; vertical-align: top;"
                                    :title="`${passwordStrength(user.password)} password strength`" onclick="return false"
                                >
                                    <SvgPadlock/>
                                </button>
                            </div>       
                            
                            <div class="pl-0 flex" v-else-if="!user.admin && showPasswordEdit === false && user.username !== usernameBeingEdited">
                                <button title="Generate new password" type="button" @click="generateNewPassword(user.username)"
                                :class="passwordStrength(user.password) === 'strong' ? 'border input-bottom-border-green' : (passwordStrength(user.password) === 'medium' ? 
                                'input-bottom-border-yellow' : 'input-bottom-border-red')" style="user-select: none; vertical-align: top;">
                                    <svg-refresh-password></svg-refresh-password>
                                </button>
                                <button class="password-strength-button"  
                                :class="passwordStrength(user.password) === 'strong' ? 
                                    'bg-green-600' : (passwordStrength(user.password) === 'medium' ? 
                                    'bg-yellow-600' : 'bg-red-600 bg-opacity-80')" style="user-select: none; vertical-align: top;"
                                    :title="`${passwordStrength(user.password)} password strength`" onclick="return false"
                                >
                                    <SvgPadlock/>
                                </button>
                            </div>        

                            <!-- If the password being edited is the current password -->
                            <div class="pl-0 flex"  v-else-if="!user.admin && showPasswordEdit === true && user.username === usernameBeingEdited">
                                <button title="Save" type="button" @click="passwordEdit(user.username, `save`)" :class="passwordStrength(user.password) === 'strong' ? 
                                    'border input-bottom-border-green ' : (passwordStrength(user.password) === 'medium' ? 
                                    'border input-bottom-border-yellow ' : 'border input-bottom-border-red ')">
                                    <svg-password-tick></svg-password-tick>
                                </button>

                                <button title="Cancel" type="button" @click="passwordEdit(user.username, `cancel`)" :class="passwordStrength(user.password) === 'strong' ? 'border input-bottom-border-green ' : (passwordStrength(user.password) === 'medium' ? 
                                    'border input-bottom-border-yellow ' : 'border input-bottom-border-red ')">
                                    <svg-password-cross></svg-password-cross>
                                </button>
                            </div>
                        </div>
                    </td>

                    <!-- If this password is an admin pasword ... -->
                    <td class="pr-0" v-else></td>
            <td class="flex justify-end"><a class="cursor-pointer text-2xl text-danger-600 no-underline hover:underline" @click="delUser(user)" title="Delete user"><svg-cross class="inline" /></a></td>
            </tr>
            <tr></tr>
        </table>
        </form>
    </div>
</section>  

</div>
</template>

<script>

import _ from 'lodash';
import SvgCross from '@/assets/svgs/cross.svg?inline';
import SvgAdd from '@/assets/svgs/downThin.svg?inline';
import SvgHide from '@/assets/svgs/upThin.svg?inline';
import SvgRefreshPassword from '@/assets/svgs/refreshPassword.svg?inline';
import SvgPasswordTick from '@/assets/svgs/passwordTick.svg?inline';
import SvgPasswordCross from '@/assets/svgs/passwordCross.svg?inline';
import SvgSaved from '../../assets/svgs/saved.svg?inline';
import SvgSaving from '../../assets/svgs/saving.svg?inline';
import SvgError from '../../assets/svgs/error.svg?inline';
import SvgPadlock from '../../assets/svgs/padlock.svg?inline';

import UsersLocations from './UsersLocations';

class functionalUserInput{
    constructor(nameOfThing, usernameOfThing, currentValue, futureValue, users){
        this.nameOfThing = nameOfThing;
        this.usernameOfThing = usernameOfThing;
        this.currentValue = currentValue;
        this.futureValue = futureValue;
        this.users = users;
    }
}

export default {
    components: {
        SvgAdd,
        SvgHide,
        SvgCross,
        SvgRefreshPassword,
        SvgPasswordTick,
        SvgPasswordCross,
        SvgSaved,
        SvgSaving,
        SvgError,
        SvgPadlock,
        UsersLocations,
    },
    data: function() {
        return {

            users: [],
            newUser: {
                username: '',
                password: '',
                type: 'worker',
                precinct: 0,
            },
            
            // Table passwords
            precinctToEdit: null,
            currentPrecinctClass: null,
            showPasswordEdit: false,
            usernameBeingEdited: null,
            currentUser: null,

            // Page functionality
            showNewUser: false,
            showSection: 'users',
            saving: false,
            savingError : false, 
            caseSensitive : true,
            openStylingBox: false
        };
    },

    computed: {
        precincts() {
            return this.$results.precincts;
        },
        originalUsers() {
            return this.$services.devices.users;
        },
        changedUsers() {
            let changedUsers = {};
            this.users.forEach((u) => {
                let original = _.find(this.originalUsers, {id: u.id});
                if (!original || !_.isEqual(u, original)) {
                    changedUsers[u.id] = u;
                }
            });
            return changedUsers;
        },
    },
    async mounted() {
        // Will set the Password case sensitivity on page load to reflect the database 
        await this.$api.get('/mgr/getcasesensitivity')
            .then(resp => {
                this.caseSensitive = resp.caseSensitivity;
            })
        this.reload();
    },
    methods: {
        orderBy: _.orderBy,
        async reload() {
            try {
                await this.$services.devices.loadUsers();
                this.users = _.cloneDeep(this.$services.devices.users);
            } catch (err) {
                console.error(err);
            }
        },
        async onNewUserFormSubmit() {
            let user = this.newUser;
            if (!user.username || !user.password) {
                return;
            }

            let resp = await this.$api.post('/mgr/users', {
                new: {
                    username: user.username,
                    password: user.password,
                    type: user.type,
                    precinct: user.precinct,
                },
            });

            if (resp.error) {
                alert(resp.error.message);
                return;
            }

            // reset our form info for next time they open it
            user.username = '';
            user.password = '';
            user.type = 'worker';
            user.precinct = 0;
            this.showNewUser = false;

            this.reload();
        },
        async delUser(user) {
            if (!confirm('Delete user ' + user.username + '? This is not reversable!')) {
                return;
            }

            this.saving = true;
            // Turns off saving boolean after delay
            this.delay(1400).then(()=>this.saving = false);
            let resp = await this.$api.post('/mgr/users', {
                del: [user.id],
            });

            if (resp.error) {
                this.saving = false;
                this.savingError = true;
                alert(resp.error.message);
                return;
            }

            this.reload();
        },
        delay(time) {
                return new Promise(resolve => setTimeout(resolve, time));
        },
        async saveUsers() {
            let changedUsers = Object.values(this.changedUsers);
            let resp = await this.$api.post('/mgr/users', {
                update: changedUsers,
            });

            this.saving = true;
            // Turns off saving boolean after delay
            this.delay(1400).then(()=>this.saving = false);


            if (resp.error) {
                this.saving = false;
                this.savingError = true;
                alert(resp.error.message);
                return;
            }
            this.usernameBeingEdited = null;
            this.reload();
        },
        async generateNewPassword(passedUsername){

            let resp = await this.$api.get('/mgr/refreshPassword');

            this.users.map(user=>{
                if (user.username === passedUsername){
                    user.password = resp.password;
                }
                return
            })

            this.saveUsers();

        },
        passwordEdit(passedUsername, action, current = null, next = null){
            
            this.showPasswordEdit = !this.showPasswordEdit;

            if (action =="edit"){
                this.usernameBeingEdited = passedUsername;
                this.currentPassword = new functionalUserInput("password", passedUsername, current, next, this.users);

            } else if (action == "save"){
                this.currentPassword = new functionalUserInput("password", passedUsername, current, next, this.users);
                this.saveUsers();

            } else if (action == "cancel"){
                this.users.map(user=>{
                    if (user.username === passedUsername){
                        user.password =  this.currentPassword.currentValue;
                    }
                    return
                })

                this.currentPassword = ''; 
                this.usernameBeingEdited = null;
            }
            return false
        },

        passwordStrength(password){
            let strongPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{12,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]{3,})(?=.{12,}))')
            let mediumPassword = new RegExp('((?=.*[^A-Za-z0-9])(?=.{12,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{12,}))|((?=.*[A-Z])(?=.*[0-9]{4,})(?=.{12,}))|((?=.*[a-z])(?=.*[0-9]{4,})(?=.{12,}))')



            if (strongPassword.test(password)){
                return "strong"
            } else if (mediumPassword.test(password)){
                return "medium"
            } else {
                return "weak"
            }
        },

        async setCaseSensitivity(boolValue){

            let response = await this.$api.post('/mgr/casesensitivity', {value : boolValue})
            this.caseSensitive = response.value;
        },

        handleFocusForPasswords(user) {
            this.passwordEdit(user.username, `edit`, user.password, null);
            this.showPasswordEdit = true;
        },
    },
};

</script>

<style scoped>

.input-bottom-border-red {
  border-bottom: solid 3px #E75954; /* Tailwind's green-500 color */
}

.input-bottom-border-yellow {
  border-bottom: solid 3px #DCA91E; /* Tailwind's green-500 color */
}
.input-bottom-border-green {
  border-bottom: solid 3px #0CA736; /* Tailwind's green-500 color */
}


.box-container {
    @apply flex;
    width: 50%;
}
.new-user-box {
    @apply flex-1 mx-2 p-3 pb-0 pl-0 pr-0 bg-white border border-b-0 border-neutral-600 rounded-t relative cursor-pointer;
    top: 1px;
}
/* .auth-level-box:hover {
    @apply bg-neutral-200;
} */
.new-user-box--active {
    @apply bg-neutral-200;
}
.new-user-box:first-child {
    @apply ml-0;
}
.new-user-box:last-child {
    @apply mr-0;
}

.auth-level-box {
    @apply flex-1 mx-2 p-3 pb-0 pl-0 pr-0 bg-white border border-b-0 border-neutral-600 rounded-t relative cursor-pointer;
    top: 1px;
    /* width: 33%; */
}
/* .auth-level-box:hover {
    @apply bg-neutral-200;
} */
.auth-level-box--active {
    @apply bg-neutral-200;
}
.auth-level-box:first-child {
    @apply ml-0;
}
.auth-level-box:last-child {
    @apply mr-0;
}




.custom-height {
    height: 90%;
}
.color-fade{
    background-color: theme('colors.neutral.200');
    animation: FadeIn 2s ease-in forwards;
}


.rotation-animation {
    -webkit-animation: rotation 1.5s 60 linear;
    -moz-animation: rotation 1.5s 60 linear;
    -o-animation: rotation 1.5s 60 linear;
    animation: rotation 1.5s 60 linear;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
}

@-webkit-keyframes rotation {
    from {-webkit-transform: rotate(0deg);}
    to   {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
    from {-moz-transform: rotate(0deg);}
    to   {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
    from {-o-transform: rotate(0deg);}
    to   {-o-transform: rotate(359deg);}
}
@keyframes rotation {
    from {transform: rotate(0deg);}
    to   {transform: rotate(359deg);}
}



@keyframes FadeIn {
  0% {
    background-color: theme('colors.neutral.200');
  }
  70%{
    background-color: theme('colors.neutral.600');
  }
  100% {
    background-color: theme('colors.neutral.200');
  }
}
@media (max-width: 1200px) {
    .password-strength-button {
        display: none;
    }
}
</style>
