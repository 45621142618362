<template>
<div class="main">

<div class="card-title">
    <h3>Provisional voters</h3>
    <div>Add, verify or change the provisional voters for this election</div>
</div>


<section class="card">
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-10">
        <div>
            <form>
                <label>
                    <select class="w-full font-bold" v-model="filters.district">
                        <option value="">All Districts</option>
                        <option v-for="d in districts" :value="d.id" :key="d.id">{{d.name}}</option>
                    </select>

                </label>
            </form>
        </div>

        <div>
            <form>
                <label>
                    <select class="w-full font-bold" v-model="filters.precinct">
                        <option value="">All Precincts</option>
                        <option v-for="p in precincts" :value="p.id" :key="p.id">{{p.name}}</option>
                    </select>
                </label>
            </form>
        </div>

        <div>
            <div class="btn-export-div">
                <button @click.prevent="exportFile(`exporting`, `provisionalsReport`, null, `provisionalExport`)" class="btn-export w-48" data-testid="export-canvas-precinct">Export All</button>
                <button v-if="provisionalExport.exporting" class="btn-export btn-export-svg"><SvgLoading class="rotating"/></button>
                <button v-if="(provisionalExport.download_id && !provisionalExport.error && !provisionalExport.exporting) || (provisionalExport.completed && !provisionalExport.error && !provisionalExport.exporting)" class="btn-export btn-export-svg" @click.prevent="exportFile(`download`, `provisionalsReport`, null, `provisionalExport`)"><SvgDownload/></button>
                <button v-if="provisionalExport.error" class="btn-export btn-export-svg"><SvgDownloadError/></button>
            </div>
        </div>
    </div>

    <provisional-ballot-table :provisionals="filteredProvisionals"></provisional-ballot-table>
</section>

</div>
</template>

<style scoped>
    .btn-success {
        height: 50px; 
        @apply w-48 p-0;
    }

    .btn-success:hover {
        height: 50px; 
        @apply w-48 p-0;
    }
    
    .btn-export-div {
        display: flex;
        flex-direction: row;
    }
    .btn-export {
        height: 50px; 
        vertical-align: middle;
        @apply border border-green-600 text-green-600 cursor-pointer;
    }
    .btn-export:hover {
        @apply bg-green-600 text-white border border-green-600 cursor-pointer;
    }
    .btn-export-svg {
        width: 50px; 
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
    }
    
    @keyframes spin {
        0% { transform: rotate(360deg); }
        100% { transform: rotate(0deg); }
    }
    
    .rotating {
        animation: spin 3s linear infinite;
    }
</style>

<script>

import ProvisionalBallotTable from '../../components/ProvisionalBallotTable';
import { sleep } from '@/libs/misc';
import SvgLoading from '@/assets/svgs/loading.svg?inline';
import SvgDownload from '@/assets/svgs/download.svg?inline';
import SvgDownloadError from '@/assets/svgs/downloadError.svg?inline';
    

export default {
    components: {
        ProvisionalBallotTable,
        SvgLoading, 
        SvgDownload,
        SvgDownloadError
    },
    data: function() {
        return {
            filters: {
                district: '',
                precinct: '',
            },
            provisionals: [],
            loaded: false,
            provisionalExport: {
                exporting: false,
                completed: false,
                error: false,
                download_id : null,
            }
        };
    },
    computed: {
        provisionalReasons() {
            return this.$results.election.election_settings.provisional_types;
        },
        districts: function() {
            return this.$results.locations;
        },
        filteredDistricts: function() {
            let ret = {};
            if (this.filters.district) {
                let district = this.$results.locations[this.filters.district];
                if (district) {
                    ret[district.id] = district;
                }
            } else {
                ret = this.$results.locations;
            }

            return ret;
        },
        precincts: function() {
            let locs = this.filteredDistricts;

            let ret = {};
            for (let districtId in locs) {
                let precincts = locs[districtId].precincts;
                for (let precinctId in precincts) {
                    ret[precinctId] = precincts[precinctId];
                }
            }

            return ret;
        },
        filteredProvisionals: function() {
            return this.provisionals.filter(voter => {
                if (this.filters.precinct && this.filters.precinct !== voter.precinct_id) {
                    return false;
                }

                return true;
            });
        },
    },
    mounted() {
        this.reload();
    },
    created() {
            this.latestDownloads();
        },
    methods: {
        async latestDownloads(){
                let res = await this.$api.get(`/mgr/export/latest`);

                if (res.status === 200){
                    if (!res.data || res.data.length === 0){
                        this.provisionalExport.download_id = null;
                    } else {
                        let latestExports = res.data.reduce((acc, curr) => {
                            acc[curr.export_type] = curr.export_jobs_id;
                            return acc;
                        }, {});
                        this.provisionalExport.download_id = latestExports.provisionalsreport || null;
                    }
                }

            },
        async exportFile(instruction, fileType, subType = null, localVariableName) {
            const resetStatus = () => {
                this[localVariableName].exporting = false;
                this[localVariableName].completed = false;
                this[localVariableName].error = false;
            };
        
            const setExportingStatus = () => {
                this[localVariableName].exporting = true;
                this[localVariableName].completed = false;
                this[localVariableName].error = false;
            };
        
            const downloadFile = async (id) => {
                let cacheBuster = Date.now(); // Current timestamp
                let route = `/mgr/export/downloadfile?id=${id}&cb=${cacheBuster}`;
                let downloadFileRequest = await this.$api.get(route, null, { responseType: 'blob' });
            
                if (downloadFileRequest.status === 200) {
                    let blob = new Blob([downloadFileRequest.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let url = window.URL.createObjectURL(blob);
                    let link = document.createElement('a');
                    link.href = url;
                
                    let contentDisposition = downloadFileRequest.contentDisposition;
                    let filename = contentDisposition.split('filename=')[1];
                    link.download = filename;
                
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                
                    return { success: true, status: downloadFileRequest.status };
                } 
            
                return { success: false, status: downloadFileRequest.status };
            };
        
            const checkDownloadStatus = async (id) => {
                let { success, status } = await downloadFile(id);
                if (success) {
                    resetStatus();
                    this[localVariableName].completed = true;
                    this[localVariableName].download_id = id;
                } else if (status === 202) {
                    setTimeout(() => checkDownloadStatus(id), 2000); // every 5 seconds
                } else {
                    resetStatus();
                    this[localVariableName].error = true;
                }
            };
        
            if (instruction === 'exporting') {
                setExportingStatus();
            
                let res = subType === null
                    ? await this.$api.post(`/mgr/export/${fileType}`)
                    : await this.$api.post(`/mgr/export/${fileType}`, { "type": subType });
            
                if (res.error == null && res.exportFilesId) {
                    setExportingStatus();
                    await sleep(2000);
                    checkDownloadStatus(res.exportFilesId);
                } else {
                    resetStatus();
                    this[localVariableName].error = true;
                }
            } else if (instruction === 'download') {
                let { success } = await downloadFile(this[localVariableName].download_id);
                if (!success) {
                    resetStatus();
                    this[localVariableName].error = true;
                }
            } else if (instruction === 'reset') {
                resetStatus();
            }
        },
        provisionalReasonLabel(code) {
            let reason = this.$results.election.election_settings.provisional_types.find(r => r.code === code);
            return reason ?
                reason.label :
                'Unknown reason';
        },
        async reload() {
            try {
                let resp = await this.$api.get('/mgr/provisionals?json=1');
                if (resp && resp.provisionals) {
                    this.provisionals = resp.provisionals
                }
            } catch (err) {
                console.error(err);
            }
        },
        async downloadCsv() {
            let res = await this.$api.get('/mgr/export/provisionals');
            window.open(res.url);
        }
    },
};

</script>
