<template>
    <div>
        <div v-if="!precinct">
            No precinct set
        </div>
        <section v-else-if="ballots.length === 0" class="card">
            <div class="text-danger-800 bg-danger-300 border-b border-danger-800 p-5 m-5 text-center">
                <b>This precinct has no ballot assigned!</b>
                <p class="mt-5">Please contact the administrator to configure this precinct.</p>
            </div>
        </section>
        <aggregate-ballots v-else-if="tapeFormat === 'aggregate'" :precinct="precinct" :ballots="ballots" :results="results" :showerrors="true" />
        <complete-ballots v-else :precinct="precinct" :ballots="ballots" :results="results" :showerrors="true" />
    </div>
</template>

<script>

import AggregateBallots from '@/components/enterresultscreens/AggregateBallots';
import CompleteBallots from '@/components/enterresultscreens/CompleteBallots';
 
export default {
    components: {
        CompleteBallots,
        AggregateBallots,
    },
    data: function() {
        return {
        };
    },
    computed: {
        tapeFormat() {
            return this.precinct.tape_format || 'aggregate';
        },
        precinct() {
            return this.$results.precincts.find(loc => loc.id === this.$state.user.precinctId);
        },
        ballots() {
            let precinct = this.precinct;
            if (!precinct) {
                return [];
            }

            let ballots = [];
            precinct.ballot_ids.forEach(bId => {
                let ballot = this.$results.ballots[bId];
                if (ballot) {
                    ballots.push(ballot);
                }
            });

            return ballots;
        },
        results() {
            return this.$results.results.filter(r => {
                return r.precinct_id === this.precinct.id;
            });
        },
    },
    methods: {
    },
};

</script>