<template>
  <div class="main">
      <messages></messages>

      <main>
        <router-view v-if="thisPrecinct && $results.loaded" />
        <div v-else class="text-danger-800 bg-danger-300 border border-danger-800 p-5 w-5/12 mx-auto my-5 text-center">
            <p>You are not assigned to any precinct. Please contact the administrator for more information.</p>
        </div>
      </main>
  </div>
</template>

<script>

import Messages from '@/components/messages/Messages.vue';

export default {
    name: 'Worker',
    components: {
        Messages,
    },
    computed: {
        thisPrecinct() {
            return this.$results.precincts.find(loc => loc.id === this.$state.user.precinctId);
        },
    },
}
</script>
