<template>
    <div class="machine-switcher mt-10" v-if="precinct.num_machines > 1">
        <div class="font-bold">Tabulation machines</div>

        <div v-if="!currentMachineNum">Which machine are you entering results for?</div>
        <div v-else>Currently entering results from machine {{currentMachineNum}}</div>
        <div class="flex gap-5 mt-2">
            <a
                v-for="idx in precinct.num_machines"
                :key="idx"
                @click="currentMachineNum=idx" 
                class="cursor-pointer block no-underline border-2 flex items-center justify-center"
                :class="{
                    'border-green-600': currentMachineNum === idx,
                    'bold': currentMachineNum === idx,
                    'text-2xl': currentMachineNum === idx,
                    'border-neutral-700':  currentMachineNum !== idx,

                }"
                :style="[
                    currentMachineNum === idx ?
                        {'height': '75px', 'width': '66px'} :
                        {'height': '66px', 'width': '58px'},
                ]"
            >
                <span>{{idx}}</span>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    components: {
    },
    props: [
        'precinct',
    ],
    watch: {
        currentMachineNum() {
            this.$emit('selected', this.currentMachineNum);
        }
    },
    data: function() {
        return {
            currentMachineNum: 0,
        };
    },
}

</script>