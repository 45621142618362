<template>

<section class="card">
    <div class="mt-12">

        <device-auth-switcher class="mb-10" />

        <form>
        <table class="w-full" border=1>
            <tr class="bg-neutral-200">
                <th colspan=5>
                    <span
                        class="cursor-pointer"
                        :class="{'text-neutral-700': showRejected}"
                        @click="showRejected=false"
                    >Unknown devices</span>
                    <toggle v-model="showRejected" class="mx-3" />
                    <span
                        class="cursor-pointer"
                        :class="{'text-neutral-700': !showRejected}"
                        @click="showRejected=true"
                    >Rejected devices</span>
                </th>
            </tr>
            <tr v-if="Object.keys(unauthorized).length === 0">
                <td colspan=5>No devices here</td>
            </tr>
            <template v-for="(location, locationIdx) in orderBy(unauthorized, ['auth.approved_at', 'auth.remote_addr', 'user.username'], ['desc'])">
            <tr
                v-for="(entry, entryIdx) in orderBy(location.entries, ['auth.approved_at'], ['desc'])"
                :key="`authed-loc-${entry.key}`"
                class="row--compact onhover--show-svgs"
                :class="{'row--compact--first': locationIdx===0 && entryIdx===0}"
            >
                <td v-if="entryIdx === 0" :rowspan="location.entries.length">{{location.remote_addr}}</td>
                <td>{{entry.user.username}}</td>
                <td>{{precinctLabel(entry.user.precinct_id)}}</td>
                <td>
                    <span class="text-neutral-700">{{entry.auth.device_id}}</span>
                    <a @click.prevent="authorizeEntry(entry)" class="cursor-pointer no-underline" title="Trust this device">
                        <svg-tick class="inline mx-2 onhover--show-svgs--target" />
                    </a>
                </td>
                <td v-if="entryIdx === 0" :rowspan="location.entries.length">
                    <button @click.prevent="authorizeWholeLocation(location)">
                        <svg-tick class="inline mx-2" /> Trust all of {{location.remote_addr}}
                    </button>
                </td>
            </tr>
            <tr :key="`authed-loc-${location.remote_addr}-sep`">
                <!-- a seperator between each location so each group of devices actually look like a group -->
                <td colspan=5><hr class="border-neutral-400 my-2" /></td>
            </tr>
            </template>


            <tr class="bg-neutral-200">
                <th colspan=5>Trusted devices</th>
            </tr>
            <tr v-if="Object.keys(authorized).length === 0">
                <td colspan=5>No devices here</td>
            </tr>
            <template v-for="(location, locationIdx) in orderBy(authorized, ['auth.approved_at', 'auth.remote_addr', 'user.username'], ['desc'])">
            <tr
                v-for="(entry, entryIdx) in orderBy(location.entries, ['auth.approved_at'], ['desc'])"
                :key="`unauthed-loc-${entry.key}`"
                class="row--compact onhover--show-svgs"
                :class="{'row--compact--first': locationIdx===0 && entryIdx===0}"
            >
                <td v-if="entryIdx === 0" :rowspan="location.entries.length">{{location.remote_addr}}</td>
                <td>{{entry.user.username}}</td>
                <td>{{precinctLabel(entry.user.precinct_id)}}</td>
                <td>
                    <span class="text-neutral-700">{{entry.auth.device_id}}</span>
                    <a @click.prevent="unauthorizeEntry(entry)" class="cursor-pointer no-underline" title="Untrust this device">
                        <svg-cross class="inline mx-2 onhover--show-svgs--target" />
                    </a>
                </td>
                <td v-if="entryIdx === 0" :rowspan="location.entries.length">
                    <button @click.prevent="unauthorizeWholeLocation(location)">
                        <svg-cross class="inline mx-2" /> Untrust all of {{location.remote_addr}}
                    </button>
                </td>
            </tr>
            <tr :key="`unauthed-loc-${location.remote_addr}-sep`">
                <!-- a seperator between each location so each group of devices actually look like a group -->
                <td colspan=5><hr class="border-neutral-400 my-2" /></td>
            </tr>
            </template>
        </table>
        </form>
    </div>
</section>
</template>

<style scoped>
.row--compact td {
    padding-top: 0;
    padding-bottom: 0;
}
.row--compact--first td {
    @apply pt-4;
}
.onhover--show-svgs .onhover--show-svgs--target {
    opacity: 0;
    pointer-events: none;
}
.onhover--show-svgs:hover .onhover--show-svgs--target {
    opacity: 1;
    pointer-events: all;
}
</style>

<script>

import _ from 'lodash';
import Toggle from '@/components/Toggle';
import DeviceAuthSwitcher from './UsersDeviceAuthSwitcher';
import SvgTick from '../../assets/svgs/tick.svg?inline';
import SvgCross from '../../assets/svgs/cross.svg?inline';

export default {
    components: {
        SvgTick,
        SvgCross,
        DeviceAuthSwitcher,
        Toggle,
    },
    data: function() {
        return {
            showRejected: false,
        };
    },
    computed: {
        precincts() {
            return this.$results.precincts;
        },
        locations() {
            return this.$services.devices.seperateAuthorizedLocations();
        },
        authorized() {
            return this.$services.devices.seperateAuthorizedLocations({trusted: true});
        },
        unauthorized() {
            let types = {};
            if (this.showRejected) {
                types.rejected = true;
            } else {
                types.unknown = true;
            }
            return this.$services.devices.seperateAuthorizedLocations(types);
        },
    },
    mounted() {
    },
    methods: {
        orderBy: _.orderBy,
        precinctLabel(precinctId) {
            let p = this.precincts.find(p => p.id === precinctId);
            if (!p) {
                return '';
            }

            return `${p.key} - ${p.name}`;
        },
        async authorizeWholeLocation(location) {
            await this.$services.devices.authorizeLocation(location.remote_addr);
            location.entries.forEach(entry => {
                if (!entry.auth.approved_at) {
                    entry.auth.approved_at = new Date().toISOString();
                }
            });
        },
        async unauthorizeWholeLocation(location) {
            await this.$services.devices.unauthorizeLocation(location.remote_addr);
            location.entries.forEach(entry => {
                if (entry.auth.approved_at) {
                    entry.auth.approved_at = null;
                }
            });
        },
        async authorizeEntry(entry) {
            await this.$services.devices.authorizeDevice(entry.auth.device_id, entry.auth.remote_addr);
            entry.auth.approved_at = new Date().toISOString();
        },
        async unauthorizeEntry(entry) {
            await this.$services.devices.unauthorizeDevice(entry.auth.device_id, entry.auth.remote_addr);
            entry.auth.approved_at = null;
        },
    },
};

</script>
