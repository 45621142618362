<template>
    <a class="cursor-pointer no-underline select-none text-neutral-900" @click="$emit('order', orderKey)">
        {{title}}
        <img v-if="orderKey===currentOrder" src="/icon-orderby.svg" class="inline relative" style="top:-1px;" />
        <span v-else style="width:21px; display:inline-block;"></span>
    </a>
</template>

<script>

export default {
    props: ['orderKey', 'currentOrder', 'title'],
};

</script>