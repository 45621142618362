import Manager from './Manager.vue';
import PrecinctStatus from './PrecinctStatus';
import PrecinctOverview from './PrecinctOverview';
import ElectionResults from './ElectionResults';
import BallotStyles from './BallotStyles';
import RegisteredVoters from './RegisteredVoters';
import Provisionals from './Provisionals';
import Users from './Users';
import ReorderContests from'./ReorderContests';
// import ElectionData from './ElectionData';

import Header from '@/components/Header';

export default {
    components: { default: Manager, header: Header},
    children: [
      {name: 'manager.ballots', path: 'ballots', component: BallotStyles},
      {name: 'manager.enter_results', path: 'results/enter', component: RegisteredVoters},
      {name: 'manager.provisional_voters', path: 'provisional_voters', component: Provisionals},
      {name: 'manager.users', path: 'users', component: Users},
      //{name: 'manager.import_election', path: 'import_election', component: ElectionData},
      {
        name: 'manager.import_election',
        path: 'import_election',
        // Split this into it's own chunk as it's pretty large with all the spreadsheet handling libs
        component: () => import(/* webpackChunkName: "electiondata" */ './ElectionData'),
      },
      
      {name: 'manager.precinct_status', path: 'status', component: PrecinctStatus},
      {name: 'manager.reorder_contests', path: 'reorder', component: ReorderContests},
      {name: 'manager.precinct_overview', path: 'precincts', component: PrecinctOverview},
      {name: 'manager.election_results', path: 'summary', component: ElectionResults},

      // urls for the server just for easy url generation. can be used to generate urls with the router
      // with an api. prefix. ie. routeurl('api.manager.election') in case the API is at a different url
      {name: 'manager.open_precinct', path: 'openprecinct'},
      {name: 'manager.election', path: 'election'},
      {name: 'manager.publish', path: 'export/website'},
    ],
};
