<template>
    <div class="border border-neutral-600 focus-within:border-blue-500" @focusin="updateFocus(true)" @focusout="updateFocus(false)">
        <slot v-if="shouldShowUi" name="toolbar"></slot>

        <textarea
            ref="elNewMessage"
            placeholder="New message..."
            class="transition-height duration-100 w-full p-3 resize-none focus:outline-none"
            :class="[
                shouldShowUi ? 'h-28' : 'h-10',
                shouldShowUi ? 'overflow-auto' : 'overflow-hidden'
            ]"
            :value="value"
            @input="input"
        ></textarea>
        <div class="text-right text-sm m-3 mt-0 flex">
            <a class="float-left inline-block cursor-pointer mr-3" @click="onClearClick">
                <svg-trash
                    class="fill-current h-4 w-4 relative"
                    style="top:3px;"
                    :class="[weHaveFocus ? '' : 'text-neutral-400']"
                />
            </a>
            <div class="flex-grow"></div>
            <a class="float-left inline-block cursor-pointer mr-6">
                <svg-camera
                    class="fill-current h-4 w-4 relative"
                    style="top:3px;"
                    :class="[weHaveFocus ? '' : 'text-neutral-400']"
                />
            </a>
            <a
                href=""
                @click.prevent="onSendClicked"
                :class="[weHaveFocus ? '' : 'text-neutral-400']"
                class="cursor-pointer no-underline"
            >Send <span class="text-xs">❯</span></a>
        </div>
    </div>
</template>

<script>

import SvgCamera from '../../assets/svgs/camera.svg?inline';
import SvgTrash from '../../assets/svgs/trash.svg?inline';


export default {
    components: {
        SvgCamera,
        SvgTrash,
    },
    props: ['value'],
    data: function() {
        return {
            weHaveFocus: false,
        };
    },
    computed: {
        shouldShowUi() {
            return this.weHaveFocus || this.value.trim();
        },
    },
    methods: {
        focusInput() {
            this.$refs.elNewMessage.focus();
        },
        input(event) {
            this.$emit('input', event.target.value);
        },
        updateFocus(hasFocus) {
            // Switching between elements within this component triggers first a focusout event and
            // then a focusin event, but both in the same js tick. Track all focus events and then
            // get the last event in the next js tick to determine which focus event we should act
            // on
            if (!this.focusTick) {
                this.focusTick = [];
                setTimeout(() => {
                    this.weHaveFocus = this.focusTick[this.focusTick.length - 1];
                    this.focusTick = null;
                    this.$emit(this.weHaveFocus ? 'focus' : 'blur');
                }, 0)
            }

            this.focusTick.push(hasFocus)
        },
        onClearClick() {
            if (!confirm('Delete current unsaved message?')) {
                return;
            }

            this.$emit('input', '');
        },
        onSendClicked() {
            if (this.weHaveFocus && this.value.trim()) {
                this.$emit('send');
            }
        },
    },
}
</script>
