<template>
    <div class="main">
        <div v-if="thisPrecinct.status === 'completed'" class="absolute w-screen h-24 left-0 top-0">
            <div ref="stickyStatus" class="sticky top-0 left-0 z-10 text-center">
                <div class="flex text-success-900 bg-success-300 border-b border-success-800 p-7 font-semibold">
                    <div class="flex-grow">
                        <template>
                            These results have been submitted for review.
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <section class="section-recorder-menu" :class="[precinctReady ? 'precinct-ready' : '', thisPrecinct.status === 'completed' ? 'pt-24' : '']">
            <div class="overflow-hidden">
                <h3 class="float-left">{{thisDistrict ? thisDistrict.name : ''}}, {{thisPrecinct.name}}</h3>

                <form class="inline-block float-right mt-5">
                    <label class="m-0">
                        <span class="pt-0">Precinct Ready</span>
                        <input type="checkbox" v-model="precinctReady" />
                    </label>

                    <div class="text-neutral-700 text-sm">
                        <a @click="$state.$emit('message.open')" class="cursor-pointer">Messages {{unreadMessageText}}</a>
                    </div>
                </form>
            </div>

            <div v-if="!hasBallot" class="w-9/12 lg:w-6/12 mx-auto my-10">
                <div class="text-danger-800 bg-danger-300 border border-danger-800 p-5 m-5 text-center">
                    <b>This precinct has no ballot assigned!</b>
                    <p class="mt-5">Please contact the administrator to configure this precinct.</p>
                </div>
            </div>
            <div v-else class="w-9/12 lg:w-6/12 mx-auto my-10">
                <div v-if="$results.election.status === 'open' || $results.election.status === 'closed'">
                    <h4 class="border-green-600 pl-5 border-l-8 mb-10">{{
                        $results.election.status === 'open' ? 'Election is running' : 'Election closed, accepting results'
                    }}</h4>

                    <section class="card">
                        <div v-if="!precinctReady">
                            Once your precinct is ready, you will be able to update the results for this election.
                            <button class="btn btn-success mx-auto block p-5 mt-10" @click="precinctReady=true" data-testid="pollworker-precinct-ready-button">This precinct is ready ❯</button>
                        </div>
                        <template v-else>
                            <template v-if="!countInputFormType">
                                <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                                    <a class="btn-success border-2 p-5" @click="showCountInput('turnout')" data-testid="pollworker-update-turnout-button">Update Turnout</a>

                                    <router-link v-if="displayOptions.display_provisional_voter" class="btn-success border-2 p-5" :to="routeurl('record.report_voter')" data-testid="pollworker-provisional-voter-button">Provisional Voter</router-link>

                                    <a  v-if="displayOptions.display_curbside_voter" class="btn-success border-2 p-5" @click="showCountInput('curbside')"  data-testid="pollworker-update-curbside-button">Curbside Voter</a>

                                    <router-link v-if="$results.election.status === 'closed'" class="btn-success border-2 p-5" :to="routeurl('record.summary')">Enter Results</router-link>
                                </div>
                            </template>
                            <div v-else class="count-input">
                                <form @submit.prevent="onCountFormSubmit">
                                    <h5>{{countInputFormTitles[countInputFormType]}}</h5>
                                
                                    <input type="number" v-model="countInputFormValue" v-focus data-testid="pollworker-update-number-input"/> <br />

                                    <span v-if="countInputFormType==='turnout'" class="mt-2 block text-neutral-700" data-testid="pollworker-update-number-current-turnout">Current Turnout: {{turnoutStats.turnout}} ({{turnoutStats.ptc}}%)</span>
                                    <span v-if="countInputFormType==='curbside'" class="mt-2 block text-neutral-700" data-testid="pollworker-update-number-current-curbside">Current Curbside voters: {{thisPrecinct.curbside}}</span>
                                
                                    <div class="mt-5">
                                        <button type="submit" class="btn-success mr-5">Save</button>
                                        <button @click="hideCountInput">Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </template>
                    </section>
                </div>


                <div v-if="$results.election.status === 'completed'">
                    <h4 class="border-red-600 pl-5 border-l-8 mb-10">Election Closed</h4>

                    <p>This election has now closed. No more results can be entered.</p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import _ from 'lodash';
import {
    ptc,
} from '@/libs/misc';

export default {
    data: function() {
        return {
            countInputFormTitles: {
                turnout: 'Update Turnout',
                curbside: 'Curbside Voter',
            },
            countInputFormType: '',
            countInputFormValue: '',
            hideOptions: {
                provisional : false, 
                curbside : true
            },
            displayOptions: {
                display_curbside_voter : true,
                display_provisional_voter : true
            },
            dropdown : {
                submitted : false,
            }
        };
    },
    mounted(){
        this.pullDisplayOptions();
    },
    computed: {
        thisDistrict() {
            return this.$results.locations[this.thisPrecinct.district_id];
        },
        thisPrecinct() {
            return this.$results.precincts.find(loc => loc.id === this.$state.user.precinctId);
        },
        hasBallot() {
            let thisLoc = this.thisPrecinct;
            if (!thisLoc) return false;

            if (thisLoc.ballot_ids.length === 0) {
                return false;
            }

            // Check if we have at least one ballot
            for (let ballotId of thisLoc.ballot_ids) {
                if (this.$results.ballots[ballotId]) {
                    return true;
                }
            }

            return false;
        },
        turnoutStats: function() {
            let ret = {
                turnout: 0,
                registered: 0,
                ptc: 0,
            };

            let loc = _.chain(this.$results.precincts)
                .find({id: this.$state.user.precinctId})
                .value();
            
            if (loc) {
                ret.turnout = loc.turnout;
                ret.registered = loc.registered;
                ret.ptc = Math.floor(ptc(ret.registered, ret.turnout));
            }

            return ret;
        },
        precinctReady: {
            get() {
                let loc = _.chain(this.$results.precincts)
                    .find({id: this.$state.user.precinctId})
                    .value();
            
                if (loc) {
                    return loc.ready;
                }

                return false;
            },
            async set(newVal) {
                await this.$api.post('/pollworker/save', {
                    precinct: this.$state.user.precinctId,
                    ready: !!newVal,
                });
                this.$results.refresh();
            }
        },
        unreadMessageText() {
            let out = '';
            let count = this.$services.messages.state.unreadCount;
            if (count > 0) {
                out = `(${count})`;
            }

            return out;
        }
    },
    methods: {
        // This will check whether the curbside and provisional sections should be displayed when the page loads
        async pullDisplayOptions(){
            await this.$api.post('/pollworker/displayoptions',{options:null})
            .then(resp => {
                this.displayOptions.display_curbside_voter = resp.returnedOptions.curbside;
                this.displayOptions.display_provisional_voter = resp.returnedOptions.provisional;
            })
        },
        showCountInput(type) {
            console.log(type);
            this.countInputFormType = type;
        },
        hideCountInput() {
            this.countInputFormType = '';
            this.countInputFormValue = '';
        },
        async onCountFormSubmit() {
            try {
                let toUpdate = {
                    precinct: this.$state.user.precinctId,
                };

                if (this.countInputFormType === 'turnout') {
                    toUpdate.turnout = parseInt(this.countInputFormValue);
                } else if (this.countInputFormType === 'curbside') {
                    toUpdate.curbside = parseInt(this.countInputFormValue);
                }
                let resp = await this.$api.post('/pollworker/save', toUpdate);

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                this.hideCountInput();
                this.$results.refresh();
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.log(err.message);
            }
        },
    },
};


</script>