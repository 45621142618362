import EventEmitter from 'events';
import Vue from 'vue';

export default class UserDevices extends EventEmitter {
    constructor(api, bus) {
        super();

        this.users = [];
        this.devices = [];

        // Only make previous properties reactive
        new Vue.observable(this);

        this.api = api;
        this.bus = bus;
    }

    listen() {
        this.bus.$on('serverevent.auth.unauthorized', event => {
            let newDevice = {
                deviceId: event.deviceId,
                remoteAddr: event.remoteAddr,
                username: event.username,
                userId: event.userId,
                precinctId: event.precinctId,
                precinctName: event.precinctName,
                precinctKey: event.precinctKey,
            };

            let existing = this.devices.find(d => d.deviceId === event.deviceId);
            if (existing) {
                Object.assign(existing, newDevice);
            } else {
                this.devices.push(newDevice);
            }
        });
    }

    async loadUsers() {
        try {
            let resp = await this.api.get('/mgr/users');
            if (resp && resp.users) {
                Vue.set(this, 'users', resp.users);
            }
        } catch (err) {
            console.error(err);
        }
    }

    seperateAuthorizedLocations(types={trusted:true,unknown:true,rejected:true}) {
        let locs = {};
        // returns {1.1.1.1: [ {key, user, auth} ]}
        this.users.forEach(user => {
            user.locations.forEach(loc => {
                let include = false;
                if (types.unknown && (!loc.approved_at && !loc.rejected_at)) {
                    include = true;
                }
                if (types.trusted && loc.approved_at) {
                    include = true;
                }
                if (types.rejected && loc.rejected_at) {
                    include = true;
                }

                if (!include) {
                    return;
                }

                locs[loc.remote_addr] = locs[loc.remote_addr] || {
                    remote_addr: loc.remote_addr,
                    entries: [],
                };
                locs[loc.remote_addr].entries.push({
                    // key is commonly used in UI component templates for loops
                    key: `${user.id}-${loc.remote_addr}-${loc.device_id}`,
                    user,
                    auth: loc,
                });
            });
        });

        return locs;
    }

    async authorizeLocation(remoteAddr) {
        await this.api.post('mgr/users/devices', {
            trust: [{
                remoteAddr,
            }],
        });

        this.removeDevice(null, remoteAddr);
        await this.loadUsers();
    }

    async unauthorizeLocation(remoteAddr) {
        await this.api.post('/mgr/users/devices', {
            reject: [{
                remoteAddr,
            }],
        });

        this.removeDevice(null, remoteAddr);
        await this.loadUsers();
    }

    async authorizeDevice(deviceId, remoteAddr) {
        await this.api.post('/mgr/users/devices', {
            trust: [{
                deviceId,
                remoteAddr,
            }],
        });

        this.removeDevice(deviceId, remoteAddr);
        await this.loadUsers();
    }

    async unauthorizeDevice(deviceId, remoteAddr) {
        await this.api.post('/mgr/users/devices', {
            reject: [{
                deviceId,
                remoteAddr,
            }],
        });

        this.removeDevice(deviceId, remoteAddr);
        await this.loadUsers();
    }

    removeDevice(deviceId, remoteAddr) {
        // _.remove and other lodash functions don't trigger vue's reactivity on arrays as it
        // calls a different .splice() function internally, so we need our own way to remove
        // array items directly on the reactive array
        let i = 0;
        while (i < this.devices.length) {
            let remove = true;
            if (!deviceId || (deviceId && deviceId !== deviceId)) {
                remove = false;
            }
            if (!remoteAddr || (remoteAddr && remoteAddr !== remoteAddr)) {
                remove = false;
            }
            
            if (remove) {
                this.devices.splice(i, 1);
            } else {
                ++i;
            }
        }
    }
}
