import Vue from 'vue';
import VueRouter from 'vue-router';
import Public from '../views/Public';
import RequestDemo from '../views/RequestDemo';
import Install from '../views/Install';
import PageNotFound from '../views/PageNotFound';
import ViewsRoutes from '../views/routes';
import ManagerRoutes from '../views/manager/routes';
import WorkerRoutes from '../views/worker/routes';
import SystemRoutes from '../views/system/routes';
import ExternalLogin from '../views/ExternalLogin';

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
      // All API specific stuff
      path: '/data/',
      children: [
        {name: 'data.election', path: 'election'},
        {name: 'data.contest', path: 'contest'},
        {name: 'data.messages_inbox', path: 'messages'},
        {name: 'data.messages_update', path: 'message'},
      ],
  },
  {
    ...ViewsRoutes,
    path: '/about/',
  },
  {
    ...ManagerRoutes,
    meta: { requiresAuth: true, requiresAdmin: true, },
    path: '/mgr/',
  },
  {
    ...WorkerRoutes,
    meta: { requiresAuth: true, },
    path: '/worker/'
  },

  {
    ...SystemRoutes,
    meta: { requiresSystem: true },
    path: '/system/'
  },

  {
    name: 'requestdemo',
    path: '/requestdemo',
    component: RequestDemo,
    children: [
      //{name: 'pages.logout', path: '/logout'},
      //{name: 'pages.login', path: '/login'},
    ],
  },

  {
    name: 'home',
    path: '/',
    component: Public,
    children: [
      // {name: 'pages.home', path: '/', component: Public },
      {name: 'pages.logout', path: '/logout'},
      {name: 'pages.login', path: '/login'},
    ],
  },

  {
    name: 'externallogin',
    path: '/externallogin',
    component: ExternalLogin,
  },

  {
    name: 'install',
    path: '/install',
    component: Install,
    children: [ ],
  },

  {
    path: '*',
    component: PageNotFound
  }
];



let instance = null;
export default function routerSingleton(appState) {
  if (instance) {
    return instance;
  }

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    }
  });

  router.beforeEach((to, from, next) => {

    // This path is used for external login usually from EIA. It will automatically check whether the user is logged into keycloak. If not they will 
    // be prompted to login. 
    if (to.path === '/externallogin') {
      next();
    }

    // Protect any routes with requiresSystem with our auth status
    if (to.matched.some(record => record.meta.requiresSystem)) {
      if (!appState.isSystem()) {
        next({
          path: '/',
          // query: { redirect: to.fullPath }
        });
        return;
      }
    }

    // Protect any routes with requiresAuth with our auth status
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!appState.isLoggedIn()) {
        next({
          path: '/',
          // query: { redirect: to.fullPath }
        });
        return;
      }
    }

    if (to.matched.some(record => record.meta.requiresAdmin)) {
      // Not an admin to this admin route? Send them to the home page
      if (!appState.user.admin) {
        next({ path: '/' });
        return;
      }

      // Make sure we clear any pollworker overrides on an admin page
      appState.user.precinctId = 0;
    }

    next();
  })

  instance = router;
  window.router = router;
  //router.apiBase = 'https://localhost';
  router.apiBase = '';

  return router;
}