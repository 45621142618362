<template>
<div class="main">

        <div class="card-title">
            <h3>Precinct results overview</h3>
            <div>Preview and modify results for each precinct</div>
        </div>


<section class="card">
    <div class="bg-neutral-200 p-5 overflow-hidden">
        <form>
            <div class="font-bold">Choose Precinct</div>
            <label>
                <select class="w-full font-bold" v-model="filters.precinct">
                    <option v-for="p in precincts" :value="p.id" :key="p.key">{{p.key}} - {{p.name}}</option>
                </select>
            </label>
        </form>

        <div v-if="thisPrecinct" class="overflow-hidden">
            <div class="text-sm bg-neutral-400 rounded rounded-br-none py-2 px-5 float-right text-neutral-800">
                Status: {{thisPrecinct.status}},
                Registered: {{thisPrecinct.registered}},
                Turnout: {{thisPrecinct.turnout}},
                Curbside: {{thisPrecinct.curbside}}.
                |
                <span
                    :title="isPrecinctOnline ? 'Online' : 'Offline'"
                    class="rounded-full h-2 w-2 inline-block mr-1 ml-2" :class="{
                        'bg-red-600': !isPrecinctOnline,
                        'bg-green-600': isPrecinctOnline,
                    }"
                ></span> {{isPrecinctOnline ? 'Online' : 'Offline'}}
            </div>
        </div>

        <div v-if="thisPrecinct" class="overflow-hidden">
            <div class="text-sm bg-neutral-400 py-2 px-5 float-right text-neutral-800 rounded-bl">
                <span v-if="!tabmachines.showForm">
                    Tape format: {{tapeFormat === 'individual' ? 'Individual ballot votes' : 'Aggregated ballot votes'}},
                    Tab. machines: {{thisPrecinct.num_machines}}.
                    <a @click="editNumMachines" class="cursor-pointer">change</a>
                </span>
                <form v-else @submit.prevent="saveNumMachines">
                    Tape format:
                    <select v-model.number="tabmachines.tapeFormat" class="mr-4">
                        <option value="individual">Individual ballot votes</option>
                        <option value="aggregate">Aggregated ballot votes</option>
                    </select>

                    Tab. machines: <input v-model.number="tabmachines.numMachines" type="number" class="w-20" />
                    <button>Save</button>
                    <button @click.prevent="tabmachines.showForm=false">Cancel</button>
                </form>
            </div>
        </div>

        <div v-if="thisPrecinct" class="overflow-hidden">
            <form class="inline-block w-auto float-right text-sm bg-neutral-400 rounded rounded-t-none py-2 px-5 text-neutral-800">
                <label class="m-0">
                    <span class="w-auto pt-1">Mark these results as completed</span>
                    <input type="checkbox" class="pt-1" v-model="resultsStatus" />
                </label>
            </form>
        </div>
    </div>

</section>

<div v-if="resultsHaveErrors" class="text-danger-800 bg-danger-300 border border-danger-800 p-5 m-5 w-6/12 mx-auto text-center">
    Errors were found with these results
</div>
<div v-else-if="thisPrecinct && thisPrecinct.status==='completed'" class="text-success-800 bg-success-300 border border-success-800 p-5 w-5/12 mx-auto my-5 text-center">
    <p>This location has completed entering results and cannot make any more changes</p>
</div>

<!-- the key attribute forces a complete re-create of this component when thisPrecinct.id changes -->
<aggregate-ballots
    v-if="thisPrecinct && tapeFormat === 'aggregate'"
    ref="enterResults"
    :key="thisPrecinct.id"
    :showerrors="false"
    :isadmin="true"
    :precinct="thisPrecinct"
    :ballots="precinctBallots"
    :results="precinctResults"
    @haserrors="resultsHaveErrors=$event"
/>
<complete-ballots
    v-else-if="thisPrecinct"
    ref="enterResults"
    :key="thisPrecinct.id"
    :showerrors="false"
    :isadmin="true"
    :precinct="thisPrecinct"
    :ballots="precinctBallots"
    :results="precinctResults"
    @haserrors="resultsHaveErrors=$event"
/>

</div>
</template>

<script>

import _ from 'lodash';
import {
    aggregateResultsPerContest,
    queryStringVal,
    ptc,
    propSum,
    precinctsWithBadResults,
} from '@/libs/misc';
import AggregateBallots from '@/components/enterresultscreens/AggregateBallots';
import CompleteBallots from '@/components/enterresultscreens/CompleteBallots';

export default {
    components: {
        AggregateBallots,
        CompleteBallots,
    },
    data: function() {
        return {
            filters: {
                precinct: '',
            },
            editingContest: '',
            editingContestValues: {
                /*
                candidateId: 0,
                */
            },
            resultsHaveErrors: false,
            tabmachines: {
                showForm: false,
                numMachines: 0,
                tapeFormat: '',
            }
        };
    },
    computed: {
        isPrecinctOnline() {
            return this.$services.activeSessions.precinctIds.includes(this.thisPrecinct.id);
        },
        tapeFormat() {
            return this.thisPrecinct ?
                this.thisPrecinct.tape_format :
                'aggregate';
        },
        thisPrecinct: function() {
            return this.$results.precincts.find(p => p.id === this.filters.precinct);
        },
        precinctBallots() {
            let precinct = this.thisPrecinct;
            if (!precinct) {
                return [];
            }

            let ballots = [];
            precinct.ballot_ids.forEach(bId => {
                let ballot = this.$results.ballots[bId];
                if (ballot) {
                    ballots.push(ballot);
                }
            });

            return ballots;
        },
        precinctResults() {
            return this.$results.results.filter(r => {
                return r.precinct_id === this.thisPrecinct.id;
            });
        },
        aggResults: function() {
            let precinct = this.thisPrecinct;

            let ballots = [];
            for (let ballotId of precinct.ballot_ids) {
                let ballot = this.$results.ballots[ballotId];
                if (!ballot) {
                    console.error('Precinct referes to non-existing ballot,', ballotId);
                    continue;
                }

                let contests = aggregateResultsPerContest(
                    { precinct_id: precinct.id },
                    this.$results.results,
                    ballot,
                    this.$results
                );

                contests = _.orderBy(contests, 'position');
                ballots.push({
                    ballot,
                    results: contests,
                });
            }

            return ballots;
        },
        precincts: function() {
            return this.$results.precincts;
        },
        badResults() {
            let r = precinctsWithBadResults(this.$results.results, this.$results.locations, this.$results.ballots);
            for (let prop in r) {
                delete r[prop].precinct;
            }
            return r[this.thisPrecinct.id];
        },
        resultsStatus: {
            get() {
                return this.thisPrecinct ?
                    this.thisPrecinct.status === 'completed' :
                    false;
            },
            async set(newVal) {
                await this.setPrecinctStatus(newVal ? 'completed' : 'pending');
            },
        },
    },
    mounted() {
        this.filters.precinct = parseInt(queryStringVal('precinct') || this.precincts[0]?.id || '');
    },
    methods: {
        ptc,
        propSum,
        editNumMachines() {
            this.tabmachines.numMachines = this.thisPrecinct.num_machines;
            this.tabmachines.tapeFormat = this.tapeFormat;
            this.tabmachines.showForm = true;
        },
        async saveNumMachines() {
            try {
                let resp = await this.$api.post('/mgr/updateprecinct', {
                    precincts: [
                        {
                            id: parseInt(this.filters.precinct),
                            tape_format: this.tabmachines.tapeFormat,
                            num_machines: this.tabmachines.numMachines,
                        },
                    ]
                });

                this.thisPrecinct.num_machines = this.tabmachines.numMachines;
                this.thisPrecinct.tape_format = this.tabmachines.tapeFormat;
                this.tabmachines.showForm = false;

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                this.$results.refresh();
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }
        },
        async setPrecinctStatus(newStatus) {
            try {
                let resp = await this.$api.post('/mgr/updateprecinct', {
                    precincts: [
                        {
                            id: parseInt(this.filters.precinct),
                            status: newStatus,
                        },
                    ]
                });

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                this.$results.refresh();
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.error(err.message);
            }
        },
        editContest: function(contestId) {
            // Find and copy the current contest so we edit the copy only. The user
            // may cancel the edit at any point
            let contest = this.aggResults.map(i=>i.results).flat().find(c => c.id === contestId);
            if (!contest) {
                console.error(`editContest() Could not find contest '${contestId}'`);
                return;
            }

            let currentValues = {};
            contest.rows.forEach((row) => {
                // Copy the object so we're not modifying the original
                currentValues[row.candidate_id] = Object.assign({}, row);
            });

            this.editingContestValues = currentValues;
            this.editingContest = contestId;
        },
        onContestFormSubmit: async function() {
            let newValues = this.editingContestValues;

            try {
                let resp = await this.$api.post('/data/contest', {
                    precinct: this.filters.precinct,
                    contest: this.editingContest,
                    totals: newValues,
                });

                if (resp.error) {
                    throw new Error(resp.error.message);
                }

                this.$results.refresh();
            } catch (err) {
                alert('There was an error saving your data. Please try again');
                console.log(err.message);
            }

            this.editingContestValues = {};
            this.editingContest = '';
        },
        openPollWorker(precinctId) {
            this.$state.user.precinctId = precinctId;
            this.$router.push(this.routeurl('record.home'));
        }
    },
};

</script>
