<template>
<div class="main">

<section class="section-login">
    <div class="w-6/12 mx-auto text-center">
        <h3 class="text-center">Install TallyPoint</h3>
        
        <div v-if="!installed" class="text-left">
            <p>
                Installation will:
                <ul class="list-disc">
                    <li class="ml-7">Configure this device with TallyPoint</li>
                    <li class="ml-7">Enable notifications to be alerted on new messages</li>
                    <li class="ml-7">Optionally install the TallyPoint application on your desktop</li>
                </ul>
            </p>
            <p> <button @click="install" class="btn-success mt-5 p-5">Install TallyPoint</button> </p>
        </div>
        <div v-else>
            TallyPoint has been installed on this device!<br />
            <a href="/">Continue to the login page to log in</a>
        </div>

        <!--
        <p> <button @click="setupNotifications" class="btn-danger">Enable Notifications</button> </p>
        <p> <button @click="testNotification" class="btn-danger">Test Notification in 6 seconds</button> </p>
        -->
    </div>

    <div v-if="logLines.length > 0" class="w-6/12 mx-auto mt-20 text-sm text-left text-neutral-800">
        <div>Installation log:</div>
        <div v-for="(l, idx) in logLines" :key="idx">{{l}}</div>
    </div>
</section>

</div>
</template>

<script>

export default {
    data: function() {
        return {
            logLines: [],
            installed: false,
        };
    },
    created() {
        this.listen(window, 'beforeinstallprompt', this.onBeforeinstallprompt);
        if (navigator.serviceWorker.controller) {
            this.log('Already installed on this device');
            this.installed = true;
        }
    },
    methods: {
        log(line) {
            this.logLines.push(line);
        },
        onBeforeinstallprompt(event) {
            // Wait for the user to respond to the prompt
            event.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    this.log('Added TallyPoint to the home screen');
                    // this.setupNotifications();
                } else {
                    this.log('Could not add TallyPoint to the home screen');
                }
            });
        },
        install() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.ready.then((registration) => {
                    if (Date.now() < 1) {
                        // just to ignore errors for now
                        console.log(registration);
                    }
                    this.log('Service worker registered. Enabling notifications...');
                    this.setupNotifications();
                }, (err) => {
                    this.error('Service worker not registered -->', err);
                    alert('There was an unknown error installing on this device');
                });

                navigator.serviceWorker.register('/serviceworker.js', {scope:'/'});
            } else {
                alert('This device is not supported');
            }
        },
        setupNotifications() {
            if ('Notification' in window) {
                Notification.requestPermission(result =>  {
                    if (result === 'granted') {
                        this.log('Notifications enabled')
                        alert('TallyPoint installed!');
                        this.installed = true;
                    } else if (result === 'denied') {
                        this.log('Notifications denied');
                        alert('TallyPoint was installed but notifications were refused. Please retry setup to enable notifications.');
                    } else {
                        this.log('Notifications request ignored')
                        alert('TallyPoint was installed but notifications were refused. Please retry setup to enable notifications.');
                    }
                })
            } else {
                this.log('Notifications not supported')
                alert('TallyPoint was installed but notifications are not supported on this device.');
                this.installed = true;
            }
        },
        testNotification() {
            alert('Should now see one notification...');
            this.notify('testing...');
            setTimeout(() => {
                alert('Should now see one last notification...');
                this.notify('TallyPoint notification sent successfulyl!');
            }, 6000);
        },
        notify(...args) {
            Notification.requestPermission(result => {
                if (result === 'granted') {
                    navigator.serviceWorker.ready.then(registration => {
                        registration.showNotification(...args);
                    });
                }
            });
        }
    },
};

</script>
