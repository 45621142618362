<template>
  <div id="app">
    <waiting-approval v-if="!$state.user.authApproved"></waiting-approval>
    <template v-else>
      <router-view name="header"></router-view>
      <router-view/>
    </template>
    <footer>
        <div class="overflow-hidden">
            <router-link :to="{name:'views.about'}" class="no-underline text-neutral-800 inline-block mr-10">About TallyPoint</router-link>
             <router-link :to="{name:'install'}" class="no-underline text-neutral-800 inline-block" style="margin-top:18px;">
              <template v-if="installed">
                Installed {{ installedVersion ? `(${installedVersion})` : '' }}
              </template>
              <template v-else>
                Install TallyPoint
              </template>
             </router-link>
            
            <a href="https://demtechvoting.com/"><img style="max-width:158px;float:right;" src="/assets/demtech-pb-logo.png" data-testid="demtech-logo"/></a>
        </div>
    </footer>
  </div>
</template>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>


<script>

import WaitingApproval from './views/WaitingApproval';

export default {
  components: {
    WaitingApproval,
  },
  data: function() {
      return {
        installed: false,
        installedVersion: 0,
      };
  },
  created() {
    navigator.serviceWorker.ready.then(() => {
      this.installed = true;
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({command:'version'});
      }
      /*
      console.log('posting message', registration.active)
      window.reg = registration.active;
      registration.active.postMessage({command: 'version'});
      registration.active.postMessage('plain str');
      //navigator.serviceWorker.controller.postMessage({command: 'version'});
      */
    });

    navigator.serviceWorker.addEventListener('message', async event => {
      console.log('message from sw', event, event.data);
      if (event.data.command === 'version') {
        this.installedVersion = event.data.version;
      }

      if (event.data.command === 'registerwebpush') {
        await this.$api.post('/data/notifications', {subscription: event.data.subscription});
      }
    });

    /*
    this.setInterval(() => {
      this.installed = (navigator.serviceWorker.controller);
    }, 1000);
    */
  },
  computed: {
  },
}
</script>